import {Component, Input} from '@angular/core';
import {IconsService} from '../../../services/ui-services/icon.service';

@Component({
  selector: 'app-platform-logo',
  templateUrl: './platform-logo.component.html',
  styleUrls: ['./platform-logo.component.scss']
})
export class PlatformLogoComponent {
  @Input() platform: string;

  constructor(public icons: IconsService) {
  }
}
