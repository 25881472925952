<div class="platform-list" *ngIf="filteredPlatforms?.length; else noPlatforms">
    <button *ngFor="let platform of filteredPlatforms" mat-raised-button (click)="platformSelected(platform)">
      <app-platform-logo [platform]="platform.name"></app-platform-logo>
    </button>

</div>

<ng-template #noPlatforms>
  <p *ngIf="showAvailable">No platforms available</p>
  <p *ngIf="!showAvailable">No platforms installed</p>

</ng-template>


<ng-template #confirmDeleteRef>
  <h1 mat-dialog-title>Confirm disconnect {{ selected?.name }}?</h1>
  <div mat-dialog-content class="confirm">
    <div class="project-warning" *ngIf="linkedProjects?.length">
      <div class="description">
        <fa-icon [icon]="icons.warning" size="2x" class="warning"></fa-icon>
        <p>Note that disconnecting from <strong>{{ selected?.name }}</strong> will result in the
          deletion of the following projects:</p>
      </div>
      <div class="list-wrapper">
        <ul>
          <li *ngFor="let project of linkedProjects">{{ project.name }}</li>
        </ul>
      </div>

    </div>
  </div>
  <div mat-dialog-actions>
    <div  class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-raised-button mat-dialog-close color="warn" (click)="disconnect()"
          [disabled]="fetchingProjects">Disconnect</button>
    </div>
  </div>
</ng-template>
