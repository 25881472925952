<div class="root">
  <div class="list">
    <div class="toolbar">
      <mat-form-field appearance="outline" class="search">
        <mat-label>Search</mat-label>
        <input type="search" matInput [formControl]="searchControl">
      </mat-form-field>
    </div>

    <div class="list-wrapper">
      <mat-selection-list [multiple]="false" [(ngModel)]="selectedOrganisations"
          (selectionChange)="selected($event)">
        <mat-list-option  *ngFor="let item of orgList.items" [value]="item">
          <span matListItemTitle>{{ item.name }}</span>
          <span matListItemLine><fa-icon [icon]="icons.plan"></fa-icon> {{ item.account.subscription.plan.name}}</span>
        </mat-list-option>
      </mat-selection-list>

    </div>
    <mat-paginator [length]="orgList.total"
                [pageIndex]="orgList.page - 1"
                [pageSize]="orgList.pagesize"
                [pageSizeOptions]="pageSizeOptions"
                 (page)="pageChanged($event)"
                aria-label="Select page">
    </mat-paginator>

  </div>
  <app-admin-org-details [org]="selectedOrg" *ngIf="selectedOrg" class="details"
                         (orgChanged)="orgChanged($event)"
  ></app-admin-org-details>

</div>

  <!--      <td mat-cell *matCellDef="let org">-->
<!--        {{ org.account.subscription.plan.name }}-->
<!--        <ng-container *ngIf="org.stripe?.frozen_time">-->
<!--          <span>{{ org.stripe.frozen_time | date}}</span>-->
<!--          <button mat-icon-button color="accent" (click)="showAdvanceTime(org)"><fa-icon [icon]="clockIcon"></fa-icon></button>-->
<!--        </ng-container>-->
<!--      </td>-->
