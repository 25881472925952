import {NgModule} from '@angular/core';
import {TestRunTable} from './testrun-table/test-run-table.component';
import {SharedModule} from '../shared/shared.module';
import {WidgetsModule} from '../widgets/widgets.module';
import {StartRunDialogComponent} from './start-run-dialog/start-run-dialog.component';
import {DropdownsModule} from '../dropdowns/dropdowns.module';
import {DateFnsModule} from 'ngx-date-fns';
import {TestRunComponent} from './test-run/test-run.component';
import {BuildLogComponent} from './test-run/build-log/build-log.component';
import {HighlightModule} from 'ngx-highlightjs';
import {DashboardComponent} from './dashboard.component';
import {MatSortModule} from '@angular/material/sort';
import {TestSummariesComponent} from './test-run/test-summaries/test-summaries.component';
import {JobStatsComponent} from './test-run/job-stats/job-stats.component';


@NgModule({
  declarations: [
    TestRunTable,
    StartRunDialogComponent,
    TestRunComponent,
    BuildLogComponent,
    DashboardComponent,
    TestSummariesComponent,
    JobStatsComponent
  ],
  imports: [
    DateFnsModule,
    SharedModule,
    WidgetsModule,
    DropdownsModule,
    HighlightModule,
    MatSortModule,
  ]
})
export class DashboardModule { }
