import {NgModule} from '@angular/core';
import {MaterialModule} from './material.module';
import {ShortDurationPipe} from './short-duration.pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ResolversModule} from '@services/resolvers/resolvers.module';
import {UiServicesModule} from '@services/ui-services/ui-services.module';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';


@NgModule({
  declarations: [
    ShortDurationPipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    FontAwesomeModule,
    ResolversModule,
    UiServicesModule,
    RouterModule
  ],
  exports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    FontAwesomeModule,
    ShortDurationPipe,
    ResolversModule,
    ReactiveFormsModule,
    UiServicesModule,
    RouterModule
  ]
})
export class SharedModule { }
