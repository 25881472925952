import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent {
  @Input() isNew: boolean = true;
  @Input() form: FormGroup;
  @Input() saving = false;
  @Input() label: string;

  @Output() savedClicked = new EventEmitter<any>();

  spinner = faSpinner;

  save() {
    this.savedClicked.emit();
    this.form.markAsPristine();
  }
}
