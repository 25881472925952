import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApiInterceptor} from './interceptors/api.interceptor';
import {HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '@environment';
import * as Sentry from "@sentry/angular-ivy";
import {Router} from '@angular/router';
import {DateFnsModule} from 'ngx-date-fns';
import {SharedModule} from './features/shared/shared.module';
import {SignupModule} from './features/signup/signup.module';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {GithubAppInstalledComponent} from './github-app-installed/github-app-installed.component';
import {WidgetsModule} from './features/widgets/widgets.module';
import {AcceptInviteComponent} from './features/signup/accept-invite/accept-invite.component';
import {HttpErrorInterceptor} from './interceptors/http-error.interceptor';
import {BadGatewayComponent} from './auth/bad-gateway/bad-gateway.component';
import {NgOptimizedImage} from '@angular/common';
import {LogosModule} from './features/logos/logos.module';
import {HoldingPageComponent} from './holding-page/holding-page.component';
import {MainModule} from './features/main/main.module';
import {NavModule} from './features/nav/nav.module';
import {OrganisationDeletedMessage} from './delete-org-message/organisation-deleted-message.component';
import {MatNativeDateModule} from '@angular/material/core';
import {PrivacyComponent} from './privacy/privacy.component';
import {TermsComponent} from './terms/terms.component';
import {PricingPlansComponent} from './pricing/pricing-plans/pricing-plans.component';
import {AccountModule} from './features/settings/account/account.module';

let extraProviders=[];

if (environment.sentryDsn) {
  extraProviders.push({
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },)
}

@NgModule({
  declarations: [
    AppComponent,
    GithubAppInstalledComponent,
    AcceptInviteComponent,
    BadGatewayComponent,
    HoldingPageComponent,
    OrganisationDeletedMessage,
    PrivacyComponent,
    TermsComponent,
    PricingPlansComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NavModule,
    SharedModule,
    SignupModule,
    LogosModule,
    DateFnsModule.forRoot(),
    HttpClientModule,
    BreadcrumbModule,
    WidgetsModule,
    NgOptimizedImage,
    MainModule,
    MatNativeDateModule,
    AccountModule
  ],
  providers: [
    CookieService,
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          bash: () => import('highlight.js/lib/languages/bash'),
          json: () => import('highlight.js/lib/languages/json'),
        },
        themePath: 'assets/styles/highlightjs/light.css'
      }
    },
    // {provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
    ...extraProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
