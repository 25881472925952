import {Component, Input} from '@angular/core';
import {faCircleInfo} from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-info-icon',
  templateUrl: './info-icon.component.html',
  styleUrls: ['./info-icon.component.scss']
})
export class InfoIconComponent {
  icon = faCircleInfo;

  @Input() tooltip: string;
}
