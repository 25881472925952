import {Component, OnInit} from '@angular/core';
import {WebHook, WebhookHistory} from '@models/generated.model';
import {ActivatedRoute, Router} from '@angular/router';
import {WebhooksService} from '@services/api-services/webhooks.service';

@Component({
  selector: 'app-webhooks-form-page',
  templateUrl: './webhooks-form-page.component.html',
  styleUrls: ['./webhooks-form-page.component.scss']
})
export class WebhooksFormPageComponent implements OnInit {
  webhook: WebHook;
  history: WebhookHistory[];

  constructor(private router: Router,
              private webhookService: WebhooksService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.webhook = this.route.snapshot.data['webhook'];
    if (this.webhook) {
      this.webhookService.getHistory(this.webhook.id).subscribe(history => {
        this.history = history;
      });
    }
  }

  async cancelled() {
    if (this.webhook) {
      await this.router.navigate(['../..'], {relativeTo: this.route})
    } else {
      await this.router.navigate(['..'], {relativeTo: this.route})
    }
  }

  async saved() {
    await this.cancelled();
  }


}
