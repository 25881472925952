import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {FormUtilsService} from '@services/form-utils.service';
import {IntegrationsService} from '@services/api-services/integrations.service';
import {MatDialog} from '@angular/material/dialog';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-rocketchat-credentials',
  templateUrl: './rocketchat-credentials-dialog.component.html',
  styleUrls: ['./rocketchat-credentials-dialog.component.scss']
})
export class RocketchatCredentialsDialog {

  form: FormGroup;

  constructor(private fb: FormBuilder,
              private integrationsService: IntegrationsService,
              private snackBar: MatSnackBar,
              private errorDialogService: ErrorDialogService,
              private dialog: MatDialog,
              private formUtils: FormUtilsService) {
    this.form = fb.group({
      url: ['', {validators: [Validators.required, this.formUtils.urlValidator()],
                 updateOn: 'blur'}],
      user_id: ['', Validators.required],
      access_token: ['', Validators.required],
    });
  }

  save() {
    this.integrationsService.createRocketchatIntegration(this.form.value).subscribe({
      next: () => {
        this.dialog.closeAll();
        this.snackBar.open('Connection successful', "OK", {duration: 2000});
      },
      error: (resp) => {
        this.errorDialogService.show('Failed to connect', resp?.error?.detail);
      }
    })
  }
}
