import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormUtilsService} from '@services/form-utils.service';
import {NotificationsService} from '@services/api-services/notifications.service';
import {NewNotification, Notification, PlatformEnum} from '@models/generated.model';
import {WebhooksService} from '@services/api-services/webhooks.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatDialog} from '@angular/material/dialog';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {IntegrationsService} from '@services/api-services/integrations.service';

@UntilDestroy()
@Component({
  selector: 'app-notification-form',
  templateUrl: './notification-form.component.html',
  styleUrls: ['./notification-form.component.scss']
})
export class NotificationFormComponent implements OnInit {

  form: FormGroup;
  saving = false;
  connectedPlatforms: PlatformEnum[];

  @Input() cancelLabel = 'Cancel';
  @Input() projectId: number | undefined;
  @Input() notification: Notification | undefined;

  @Output() saved = new EventEmitter<Notification>();
  @Output() cancelled = new EventEmitter<any>();

  @ViewChild('confirmDeleteRef', { static: true }) confirmDeleteRef: TemplateRef<any>;

  constructor(private notificationService: NotificationsService,
              private webhookService: WebhooksService,
              private errorDialogService: ErrorDialogService,
              private integrationService: IntegrationsService,
              private snackBar: MatSnackBar,
              private matDialog: MatDialog,
              private authService: AuthenticationService,
              private formUtils: FormUtilsService,
              private fb: FormBuilder) {

  }

  ngOnInit(): void {
    this.integrationService.connectedMessagingPlatforms$.subscribe(platforms => {
      this.connectedPlatforms = platforms;
      this.createForm(this.notification);
    });
  }

  createForm(notification?: Notification): void {
    const platform = (notification?.platform) || this.connectedPlatforms[0];

    this.form = this.fb.group({
      name: [notification?.name],
      platform: [platform, Validators.required],
      project_id: [notification?.project_id],
      on_pass: [notification?.on_pass],
      on_fixed: [notification?.on_fixed],
      on_fail: [notification?.on_fail],
      on_flake: [notification?.on_flake],
      branch_regex: [notification?.branch_regex],
      include_private: [notification?.include_private || false],
      channel: [(notification?.channel_id)?
        {id: notification.channel_id,
         name: notification.channel_name}:'', Validators.required]
    });

    if (!notification) {
      this.form.patchValue({on_pass: false, on_fixed: true, on_fail: true, on_flake: true});
    }

    this.form.controls['include_private'].valueChanges.pipe(untilDestroyed(this))
      .subscribe(p => {
        this.form.patchValue({channel: null});
    });

    this.form.controls['platform'].valueChanges.pipe(untilDestroyed(this))
      .subscribe(p => {
        this.form.patchValue({channel: null});
    });
  }

  private getNewNotification(): NewNotification {
    let base = {...this.form.value};
    const channel = this.form.value.channel;
    base.organisation_id = this.authService.currentOrganisationId;
    base.channel_id = channel.id;
    base.channel_name = channel.name;
    if (!base.on_pass) {
      base.on_pass = false;
    }
    delete base.channel;
    if (!this.projectId) {
      return base;
    } else {
      return {...base, project_id: this.projectId };
    }
  }

  save() {
    this.saving = true;

    let base = this.getNewNotification();

    if (!this.notification) {
        this.notificationService.create(base).subscribe({
          next: created => {
            this.snackBar.open('Notification created', "OK", {duration: 2000});
            this.saved.next(created);
          },
          error: err => {
            this.errorDialogService.show("Failed to create notification", err.error?.detail);
            this.cancelled.next(null);
          },
          complete: () => {
            this.saving = false;
          }
        });
    } else {
      const notification = {...this.notification, ...base};
      this.notificationService.update(notification).subscribe({
        next: created => {
          this.snackBar.open('Notification updated', "OK", {duration: 2000});
          this.saved.next(created);
        },
        error: err => {
          this.errorDialogService.show("Failed to update notification", err.error?.detail);
          this.cancelled.next(null);
        },
        complete: () => { this.saving = false; }
      })
    }

  }

  cancel() {
    this.cancelled.next(null);
  }

  delete() {
    const id = this.notification!.id;
    this.notificationService.delete(id).subscribe(() => {
      this.cancel();
    });
  }

  confirmDelete() {
    this.matDialog.open(this.confirmDeleteRef);
  }

  sendTestMessage() {
    const notify = this.getNewNotification();
    this.notificationService.sendTestMessage(notify).subscribe({
      next: () => {
        this.snackBar.open(`Posted: you should see a message with a notification on the ${notify.platform} channel "${notify.channel_name}"`, "OK", {duration: 10000});
      },
      error: (err) => {
        this.errorDialogService.show("Failed to post notification", err.error?.detail);
      }
    })
  }
}
