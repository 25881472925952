import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {IntegrationSummary} from '@models/generated.model';
import {IntegrationsService} from '@services/api-services/integrations.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {
  integrations: IntegrationSummary[];

  constructor(private integrationsService: IntegrationsService) {
  }

  ngOnInit(): void {
    this.integrationsService.integrations$.pipe(untilDestroyed((this)))
      .subscribe(platforms => {
      this.integrations = [...platforms];
    });
  }
}
