import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-accept-invite',
  templateUrl: './accept-invite.component.html',
  styleUrls: ['./accept-invite.component.scss']
})
export class AcceptInviteComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private snackbar: MatSnackBar,
              private authService: AuthenticationService) {
  }

  async ngOnInit() {
    const token = this.route.snapshot.params['token'];
    if (!this.authService.isAuthenticated) {
      // store the token and sign the user up
      this.authService.setOrgSignupToken(token);
      await this.router.navigate(['/signup']);
    } else {
      // otherwise we can just accept the token
      this.authService.acceptInvite(token).subscribe((org) => {
        this.snackbar.open(`Accepted invitation to organisation ${org.name}`, "OK",
          {duration: 5000});
        this.router.navigate(['/']);
      });
    }
  }


}
