import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {TestRunDetail} from '../../models/generated.model';
import {TestRunService} from '../api-services/test-run.service';

@Injectable({
  providedIn: 'root'
})
export class TestRunResolver  {

  constructor(private service: TestRunService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TestRunDetail> {
    const pname = route.params['project'];
    const localid = route.params['localid'];
    if (pname && localid) {
      return this.service.getTestRunByProjectName(pname, localid);
    } else {
      return this.service.getTestRun(Number(<string>route.queryParams['id']));
    }
  }
}
