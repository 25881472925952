import {NgModule} from '@angular/core';
import {SignupComponent} from './signup.component';
import {SharedModule} from '../shared/shared.module';
import {OauthCallbackComponent} from './oauth-callback/oauth-callback.component';
import {WidgetsModule} from '../widgets/widgets.module';
import {NgOptimizedImage} from '@angular/common';
import {LogosModule} from '../logos/logos.module';


@NgModule({
  declarations: [
    SignupComponent,
    OauthCallbackComponent
  ],
  imports: [
    SharedModule,
    LogosModule,
    WidgetsModule,
    NgOptimizedImage
  ]
})
export class SignupModule { }
