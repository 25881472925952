<div class="panel">
  <ng-container *ngFor="let item of items; let i=index">
    <button mat-icon-button *ngIf="current===i" color="accent">
      <fa-icon [icon]="selectedCircle"></fa-icon>
    </button>
    <button mat-icon-button *ngIf="current!==i" (click)="select(i)">
      <fa-icon [icon]="nonSelectedCircle"></fa-icon>
    </button>
  </ng-container>
</div>
