import {Component} from '@angular/core';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {FormControl, Validators} from '@angular/forms';
import {IconsService} from '@services/ui-services/icon.service';
import {AccountService} from '@services/api-services/account.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-choose-org-name',
  templateUrl: './choose-org-name.component.html',
  styleUrls: ['./choose-org-name.component.scss']
})
export class ChooseOrgNameComponent {

  nameFormControl: FormControl;

  constructor(private authService: AuthenticationService,
              private accountService: AccountService,
              private route: ActivatedRoute,
              private router: Router,
              public icons: IconsService) {
    this.nameFormControl = new FormControl(this.authService.currentOrganisation!.name, Validators.required)
  }

  async next() {
    const name = this.nameFormControl.value;
    this.accountService.updateOrganisation({name, onboarding_state: "choose-hosted"}).subscribe(async () => {
      this.authService.currentOrganisation!.name = name;
      await this.router.navigate(['../choose-hosted'], {relativeTo: this.route});
    });
  }
}
