import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-circle-progress',
  templateUrl: './circle-progress.component.svg',
  styleUrls: ['./circle-progress.component.scss']
})
export class CircleProgressComponent implements OnInit, OnChanges {
  offset: string;
  dasharray: string;

  @Input() radius: number = 40;
  @Input() value: number = 0;
  @Input() widthPercent = 20;

  ngOnInit(): void {
    this.update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  private update() {
    const c = 2 * Math.PI * this.radius;
    this.dasharray = `${c}px`;
    this.offset = `${c-(this.value * c / 100)}px`;
  }

}
