<h2>Choose a name for your organisation or team:</h2>

<mat-form-field appearance="outline" class="org-name">
  <input matInput [formControl]="nameFormControl" placeholder="Organisation name">
  <mat-error>This field is required</mat-error>
</mat-form-field>

<div class="next-button">
  <button mat-raised-button color="primary" [disabled]="nameFormControl.invalid" (click)="next()">Next <fa-icon [icon]="icons.next"></fa-icon> </button>
</div>
