import {Component, OnInit} from '@angular/core';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {faCopy} from '@fortawesome/free-regular-svg-icons';
import {IconsService} from '@services/ui-services/icon.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '@environment';
import {APITokenExt, APITokensService} from '@services/api-services/api-tokens.service';

@Component({
  selector: 'app-api-tokens',
  templateUrl: './api-tokens.component.html',
  styleUrls: ['./api-tokens.component.scss']
})
export class ApiTokensComponent implements OnInit {

  tokens: APITokenExt[] = [];
  copyIcon = faCopy;
  apiDocUrl: string

  constructor(private apiTokensService: APITokensService,
              public icons: IconsService,
              private snackbar: MatSnackBar,
              private errorDialogService: ErrorDialogService) {
    this.apiDocUrl = `${environment.server}/redoc`;
  }

  ngOnInit(): void {
    this.apiTokensService.getAPITokens().subscribe(tokens => {
      this.tokens = tokens;
    })
  }

  revokeToken(id: number) {
    this.apiTokensService.deleteAPIToken(id).subscribe({
      next: () => {
        const idx = this.tokens.findIndex(t => t.id === id);
        this.tokens.splice(idx, 1);
        this.snackbar.open('Token deleted', 'OK', {duration: 2000});
      }, error: () => {
        this.errorDialogService.show("Failed to create API token")
      }
    })
  }

  createToken() {
    this.apiTokensService.createAPIToken().subscribe({
      next: token => {
        this.tokens.push(token);
      }, error: () => {
        this.errorDialogService.show("Failed to create API token")
      }
    })
  }

  tokenCopied() {

  }
}
