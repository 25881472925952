import {Injectable} from '@angular/core';
import {
  faBitbucket,
  faGithub,
  faGitlab,
  faGoogle,
  faJira,
  faRocketchat,
  faSlack
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowUpRightFromSquare,
  faBan,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleInfo,
  faCodeBranch,
  faEdit,
  faGear,
  faHome,
  faPlus,
  faSpinner,
  faTrash,
  faTriangleExclamation,
  faUsersGear,
  faWifi,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {PlatformEnum} from '@models/generated.model';
import {
  faCancel,
  faChevronsDown,
  faChevronsUp,
  faCodeCommit,
  faFolder,
  faForward,
  faHourglassEnd,
  faListCheck,
  faRepeat,
  faUser,
  faWifiSlash
} from '@fortawesome/pro-solid-svg-icons';
import {faCopy, faFile} from '@fortawesome/free-regular-svg-icons';
import {
  faBuilding,
  faClock,
  faCloud,
  faFileInvoiceDollar,
  faMicroscope,
  faUserSecret
} from '@fortawesome/pro-regular-svg-icons';

type IconMap = {[key: string]: IconDefinition};

@Injectable()
export class IconsService {

  add = faPlus;
  github = faGithub;
  bitbucket = faBitbucket;
  google = faGoogle;
  gitlab = faGitlab;
  jira = faJira;
  home = faHome;
  edit = faEdit;
  dashboard = faHome;
  projects = faCloud;
  settings = faGear;
  admin = faUsersGear;
  connected = faWifi;
  disconnected = faWifiSlash;
  branch = faCodeBranch;
  commit = faCodeCommit;
  matching = faListCheck;
  project = faFolder;
  spinner = faSpinner;
  close = faXmark;
  passed = faCircleCheck;
  failed = faTriangleExclamation;
  cancelled = faBan;
  timeout = faHourglassEnd;
  disconnect = faCancel;
  user = faUser;
  left = faChevronLeft;
  file = faFile;
  test = faMicroscope;
  repeat = faRepeat;
  right = faChevronRight;
  openBox = faChevronsDown;
  closeBox = faChevronsUp;
  copy = faCopy;
  next = faForward;
  plan = faFileInvoiceDollar
  publicIcon = faCloud;

  impersonatingIcon = faUserSecret;

  externalLink = faArrowUpRightFromSquare;

  info = faCircleInfo;
  warning = faTriangleExclamation;
  error = faTriangleExclamation;
  organisation = faBuilding;


  private platformIcons: IconMap = {
    github: faGithub,
    bitbucket: faBitbucket,
    slack: faSlack,
    rocketchat: faRocketchat,
    gitlab: faGitlab
  }

  private statusIcons: IconMap = {
    passed: faCircleCheck,
    pending: faSpinner,
    started: faSpinner,
    building: faSpinner,
    running: faSpinner,
    failed: faTriangleExclamation,
    cancelled: faBan,
    spinner: faSpinner,
    timeout: this.timeout
  }
  delete = faTrash;
  clock = faClock;

  constructor() { }

  platform(platform: PlatformEnum): IconDefinition {
    return this.platformIcons[platform];
  }

  status(status: string): IconDefinition {
    return this.statusIcons[status];
  }

}
