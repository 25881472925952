import {Component, Input} from '@angular/core';
import {UserProfile} from '@models/generated.model';
import {FormControl} from '@angular/forms';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {Router} from '@angular/router';
import {IconsService} from '@services/ui-services/icon.service';
import {AccountService} from '@services/api-services/account.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  profile: UserProfile;
  orgControl = new FormControl();
  freePlan = false;

  @Input() showOrgSelect  = true;
  @Input() showNavButtons: boolean = true;

  constructor(
    public authService: AuthenticationService,
    private accountService: AccountService,
    private router: Router,
    public icons: IconsService) {

    this.profile = authService.profile;

    if (this.profile?.organisations?.length) {
      this.orgControl.setValue(this.authService.currentOrganisation);
    }

  }

  ngOnInit(): void {
    this.authService.getProfile$().subscribe(p => {
      this.profile = p;
    })

    this.showOrgSelect = this.showNavButtons = this.authService.isOrgInitialised;
    this.orgControl.valueChanges.subscribe((org) => {
      this.authService.setCurrentOrganisation(org);
      this.router.navigate(['/'])
    });

    this.authService.currentOrganisation$.subscribe(org => {
      this.showOrgSelect = this.showNavButtons = org.onboarding_state == 'completed';
      if (this.orgControl.value !== org) {
        this.orgControl.setValue(org);
      }
    });

    this.authService.currentOrganisation$.subscribe(org => {
      this.freePlan = (this.authService.profile.account!.subscription.plan.name == 'Free') &&
        org.onboarding_state === 'completed';
    });
  }

  async logOut() {
    this.authService.logOut();
  }

  gotoBillingPortal() {
    this.accountService.gotoCustomerPortal();
  }

}
