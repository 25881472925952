<div class="logs" [class.firstLoad]="!firstLoad">
    <ng-container *ngFor="let log of logs">
      <span class="log-line" *ngIf="showLogLine(log)" [class]="log.level">
        <fa-icon [icon]="logIcons[log.level]" class="level" [class]="log.level"></fa-icon>
        <span class="date" *ngIf="!showHostControl.value">{{ log.ts | date:'yy-MM-dd HH:mm:ss.SSS' }}</span>

        <span class="date-host" *ngIf="showHostControl.value">
          <span class="date">{{ log.ts | date:'yy-MM-dd HH:mm:ss.SSS' }}</span>
          <span class="host">{{ log.host }}</span>
        </span>
        <span class="msg">{{ log.msg }}</span>
      </span>
    </ng-container>

    <div #last></div>
</div>
<button *ngIf="showLockIcon" mat-fab class="scroll-lock-icon"
        (click)="toggleScrollLock()">
    <fa-icon [icon]="lockIcon"></fa-icon></button>
