<div class="org-name">
  <span>
    <mat-icon>domain</mat-icon>
    <h3>{{ org.name }}<ng-container *ngIf="showHostedState">: <span *ngIf="org.prefer_self_host; else hosted">Self Hosted</span></ng-container></h3>
  </span>
  <button mat-raised-button (click)="setHosted(true)"
          *ngIf="org.prefer_self_host">Switch to fully hosted</button>
  <button mat-raised-button (click)="setHosted(false)"
          *ngIf="!org.prefer_self_host">Switch to self-hosted</button>
</div>
<ng-template #hosted><span>Hosted</span></ng-template>
