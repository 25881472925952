import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {BehaviorSubject, filter, map, Observable, switchMap, tap} from 'rxjs';
import {NewNotification, Notification, NotificationChannel} from '@models/generated.model';
import {AuthenticationService} from '@services/api-services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private _notifications = new BehaviorSubject<Notification[]>([]);

  constructor(private http: HttpClient,
              private authService: AuthenticationService) {

     authService.currentOrganisation$.pipe(switchMap( () => this.list())).subscribe(
      notifications => {
        this._notifications.next(notifications);
      }
    );
  }

  get notifications(): Notification[] {
    return this._notifications.value;
  }

  get notifications$(): Observable<Notification[]> {
    return this._notifications.asObservable();
  }

  getNotification$(id: number): Observable<Notification> {
    return this.notifications$.pipe(
      map(notifications  => notifications.find(n => n.id === id)),
      filter(n => !!n),
      map(n => n as Notification)
    )
  }

  create(notification: NewNotification): Observable<Notification> {
    return this.http.post<Notification>(`/notification`, notification).pipe(
      tap( (created) => {
        this._notifications.value.push(created);
        this.notify();
      })
    );
  }

  private notify() {
    this._notifications.next(this._notifications.value);
  }

  update(notification: Notification): Observable<Notification> {
    return this.http.put<Notification>(`/notification/${notification.id}`, notification).pipe(
      tap( (created) => {
        const idx = this.notifications.findIndex(n => n.id === notification.id);
        this.notifications[idx] = created;
        this.notify();
      })
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`/notification/${id}`).pipe(
      tap( () => {
        const idx = this.notifications.findIndex(n => n.id === id);
        this.notifications.splice(idx, 1);
        this.notify();
      }));
  }

  sendTestMessage(notification: NewNotification): Observable<any> {
    return this.http.post('/notification/test-message', notification);
  }

  searchChannels(platform: string,
                 search?: string,
                 includePrivate: boolean = false): Observable<NotificationChannel[]> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    if (includePrivate) {
      params = params.set('include_private', includePrivate);
    }
    return this.http.get<NotificationChannel[]>(`/notification/channels/${platform}/${this.authService.currentOrganisationId}`,
      {params});
  }


  get(id: number): Observable<Notification> {
    return this.http.get<Notification>(`/notification/${id}`);
  }

  list(project_id?: number): Observable<Notification[]> {
    let params = new HttpParams(
      {fromObject: {organisation_id: this.authService.currentOrganisationId}});

    if (project_id) {
      params = params.set('project_id', project_id.toString());
    }
    return this.http.get<Notification[]>(`/notification`, {params});
  }

}
