<div class="page">
  <h1>Invite Users</h1>

  <div class="card">

    <form [formGroup]="inviteForm" class="card-contents">

      <p>Enter the emails of users you wish to invite</p>
      <p>Note that you can also let users sign up using this
        <button mat-flat-button type="button" [cdkCopyToClipboard]="orgInviteLink" (click)="copied()">invite link
          <fa-icon [icon]="icons.copy" size="xs"></fa-icon>
        </button>
      </p>

      <ng-container formArrayName="invites">
        <div *ngFor="let invite of invites.controls; let i=index" class="user-invite">
          <ng-container [formGroupName]="i">

            <mat-form-field appearance="fill" class="email">
              <mat-label>Email</mat-label>
              <input matInput type="email" formControlName="email" email="true">
              <mat-error>Please enter a valid email</mat-error>
            </mat-form-field>

            <div class="permissions-field">
              <mat-radio-group formControlName="permissions">
                <mat-radio-button value="member">Member</mat-radio-button>
                <mat-radio-button value="admin">Admin</mat-radio-button>
              </mat-radio-group>
            </div>

            <button type="button" mat-icon-button (click)="removeInvite(i)">
              <mat-icon>delete</mat-icon>
            </button>

          </ng-container>
        </div>


      </ng-container>
      <button mat-raised-button type="button" (click)="addInvite()" [disabled]="inviteForm.invalid"><fa-icon [icon]="icons.add"></fa-icon> Add another</button>
    </form>
    <div class="row-space-between toolbar">
      <button mat-raised-button [routerLink]="'..'" type="button" >Cancel</button>
      <button mat-raised-button color="primary" type="button"
              [disabled]="inviteForm.invalid || inviteForm.pristine" (click)="save()">Send invites
      </button>
    </div>
  </div>
</div>
