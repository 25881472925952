import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {NotificationPlatforms} from '@models/consts';
import {IntegrationsService} from '@services/api-services/integrations.service';
import {NotificationsService} from '@services/api-services/notifications.service';
import {Notification} from '@models/generated.model';
import {IconsService} from '@services/ui-services/icon.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TriggerableModel} from '@models/triggerable.model';


@UntilDestroy()
@Component({
  selector: 'app-notification-table',
  templateUrl: './notification-table.component.html',
  styleUrls: ['./notification-table.component.scss']
})
export class NotificationTableComponent implements OnInit {

  connectedPlatforms: string[] | null = null;
  notifications: Notification[];
  fetched = false;

  constructor(private integrationsService: IntegrationsService,
              public icons: IconsService,
              private router: Router,
              private route: ActivatedRoute,
              private notificationsService: NotificationsService) {
  }

  ngOnInit(): void {
    this.integrationsService.integrations$.pipe(untilDestroyed(this))
      .subscribe(integrations => {
        integrations = integrations.filter(i => NotificationPlatforms.includes(i.name));
        this.connectedPlatforms = integrations.filter(i => i.connected)
          .map(p => p.name)
      });

    this.notificationsService.notifications$.pipe(untilDestroyed(this)).subscribe(notifications => {
      this.notifications = notifications;
      this.fetched = true;
    });
  }

  get triggerables(): TriggerableModel[] {
    return this.notifications as TriggerableModel[];
  }

  async selected(notification: Notification) {
    await this.router.navigate(['./edit', notification.id], {relativeTo: this.route})
  }
}
