import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {IntegrationSummary, PlatformType, Project} from '@models/generated.model';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {MatDialog} from '@angular/material/dialog';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ProjectService} from '@services/api-services/project.service';
import {IconsService} from '@services/ui-services/icon.service';
import {IntegrationsService} from '@services/api-services/integrations.service';


@UntilDestroy()
@Component({
  selector: 'app-integrations-list',
  templateUrl: './integrations-list.component.html',
  styleUrls: ['./integrations-list.component.scss']
})
export class IntegrationsListComponent implements OnInit, OnChanges {

  @Input() filter: PlatformType;
  @Input() showAvailable = true;
  @Input() redirectHere: boolean = false

  fetchingProjects = false;
  filteredPlatforms: IntegrationSummary[];
  linkedProjects: Project[];

  @ViewChild('confirmDeleteRef', { static: true }) confirmDeleteRef: TemplateRef<any>;

  selected: IntegrationSummary | undefined;

  constructor(private authService: AuthenticationService,
              private matDialog: MatDialog,
              private integrationsService: IntegrationsService,
              private errorDialogService: ErrorDialogService,
              private snackBar: MatSnackBar,
              private projectService: ProjectService,
              public icons: IconsService) {
  }

  ngOnInit(): void {
    this.filterPlatforms();
  }

  private filterPlatforms() {
    this.integrationsService.integrations$.pipe(untilDestroyed((this)))
      .subscribe(platforms => {
      this.filteredPlatforms = platforms.filter(i =>
        (!this.filter || i.type === this.filter) && i.connected == !this.showAvailable);
    });
  }

  platformSelected(platform: IntegrationSummary) {
    if (this.showAvailable) {
      this.integrationsService.authorizePlatform(platform.name, this.redirectHere);
    } else {
      this.selected = platform;
      if (platform.type === 'git') {
        this.fetchingProjects = true;
        // check for projects using this
        this.projectService.list({platform: platform.name}).subscribe(projects => {
          this.linkedProjects = projects;
          this.fetchingProjects = false;
        });
      }
      this.matDialog.open(this.confirmDeleteRef);
    }

  }

  disconnect() {
    const name = this.selected!.name;
    this.integrationsService.disconnectPlatform(name).subscribe({
      next: () => {
        this.snackBar.open(`Disconnected ${name}`, "OK", {duration: 2000});
        this.selected!.connected = false;
        delete this.selected;
      },
      error: () => {
        this.errorDialogService.show(`Failed to disconnect ${name}`);
        delete this.selected;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['platforms']) {
      this.filterPlatforms();
    }
  }

}
