import {Component} from '@angular/core';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {AccountService} from '@services/api-services/account.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-choose-hosted',
  templateUrl: './choose-hosted.component.html',
  styleUrls: ['./choose-hosted.component.scss']
})
export class ChooseHostedComponent {

    constructor(private authService: AuthenticationService,
                private accountService: AccountService,
                private router: Router,
                private route: ActivatedRoute) {
    }

  selectSelfHosted(isSelfHosted: boolean) {
    this.accountService.updateOrganisation({prefer_self_host: isSelfHosted,
    onboarding_state: 'choose-notification'}).subscribe(
      async () => {
          await this.router.navigate(['../choose-notification'], {relativeTo: this.route});
    });
  }
}
