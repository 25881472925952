<app-org-name-heading [showHostedState]="true"></app-org-name-heading>

<div class="contents">
  <h2>How do you want to be notified?</h2>

  <p>Cykubed can send messages to any of the following platforms: just select one to connect</p>

  <ng-container *ngIf="!connectedPlatforms?.length else isConnected">
    <app-integrations-list filter="messaging" [showAvailable]="true"
                               [redirectHere]="true"></app-integrations-list>

    <div class="button-toolbar">
      <button mat-raised-button color="accent" (click)="next()">I'll do it later <fa-icon [icon]="icons.next"></fa-icon> </button>
    </div>

  </ng-container>

  <ng-template #isConnected>
      <app-notification-form cancelLabel="I'll do it later" (cancelled)="next()"
                             (saved)="next()"
      ></app-notification-form>
  </ng-template>

</div>
