import { Component } from '@angular/core';

@Component({
  selector: 'app-webhooks-page',
  templateUrl: './webhooks-page.component.html',
  styleUrls: ['./webhooks-page.component.scss']
})
export class WebhooksPageComponent {

}
