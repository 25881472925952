import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  IntegrationSummary,
  OAuthCodeResponse,
  OAuthPostInstall,
  PlatformEnum,
  PlatformType,
  RocketChatAuth
} from '@models/generated.model';
import {BehaviorSubject, filter, iif, map, Observable, of, Subject, switchMap, take, tap} from 'rxjs';
import {AuthenticationService, OAUTH_STATE_KEY, ORG_SIGNUP_TOKEN} from '@services/api-services/authentication.service';
import {environment} from '@environment';
import {MatDialog} from '@angular/material/dialog';
import {
  RocketchatCredentialsDialog
} from '../../features/settings/integrations/rocketchat-credentials/rocketchat-credentials-dialog.component';
import {Router} from '@angular/router';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {faBitbucket, faGithub, faGitlab, faJira, faRocketchat, faSlack} from '@fortawesome/free-brands-svg-icons';

export const POST_OAUTH_REDIRECT='post_oauth_redirect';
// const CURRENT_ORG_ID_KEY = 'auth_orgid'


@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  private _allIntegrations = new BehaviorSubject<IntegrationSummary[] | null>(null);
  platformConnected$ = new Subject<PlatformEnum>();
  platformDisconnected$ = new Subject<PlatformEnum>();
  iconMap = {
    github: faGithub,
    gitlab: faGitlab,
    bitbucket: faBitbucket,
    jira: faJira,
    slack: faSlack,
    rocketchat: faRocketchat
  }

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private router: Router,
              private errorDialog: ErrorDialogService,
              private dialog: MatDialog) {
    authService.getProfile$().pipe(
      filter(() => !!this.authService.currentOrganisationId),
      take(1),
      ).subscribe(() => {
      this.http.get<IntegrationSummary[]>(`/integration/${this.authService.currentOrganisationId}`).subscribe(
        items => {
        this._allIntegrations.next(items);
      });
    });
  }

  requestAuthToken(platform: PlatformEnum, code: string, state?: string) {

    return iif( () => this.authService.isAuthenticated,
      this.authService.fetchProfile(), of(null)).pipe(
        switchMap(profile => {
          const orgtoken = localStorage.getItem(ORG_SIGNUP_TOKEN);
          let payload: OAuthCodeResponse = {code};
          if (orgtoken) {
            localStorage.removeItem(ORG_SIGNUP_TOKEN);
            payload.orgtoken = orgtoken;
          }
          if (profile) {
            payload.organisation_id = profile.uisettings.current_org_id;
          }
          return this.http.post<OAuthPostInstall>(`/${platform}/auth`, payload);
        }),
        filter(result => {
          this.updateIntegration(result.integration);
          this.authService.setOAuthPostInstallToken(result, state);
          return true;
        })
    )
  }

  private updateIntegration(integ: IntegrationSummary) {
    const idx = this.allIntegrations.findIndex(i => i.name === integ.name);
    this.allIntegrations[idx] = integ;
    this._allIntegrations.next(this.allIntegrations);
    this.platformConnected$.next(integ.name);
  }

  githubAppInstalled(): Observable<any> {
    return this.http.post('/github/app-installed', {});
  }

  get allIntegrations(): IntegrationSummary[]  {
    return this._allIntegrations.value || [];
  }

  getConnectedIntegrations$(type: PlatformType): Observable<IntegrationSummary[]> {
    return this.integrations$.pipe(
      map(integrations => integrations.filter(
        i => i.connected &&  i.type === type)),
      filter( integrations => integrations.length > 0)
    );
  }

  get integrations$(): Observable<IntegrationSummary[]> {
    return this._allIntegrations.asObservable().pipe(
      filter(i => i !== null),
      map(i => i as IntegrationSummary[]));
  }

  get connectedMessagingPlatforms$(): Observable<PlatformEnum[]> {
    return this.integrations$.pipe(
      map(integrations =>
        integrations.filter(i => i.type === 'messaging' && i.connected).map(i => i.name)),
      filter(names => names?.length > 0));
  }

  getIntegrationSummary(platform: PlatformEnum): IntegrationSummary | undefined {
    return this.allIntegrations.find(i => i.name === platform);
  }

  disconnectPlatform(platform: PlatformEnum): Observable<any> {
    return this.http.post<any>(`/${platform}/disconnect/${this.authService.currentOrganisationId}`,
      {}).pipe(
      tap( () => {
        const summary = this.getIntegrationSummary(platform)!;
        summary.connected = false
        this._allIntegrations.next(this.allIntegrations);
        this.platformDisconnected$.next(platform);
      })
    )
  }

  authorizePlatform(name: PlatformEnum, redirectHere: boolean = false) {
    switch (name) {
      case 'bitbucket':
        this.authorizeBitbucket(redirectHere);
        break;
      case 'gitlab':
        this.authorizeGitLab(redirectHere);
        break;
      case 'github':
        this.authorizeGithub(redirectHere);
        break;
      case 'slack':
        this.authorizeSlack(redirectHere);
        break;
      case 'rocketchat':
        this.authorizeRocketChat();
        break;
    }
  }

  authorizeRocketChat() {
    this.dialog.open(RocketchatCredentialsDialog);
  }

  createRocketchatIntegration(details: RocketChatAuth) {
    return this.http.post<IntegrationSummary>(`/rocketchat/authenticate/${this.authService.currentOrganisationId}`, details)
      .pipe(
      tap( (summ) => {
        this.updateIntegration(summ);
      })
    )
  }

  public generateOAuthState(): string {
     const uuid = crypto.randomUUID();
     localStorage.setItem(OAUTH_STATE_KEY, uuid);
     return uuid;
  }

  public getPostOAuthRedirect(): string | null {
    const url = localStorage.getItem(POST_OAUTH_REDIRECT)
    if (url) {
      localStorage.removeItem(POST_OAUTH_REDIRECT);
    }
    return url;
  }

  private initAuthorize(redirectHere: boolean = false) {
    if (redirectHere) {
      localStorage.setItem(POST_OAUTH_REDIRECT, window.location.href);
      // FIXME this is wrong I think? If we're already authenticated when we can just get the state we need
      // localStorage.setItem(CURRENT_ORG_ID_KEY,  this.authService.currentOrganisationId.toString());
    }
  }

  authorizeBitbucket(redirectHere: boolean = false) {
    this.initAuthorize(redirectHere);
    window.location.href = `https://bitbucket.org/site/oauth2/authorize?client_id=${environment.bitbucketClientId}&state=${this.generateOAuthState()}&response_type=code`;
  }

  authorizeGitLab(redirectHere: boolean = false) {
    this.initAuthorize(redirectHere);
    const redirectUrl = encodeURIComponent(`${environment.webUrl}/oauth/callback/gitlab`);
    const scopes=encodeURI('api');
    window.location.href = `https://gitlab.com/oauth/authorize?client_id=${environment.gitlabClientId}&redirect_uri=${redirectUrl}&state=${this.generateOAuthState()}&response_type=code&scope=${scopes}`;
  }

  authorizeGithub(redirectHere: boolean = false) {
    this.initAuthorize(redirectHere);
    window.location.href = `https://github.com/login/oauth/authorize?client_id=${environment.githubClientId}&state=${this.generateOAuthState()}&scope=user:email,write:repo_hook,repo`;
  }

  authorizeSlack(redirectHere: boolean) {
    this.initAuthorize(redirectHere);
    const redirectUrl = encodeURIComponent(`${environment.webUrl}/oauth/callback/slack`);
    window.location.href = `https://slack.com/oauth/v2/authorize?scope=groups:read,users:read,users:read.email,chat:write,chat:write.public,channels:read&client_id=${environment.slackClientId}&redirect_uri=${redirectUrl}&state=${this.generateOAuthState()}`;
  }

  authorizeJira(redirectHere: boolean) {
    this.initAuthorize(redirectHere);
    window.location.href = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${environment.jiraClientId}&scope=read:jira-work%20offline_access&redirect_uri=${environment.webUrl}/oauth/callback/jira&state=${this.generateOAuthState()}&response_type=code&prompt=consent`;
  }

  getIcon(platform: PlatformEnum) {
    return this.iconMap[platform]
  }

}

