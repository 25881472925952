import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationFormComponent} from './notification-form/notification-form.component';
import {NotificationTableComponent} from './notification-table/notification-table.component';
import {NotificationsFormPageComponent} from './notifications-form-page/notifications-form-page.component';
import {ChannelSelectComponent} from './slack-channel-select/channel-select.component';
import {NotificationsPageComponent} from './notifications-page/notifications-page.component';
import {SharedModule} from '../../shared/shared.module';
import {CommonSettingsModule} from '../common/common-settings.module';
import {WidgetsModule} from '../../widgets/widgets.module';
import {IntegrationsModule} from '../integrations/integrations.module';


@NgModule({
  declarations: [
    NotificationFormComponent,
    NotificationTableComponent,
    NotificationsFormPageComponent,
    ChannelSelectComponent,
    NotificationsPageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    IntegrationsModule,
    CommonSettingsModule,
    WidgetsModule
  ],
  exports: [
    NotificationFormComponent
  ]
})
export class NotificationsModule { }
