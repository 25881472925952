import {NgModule} from '@angular/core';
import {MainComponent} from './main.component';
import {SharedModule} from '../shared/shared.module';
import {MainRoutingModule} from './main-routing.module';
import {DashboardModule} from '../dashboard/dashboard.module';
import {SettingsModule} from '../settings/settings.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ReactiveFormsModule} from '@angular/forms';
import {NavModule} from '../nav/nav.module';


@NgModule({
  declarations: [
    MainComponent,
  ],
  imports: [
    SharedModule,
    NavModule,
    DashboardModule,
    SettingsModule,
    FontAwesomeModule,
    MainRoutingModule,
    ReactiveFormsModule
  ]
})
export class MainModule { }
