import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TestSummary} from '@models/testrun-ext.model';
import {CodeFrame} from '@models/generated.model';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IconsService} from '@services/ui-services/icon.service';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';

@UntilDestroy()
@Component({
  selector: 'app-test-summaries',
  templateUrl: './test-summaries.component.html',
  styleUrls: ['./test-summaries.component.scss']
})
export class TestSummariesComponent implements OnChanges, OnInit {


  @Input() summaries: TestSummary[];
  @Input() selection?: TestSummary;
  @Output() selectionChange = new EventEmitter<TestSummary>();

  mode: string;
  selectedCodeFrame: CodeFrame;
  idx = 0;
  breakpoint: BreakpointState;
  leftIcon = faChevronLeft;
  rightIcon = faChevronRight;

  browserLogos: Record<string, string> = {
    firefox: "/assets/img/firefox-logo-colour.svg",
    edge: "/assets/img/edge-logo.svg",
    chrome: "/assets/img/chrome-icon.svg",
    chromium: "/assets/img/chromium-logo.svg",
    electron: "/assets/img/electron-logo.svg"
  }

  constructor(private breakpointObserver: BreakpointObserver,
              public icons: IconsService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selection'] && changes['selection'].currentValue === this.selection) {
      return;
    }
    this.refresh();
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.XSmall,
    Breakpoints.Medium]).pipe(untilDestroyed(this)).subscribe(st => {
      this.breakpoint = st;
    });
    this.refresh();
  }

  get browserLogo(): string | undefined {
    if (this.selection!.browser in this.browserLogos) {
      return this.browserLogos[this.selection!.browser];
    }
    return undefined;
  }

  private refresh() {
    if (this.selection) {
      this.idx = this.summaries.findIndex(x => x.file == this.selection!.file)
      if (this.idx < 0) {
        this.idx = 0;
        delete this.selection;
      }
    } else {
      this.idx = 0;
      if (!this.selection && this.summaries?.length) {
        this.selection = this.summaries[this.idx];
      }
    }
  }

  selectNext() {
    if (this.hasNext) {
      this.idx++;
      this.select();
    }
  }

  selectPrev() {
    if (this.hasPrev) {
      this.idx--;
      this.select();
    }
  }

  get hasNext(): boolean {
    return this.idx !== undefined && this.summaries?.length > 0 &&
      (this.idx < this.summaries!.length - 1);
  }

  get hasPrev(): boolean {
    return this.idx > 0;
  }

  private select() {
    this.selection = this.summaries[this.idx];
    this.selectionChange.next(this.selection);
  }

  get codeFrame(): CodeFrame | undefined {
    return this.selection?.errors?.find(err => !!err.code_frame)?.code_frame;
  }

  get testLine(): number | undefined {
    // test_line from the errors list will override the test line
    const line = this.selection?.errors?.find(err => err.test_line !== undefined)?.test_line;
    if (line === undefined && !!this.selection?.line) {
      return this.selection?.line;
    }
    return line
  }
}
