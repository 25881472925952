import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AdminDateTime, AdminOrganisation, AdminOrganisationList, AdminUserList} from '@models/generated.model';
import {GenericSearchFilter} from '@models/common';

export interface OrganisationFilter extends GenericSearchFilter {
  user_id?: number;
}

export interface UserFilter extends GenericSearchFilter {
  organisation_id?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  getLatestAgentVersion(): Observable<string> {
    return this.http.get('/admin/image/agent/current-version', {responseType: 'text'});
  }

  changeSubscriptionPlan(orgid: number, plan: string, expires: Date): Observable<any> {
    return this.http.post<AdminOrganisation>(`/admin/organisation/${orgid}/change-plan`, {
      plan,
      expires
    });
  }

  addBuildCredits(orgid: number, credits: number): Observable<AdminOrganisation> {
    return this.http.post<AdminOrganisation>(`/admin/organisation/${orgid}/add-build-credits`, {credits});
  }

  getOrganisation(id: number): Observable<AdminOrganisation> {
    return this.http.get<AdminOrganisation>(`/admin/organisation/${id}`);
  }

  getFakeTime(): Observable<Date | undefined> {
    return this.http.get<AdminDateTime>('/admin/fake-time').pipe(map(x => {
      if (x.dt) {
        return new Date(x.dt);
      }
      return;
    }))
  }

  setFakeTime(dt?: Date): Observable<any> {
    const body = (dt)?{dt:dt.toISOString()}:{dt:null};
    return this.http.post(`/admin/fake-time`, body);
  }

  listUsers(filter?: UserFilter): Observable<AdminUserList> {
    let params;
    if (filter) {
      params = new HttpParams({fromObject: filter as any});
    } else {
      params = new HttpParams();
    }
    return this.http.get<AdminUserList>('/admin/user', {params});

  }

  listOrganisations(filter?: OrganisationFilter): Observable<AdminOrganisationList> {
    let params;
    if (filter) {
      params = new HttpParams({fromObject: filter as any});
    } else {
      params = new HttpParams();
    }
    return this.http.get<AdminOrganisationList>('/admin/organisation', {params});
  }

}
