import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {WebsocketService} from '@services/api-services/websocket.service';
import {Router} from '@angular/router';
import {filter} from 'rxjs';
import {AuthenticationService} from '@services/api-services/authentication.service';


@UntilDestroy()
@Component({
  selector: 'app-bad-gateway',
  templateUrl: './bad-gateway.component.html',
  styleUrls: ['./bad-gateway.component.scss']
})
export class BadGatewayComponent implements OnInit {

  constructor(private socketService: WebsocketService,
              private authService: AuthenticationService,
              private router: Router) {
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated) {
      this.socketService.connection$.pipe(
        untilDestroyed(this),
        filter(conn => !!conn)
      ).subscribe(async (v) => {
        // we must be back up
        await this.router.navigate(['/'])
      });
    }
  }

}
