<div class="page">
  <h1>Projects</h1>

  <div class="standard-toolbar">
    <button mat-raised-button color="primary" routerLink="./new">Add new project</button>
  </div>

  <section>
    <div *ngFor="let project of projects" class="mat-elevation-z1 project list-item p1">
      <h2><fa-icon [icon]="getIcon(project)"></fa-icon> {{ project.name }}</h2>
      <button [routerLink]="['./project', project.id.toString()]" mat-raised-button>
        <fa-icon [icon]="icons.settings"></fa-icon> Configure</button>
    </div>
  </section>

</div>

