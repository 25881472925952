import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Currency, Prices, SubscriptionPlanWithPrices} from '@models/generated.model';

import {getCurrencySymbol} from '@lib/currency-utils';

@Component({
  selector: 'app-subscription-plan-prices',
  templateUrl: './subscription-plan-prices.component.html',
  styleUrls: ['./subscription-plan-prices.component.scss']
})
export class SubscriptionPlanPricesComponent implements OnChanges {

  @Input() showHosted: boolean | undefined;
  @Input() plan: SubscriptionPlanWithPrices;
  @Input() currency: Currency;

  currencySymbol: string;
  prices: Prices | undefined;

  ngOnChanges(changes: SimpleChanges): void {
    this.getPrices();
  }

  private getPrices() {
    this.currencySymbol = getCurrencySymbol(this.currency as Currency);
    this.prices = this.plan!.prices!.find(p => p.currency === this.currency)!;
  }

}
