import {Injectable} from '@angular/core';
import * as Sentry from "@sentry/browser";
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import {delay, filter, map, Observable, retry, Subject} from 'rxjs';
import {environment} from '@environment';
import {
  AgentModel,
  AgentStateMessage,
  BaseAppSocketMessage,
  LogUpdateMessage,
  SpecFile,
  SpecFileMessage,
  Subscription,
  SubscriptionUpdatedMessage,
  TestRunDetailUpdateMessage,
  TestRunJobStats,
  TestRunJobStatsUpdateMessage,
  TestRunStatusUpdateMessage,
  WebhookHistory,
  WebhookNotifiedMessage
} from '@models/generated.model';
import {TestRunDetailExt} from '@models/testrun-ext.model';
import {TestRunService} from './test-run.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '@services/api-services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  private socket$?: WebSocketSubject<any>;
  private messagesSubject$: Subject<BaseAppSocketMessage> = new Subject<BaseAppSocketMessage>();
  private connected$: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient,
              auth: AuthenticationService,
              private testRunService: TestRunService) {

    if (environment.websocket) {
      auth.getProfile$().subscribe(
        _ => {
          if (!auth.isImpersonating) {
            this.connect();
          } else {
            this.socket$?.complete();
          }
        }
      );
    }

  }

  get connection$(): Observable<boolean> {
    return this.connected$.asObservable();
  }

  connect(initalDelay = 0) {
    return this.http.post('/wsconnect', {}, {responseType: 'text'}).pipe(
      delay(initalDelay),
      retry({delay: 10000})).subscribe(token => {
      this.createSocket(token);
    });
  }

  private createSocket(token: string) {
    if (token == 'STOP') {
      // special token used in testing to avoid logspam
      return;
    }
    if (this.socket$) {
      this.socket$.complete();
    }
    try {
      this.socket$ = webSocket(`${environment.websocket}?token=${token}`);
      this.connected$.next(true);
      this.socket$.subscribe({
        next: msg => {
          this.messagesSubject$.next(msg as BaseAppSocketMessage);
        },
        error: (err => {
          // we'll need another token
          this.connect(5000);
        }),
        complete: () => {
          // we'll need to reconnect
          this.connect(5000);
        }
      })
    } catch (err) {
      // failed - fetch another token and try again
      Sentry.captureException(err);
      this.connected$.next(false);
      if (this.socket$) {
       this.socket$.complete();
       this.socket$ = undefined;
      }
      this.connect(5000);
    }
  }

  get agent$(): Observable<AgentModel> {
    return this.messages$.pipe(
      filter(msg => msg.action === 'agent'),
      map(msg => (msg as AgentStateMessage).agent)
    )
  }

  getSubscriptionUpdate$(): Observable<Subscription> {
    return this.messages$.pipe(filter(msg => msg.action === 'subscription-updated'),
      map(msg => (msg as SubscriptionUpdatedMessage).subscription));
  }

  getTestRunDetail$(testrun_id?: number): Observable<TestRunDetailExt> {
    return this.messages$.pipe(
      filter(msg => msg.action === 'testrun'),
      map(msg => msg as TestRunDetailUpdateMessage),
      filter(msg => !testrun_id || msg.testrun.id === testrun_id),
      map(msg => {
        return this.testRunService.augmentDetail((msg as TestRunDetailUpdateMessage).testrun);
      })
    )
  }

  get exceededExceededIncludeBuildCredits$(): Observable<any> {
    return this.messages$.pipe(
      filter(msg => msg.action === 'exceeded-build-credits')
    )
  }

  get jobStatsUpdated$(): Observable<TestRunJobStats> {
    return this.messages$.pipe(
      filter(msg => msg.action === 'jobstats'),
      map(msg => msg as TestRunJobStatsUpdateMessage),
      map(msg => msg.stats)
    )
  }
  getJobStatsChanged$(testrun_id: number): Observable<TestRunJobStats> {
    return this.messages$.pipe(
      filter(msg => msg.action === 'jobstats'),
      map(msg => msg as TestRunJobStatsUpdateMessage),
      filter(msg => msg.testrun_id === testrun_id),
      map(msg => msg.stats)
    )
  }

  getSpecChanged$(testrun_id: number): Observable<SpecFile> {
    return this.messages$.pipe(
      filter(msg => msg.action === 'spec-started' || msg.action === 'spec-finished'),
      map(msg => msg as SpecFileMessage),
      filter(msg => msg.testrun_id === testrun_id),
      map(msg => msg.spec)
    )
  }

  getStatus$(testrun_id?: number): Observable<TestRunStatusUpdateMessage> {
    return this.messages$.pipe(
      filter(msg => msg.action === 'status'),
      map(msg => msg as TestRunStatusUpdateMessage),
      filter(msg => !testrun_id || msg.testrun_id === testrun_id),
      map(msg => (msg as TestRunStatusUpdateMessage))
    )
  }

  getBuildLogs$(testrun_id: number): Observable<LogUpdateMessage> {
    return this.messages$.pipe(
      filter(msg => msg.action === 'buildlog'),
      map(msg => msg as LogUpdateMessage),
      filter(msg => !testrun_id || (msg.testrun_id === testrun_id))
    )
  }

  getPlanUpgradeSuccessful$(): Observable<Subscription> {
    return this.getSubscriptionUpdate$().pipe(
      filter(sub => sub.active && !sub.expires && !sub.payment_failure_date && !sub.cancelled)
    )
  }

  getWebhookEvents$(webhook_id: number): Observable<WebhookHistory> {
    return this.messages$.pipe(
      filter(msg => msg.action === 'webhook_called'),
      map(msg => msg as WebhookNotifiedMessage),
      filter(msg => msg.details.hook_id === webhook_id),
      map(msg => msg.details)
    )
  }

  get messages$(): Observable<BaseAppSocketMessage> {
    return this.messagesSubject$.asObservable();
  }
}
