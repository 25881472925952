import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {Observable, Subject, tap} from 'rxjs';
import {UserInvite, UserModel, UserUpdate} from '@models/generated.model';
import {MatSnackBar} from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class UsersService {

  invites$ = new Subject<UserInvite[]>();

  constructor(private http: HttpClient,
              private snackbar: MatSnackBar,
              private authService: AuthenticationService) {
  }

  showOrgInviteLinkSnackbar() {
    this.snackbar.open('Invite link copied to clipboard. Note that any user that follows this link will have access to your dashboard. This link is valid for 30 days', 'OK');
  }

  getInviteUrl(): Observable<string> {
    return this.http.get(`/invite-url/${this.authService.currentOrganisation!.id}`, {responseType: 'text'});
  }

  getUsers(): Observable<UserModel[]> {
    const params = new HttpParams({
      fromObject:
        {
          organisation_id: this.currentOrgId
        }
    });
    return this.http.get<UserModel[]>('/user', {params});
  }

  get currentOrgId(): number {
    return this.authService.currentOrganisation!.id;
  }

  inviteUsers(invites: UserInvite[]): Observable<any> {
    return this.http.post<any>(`/invite-users/${this.currentOrgId}`, invites).pipe(
      tap(invites => this.invites$.next(invites))
    );
  }

  getPendingInvites(): Observable<UserInvite[]> {
    return this.http.get<UserInvite[]>(`/pending-invites/${this.currentOrgId}`);
  }

  updateUserPermissions(user_id: number, perms: UserUpdate): Observable<any> {
    return this.http.post<any>(`/user/${user_id}/permissions/${this.currentOrgId}`, perms);
  }

  deletePendingInvite(email: string): Observable<any> {
    return this.http.post(`/delete-pending-invite/${this.currentOrgId}`, {email});
  }

  resendInvite(email: string): Observable<any> {
    return this.http.post(`/resend-invite/${this.currentOrgId}`, {email});
  }

  removeUserFromOrganisation(user_id: number): Observable<any> {
    return this.http.post<any>(`/user/${user_id}/remove/${this.currentOrgId}`, {});
  }
}
