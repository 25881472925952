<div class="card" *ngIf="account">
  <div class="card-header">
    <h2>Subscription: {{ account.subscription.plan.name }} <span *ngIf="org.prefer_self_host else hosted">(self-hosting)</span></h2>
    <ng-template #hosted>(hosted)</ng-template>

    <button mat-raised-button type="button" *ngIf="showManageButton"
            (click)="gotoBillingPortal()"><fa-icon [icon]="icons.settings"></fa-icon> Manage</button>

    <button mat-raised-button color="accent" type="button" *ngIf="showUpgradeButton"
             [routerLink]="['../upgrade']">{{ upgradeButtonText }}</button>

    <span class="flex-spacer"></span>

    <div class="hosted" *ngIf="showUpgradeButton || showManageButton">
      <button (click)="switchToHostedClicked()" *ngIf="org.prefer_self_host"
              type="button" mat-raised-button>Switch to hosted</button>
      <button (click)="switchToSelfHosted()" *ngIf="!org.prefer_self_host"
              type="button" mat-raised-button>Switch to self-hosted</button>

    </div>

  </div>
  <div class="card-contents">

    <div class="plan-usage" *ngIf="account && plan">

      <div class="test-results" *ngIf="plan.included_test_results">
        <app-circle-usage label="Test Results" [total]="plan.included_test_results!"
                          [value]="account.test_results_used"></app-circle-usage>
      </div>

      <div class="users" *ngIf="plan.users_limit; else unlimited">
        <app-circle-usage label="Users" [total]="plan.users_limit" [value]="account.users">
        </app-circle-usage>
      </div>
      <ng-template #unlimited>Unlimited users</ng-template>

      <ng-container *ngIf="!org.prefer_self_host">
        <div class="build-credits">

          <div class="label-value-vertical">
            <label>Build credits used this month</label>
            <span>{{ account.build_credits_used_this_month | number }}</span>
          </div>

          <div class="label-value-vertical">
            <label>Build credits remaining before top-up</label>
            <span>{{ account.build_credits_remaining_before_topup | number }}</span>
          </div>

        </div>

      </ng-container>

    </div>


  </div>
</div>


<ng-template #confirmHostedSwitch>
  <div class="dialog-contents">
    <h1 mat-dialog-title>Confirm switch to hosted?</h1>
    <div mat-dialog-content class="confirm">
      <p>You have already created an agent.</p>
      <p>If you switch to hosted then all future test runs will be performed using our agents, and you should uninstall the agent. Please confirm.</p>
    </div>
    <div mat-dialog-actions>
      <div  class="row-space-between">
        <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
        <button mat-raised-button mat-dialog-close color="warn" (click)="switchToHostedConfirmed()">Switch</button>
      </div>
    </div>
  </div>

</ng-template>
