
export function trapEvent(event: Event) {
  if (!event) { return; }
  event.preventDefault();
  event.stopPropagation();
}

export function menuItemScroll (container: any, domItem: any, scrollPadding = 4) {
  if (
    domItem.offsetHeight - container.scrollTop + domItem.offsetTop >=
    container.offsetHeight
  ) {
    container.scrollTop =
      domItem.offsetHeight +
      domItem.offsetTop -
      container.offsetHeight +
      scrollPadding;
  } else if (domItem.offsetTop <= container.scrollTop) {
    container.scrollTop = domItem.offsetTop - scrollPadding;
  }
}
