import {NgModule} from '@angular/core';
import {BranchSelectComponent} from './branch-select/branch-select.component';
import {ProjectSelectComponent} from './project-select/project-select.component';
import {SharedModule} from '../shared/shared.module';
import {ReactiveFormsModule} from '@angular/forms';
import {AuthorSelectComponent} from './author-select/author-select.component';


@NgModule({
  declarations: [
    BranchSelectComponent,
    ProjectSelectComponent,
    AuthorSelectComponent
  ],
  imports: [
    SharedModule,
    ReactiveFormsModule
  ],
  exports: [
    BranchSelectComponent,
    ProjectSelectComponent,
    AuthorSelectComponent
  ]
})
export class DropdownsModule { }
