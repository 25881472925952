<div class="card">
  <div class="card-header">
    <h1>Choose a plan:</h1>
  </div>
  <div class="card-contents">
    <div class="plans">
      <app-subscription-plans [organisation]="org"
                              [hideFreePlan]="true"
                              [showHosted]="!org.prefer_self_host"
                              [hideCancelPlan]="true"></app-subscription-plans>
    </div>

  </div>


</div>

