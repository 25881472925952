import {Currency} from '@models/generated.model';

const SymbolMap = {
  'usd': '$',
  'gbp': '£',
  'eur': '€'
}

export function getCurrencySymbol(currency: Currency): string {
  return SymbolMap[currency];
}
