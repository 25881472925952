import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {APIToken} from '@models/generated.model';
import {map, Observable} from 'rxjs';

export interface APITokenExt extends APIToken {
  created_date: Date;
}

@Injectable({
  providedIn: 'root'
})
export class APITokensService {
  constructor(private http: HttpClient) {}

  augment(token: APIToken): APITokenExt {
    return {...token, created_date: new Date(token.created)};
  }

  createAPIToken() {
    return this.http.post<APITokenExt>('/api-token', {}).pipe(map(this.augment));
  }

  getAPITokens(): Observable<APITokenExt[]> {
    return this.http.get<APIToken[]>('/api-token').pipe(map(tokens => tokens.map(this.augment)));
  }

  deleteAPIToken(pk: number): Observable<any> {
    return this.http.delete(`/api-token/${pk}`);
  }

}
