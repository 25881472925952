import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SelectedSubscriptionPlan, SubscriptionPlanWithPrices} from '@models/generated.model';
import {loadStripe} from '@stripe/stripe-js/pure';
import {Stripe} from '@stripe/stripe-js/types/stripe-js/stripe';
import {environment} from '@environment';


@Injectable({
  providedIn: 'root'
})
export class BillingService {

  _stripe: Stripe;

  constructor(private http: HttpClient) { }

  getSubscriptionPlans(): Observable<SubscriptionPlanWithPrices[]> {
    return this.http.get<SubscriptionPlanWithPrices[]>('/billing/plan');
  }

  getSubscriptionPlan(name: string, currency: string): Observable<SelectedSubscriptionPlan> {
    return this.http.get<SelectedSubscriptionPlan>(`/billing/plan/${name}/${currency}`);
  }

  async getStripe(): Promise<Stripe> {
    if (!this._stripe) {
      loadStripe.setLoadParameters({advancedFraudSignals: false});
       this._stripe = (await loadStripe(environment.stripeKey)) as Stripe;
       return this._stripe;
    } else {
      return Promise.resolve(this._stripe);
    }
  }
}
