import {environment} from '@environment';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UserProfile} from '@models/generated.model';
import {NavItem} from '@models/nav';
import {AdminService} from '@services/api-services/admin.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {IconsService} from '@services/ui-services/icon.service';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {tap} from 'rxjs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  profile: UserProfile;
  navItems: NavItem[] = [
    { path: 'users', label: 'Users'},
    { path: 'organisations', label: 'Organisations'}
  ]
  stripeTestMode = environment.stripeKey.startsWith('pk_test');
  fakeTime: Date | undefined;
  nextTime: Date;

  @ViewChild('showAdvance', { static: true }) advanceTimeDialog: TemplateRef<any>;

  constructor(private adminService: AdminService,
              private snackbar: MatSnackBar,
              public icons: IconsService,
              private errorDialogService: ErrorDialogService,
              private dialog: MatDialog) {
  }

  showAdvanceTime() {
    if (!this.fakeTime) {
      this.nextTime = new Date();
    } else {
      this.nextTime = this.fakeTime;
    }
    this.dialog.open(this.advanceTimeDialog, {width: '400px'});
  }

  advanceTime() {
    this.adminService.setFakeTime(this.nextTime).pipe(tap(() =>
      this.fakeTime = this.nextTime)).subscribe(
      this.errorDialogService.genericUpdateObserver(
      "Fake time set", "Failed to set fake time"));
  }

  clearFakeTime() {
    this.adminService.setFakeTime().subscribe(() => {
      delete this.fakeTime;
      this.snackbar.open("Fake time cleared", "OK", {duration: 2000});
    });
  }

  ngOnInit(): void {
    this.adminService.getFakeTime().subscribe(d => {
      this.fakeTime = d;
    });
  }
}
