import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {IconsService} from '../../../services/ui-services/icon.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-screenshots',
  templateUrl: './screenshots.component.html',
  styleUrls: ['./screenshots.component.scss']
})
export class ScreenshotsComponent implements OnInit, OnChanges {

  @Input() images: string[] = [];
  @ViewChild('lightbox', { static: true }) lightbox: TemplateRef<any>;

  current?: string;
  currentIndex = 0;
  dialogRef?: MatDialogRef<any>;
  keySub: Subscription;

  constructor(public dialog: MatDialog, public icons: IconsService) {
  }

  onClick(index: number) {
    this.currentIndex = index;
    this.current = this.images[index];
    this.dialogRef = this.dialog.open(this.lightbox, {
      width: '96vw',
      maxWidth: '96vw',
      height: '96vh',
      backdropClass: 'dark-backdrop'
    });
    this.keySub = this.dialogRef.keydownEvents().subscribe(evt => {
      const keyCode = evt.key;
      if (keyCode == 'ArrowRight' && this.hasNext) {
        this.next();
      }
      if (keyCode == 'ArrowLeft' && this.hasPrev) {
        this.prev();
      }
    });
  }

  closeDialog() {
    this.dialogRef!.close();
    delete this.dialogRef;
    this.keySub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.images?.length) {
      this.currentIndex = 0;
      this.current = this.images[0];
    } else {
      delete this.current;
    }
  }

  ngOnInit(): void {
    if (this.images?.length) {
      this.current = this.images[0];
    } else {
      delete this.current;
    }
  }

  imageSelected(idx: number) {
    this.currentIndex = idx;
    this.current = this.images[idx];
  }

  get hasPrev(): boolean {
    return this.currentIndex > 0;
  }

  get hasNext(): boolean {
    return this.images.length>0 && this.currentIndex < this.images.length-1;
  }

  prev() {
    this.imageSelected(this.currentIndex-1);
  }

  next() {
    this.imageSelected(this.currentIndex+1);
  }
}
