import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UiSessionSettingsService {

  _showHostsInBuildLog = false;
  _pageSize = 10;

  constructor() {
    const settings = localStorage.getItem('settings');
    if (settings) {
      const sobj = JSON.parse(settings);
      this._showHostsInBuildLog = sobj.showHostsInBuildLog || false;
      this._pageSize = sobj.pageSize || 10;
    }
  }

  save() {
    const obj = {showHostsInBuildLog: this._showHostsInBuildLog,
      pageSize: this._pageSize};
    localStorage.setItem('settings', JSON.stringify(obj));
  }

  get showHostsInBuildLog(): boolean {
    return this._showHostsInBuildLog;
  }

  set showHostsInBuildLog(v: boolean) {
    this._showHostsInBuildLog = v;
    this.save();
  }

  get pageSize(): number {
    return this._pageSize;
  }

  set pageSize(sz: number) {
    this._pageSize = sz;
    this.save();
  }
}
