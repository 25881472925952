<div class="option">

  <div class="contents">
    <h2>{{ plan.name }}</h2>

    <p class="legend">{{ plan.description }}</p>

    <div class="prices">
      <app-subscription-plan-prices [currency]="currency" [plan]="plan"
                                    [showHosted]="showHosted"></app-subscription-plan-prices>
    </div>
  </div>

  <ng-container *ngIf="!readOnly">
    <button mat-raised-button color="primary" *ngIf="currentPlan !== plan.name; else currentPlanButton"
            type="button"
            (click)="planSelected()">{{ buttonText }}
    </button>
  </ng-container>

  <ng-template #currentPlanButton>
    <button class="selected-plan" mat-raised-button color="accent" (click)="cancelPlan()"

            type="button">Current Plan
    </button>
  </ng-template>
</div>
