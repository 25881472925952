import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '@environment';
import {AuthenticationService} from '@services/api-services/authentication.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (request.url.startsWith('https')) {
      return next.handle(request);
    }

    let headers: any = undefined;
    let params = request.params;
    if (this.authService.isAuthenticated) {
      headers = {Authorization: `Bearer ${this.authService.getToken()}`};
    }

    if (this.authService.impersonatingUserId) {
      if (request.method === 'GET') {
        headers["Impersonate-User-Id"] = this.authService.impersonatingUserId.toString();
      } else {
        const msg = `Using impersonation for ${request.method} ${request.url}`;
        console.error(msg);
        throw new Error(msg);
      }
    }

    request = request.clone({
      url: `${environment.server}${request.url}`,
      setHeaders: headers,
      params
    })
    return next.handle(request);
  }
}
