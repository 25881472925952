import {Component, Input} from '@angular/core';
import {PlatformEnum} from '@models/generated.model';
import {IconsService} from '@services/ui-services/icon.service';

@Component({
  selector: 'app-platform-name',
  templateUrl: './platform-name.component.html',
  styleUrls: ['./platform-name.component.scss']
})
export class PlatformNameComponent {
  @Input() platform: PlatformEnum;

  constructor(public icons: IconsService) {
  }
}
