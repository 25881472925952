import {Injectable} from '@angular/core';
import {DetectedFrameworks, NewProject, PlatformEnum, Project, Repository} from '@models/generated.model';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {BehaviorSubject, filter, map, Observable, switchMap, tap} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TimezoneService} from '../ui-services/timezone.service';
import {AuthenticationService} from '@services/api-services/authentication.service';


export interface ProjectFilter {
  platform?: PlatformEnum;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  _projects = new BehaviorSubject<Project[]>([]);

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private timeZoneService: TimezoneService,
              private fb: FormBuilder) {

    authService.currentOrganisation$.pipe(switchMap( () => this.list())).subscribe(
      projects => {
        this._projects.next(projects);
      }
    );

  }

  projectForm(project?: Project, repos?: Repository) {

    const nameValidatorFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const v = control.value;
      return (!!v && this._projects.value.find(p => p.id !== project?.id && p.name === v)) ? {unique: true}: null;
    };

    const buildValidatorFn: ValidatorFn = (ctrl: AbstractControl): ValidationErrors | null => {
      const form = ctrl as FormGroup;
      const buildCmd = form.get('build_cmd')?.value;
      const serverCmd = form.get('server_cmd')?.value;
      const serverPort = form.get('server_port')?.value;
      return (!buildCmd?.length && !(serverCmd?.length && serverPort)) ? {invalidCommands: true} : null;
    }
    let port = project?.server_port || 0;
    if (!port && project?.app_framework === 'angular' && project.test_framework === 'playwright') {
      port = 4200;
    }

    const form = this.fb.group({
      agent_id: [project?.agent_id],
      name: [project?.name || repos?.name, [Validators.required, nameValidatorFn]],
      app_framework: [project?.app_framework, Validators.required],
      test_framework: [project?.test_framework, Validators.required],
      build_cmd: [project?.build_cmd],
      server_cmd: [project?.server_cmd],
      server_port: [port.toString(), Validators.required],
      build_cpu: [project?.build_cpu?.toString() || '2', Validators.required],
      build_memory: [project?.build_memory?.toString() || '6', Validators.required],
      build_ephemeral_storage: [project?.build_ephemeral_storage?.toString() || '4', Validators.required],
      build_deadline: [project?.build_deadline?.toString() || 1500, Validators.required],
      build_storage: [project?.build_storage?.toString() || '10', Validators.required],
      browsers: [project?.browsers || ['electron'], Validators.required],
      node_major_version: [(project?.node_major_version || 18).toString(), Validators.required],
      runner_cpu: [project?.runner_cpu?.toString() || '2', Validators.required],
      runner_memory: [project?.runner_memory?.toString() || '4', Validators.required],
      runner_ephemeral_storage: [project?.runner_ephemeral_storage?.toString() || '1', Validators.required],
      runner_deadline: [project?.runner_deadline?.toString() || 3600, Validators.required],
      spec_deadline: [project?.spec_deadline?.toString() || 0],
      spec_filter: [project?.spec_filter],
      timezone: [project?.timezone || this.timeZoneService.getDefaultTimezone(), Validators.required],
      runner_retries: [(project!==undefined)?project.runner_retries: 2, Validators.required],
      max_failures: [project?.max_failures],
      parallelism: [project?.parallelism || 4,
        [Validators.required, Validators.min(1), Validators.max(32)]]
    }, {
      validators: [buildValidatorFn]
    });
    return form;

  }

  get projects(): Project[] {
    return this._projects.value;
  }

  private notify() {
    this._projects.next(this.projects);
  }

  get projects$() {
    return this._projects.asObservable();
  }

  getProject$(id: number): Observable<Project> {
      return this.projects$.pipe(
      map(projects  => projects.find(n => n.id === id)),
      filter(n => !!n),
      map(n => n as Project)
    )
  }

  detectFrameworks(repos: Repository): Observable<DetectedFrameworks> {
    return this.http.post<DetectedFrameworks>('/detect-frameworks', repos);
  }

  createProject(project: NewProject): Observable<Project> {
    return this.http.post<Project>(`/project`, project).pipe(tap(project => {
      this.projects.push(project);
      this.notify();
    }));
  }

  updateProject(project: Project): Observable<Project> {
    return this.http.put<Project>(`/project/${project.id}`, project).pipe( tap(project => {
      const idx = this.projects.indexOf(project);
      this.projects[idx] = project;
      this.notify();
    }));
  }

  getProject(id: number): Observable<Project> {
    return this.http.get<Project>(`/project/${id}`);
  }

  deleteProject(project: Project): Observable<any> {
    return this.http.delete(`/project/${project.id}`).pipe(tap(() => {
      const idx = this.projects.indexOf(project);
      this.projects.splice(idx, 1);
      this.notify();
    }));
  }

  list(filter?: ProjectFilter): Observable<Project[]> {
    let params = this.authService.currentOrganisationHttpParam;
    if (filter?.platform) {
      params = params.set('platform', filter.platform);
    }
    return this.http.get<Project[]>('/project', {params});
  }

  listBranches(projectId: number, search?: string): Observable<string[]> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    return this.http.get<string[]>(`/project/${projectId}/branch`, {params});
  }

}
