<div class="toolbar" *ngIf="allowSearch">
  <mat-form-field appearance="outline" class="search full-width">
    <mat-label>Search</mat-label>
    <input type="search" matInput [formControl]="searchControl">
  </mat-form-field>
</div>

<div class="list-wrapper">
  <ng-container *ngIf="allowSelection else plainList">
    <mat-selection-list [multiple]="false" [(ngModel)]="selectedUsers"
        (selectionChange)="userSelected($event)">
      <mat-list-option *ngFor="let item of userList.items" [value]="item">
        <span matListItemTitle>{{ item.name }}</span>
        <span matListItemLine>{{ item.email }}</span>
      </mat-list-option>
    </mat-selection-list>
  </ng-container>

  <ng-template #plainList>
      <mat-list>
        <mat-list-item *ngFor="let item of userList.items">
          <span matListItemTitle>{{ item.name }}</span>
          <span matListItemLine>{{ item.email }}</span>
        </mat-list-item>
      </mat-list>
  </ng-template>

</div>
<mat-paginator [length]="userList.total"
            [pageIndex]="userList.page - 1"
            [pageSize]="userList.pagesize"
            [pageSizeOptions]="pageSizeOptions"
             (page)="pageChanged($event)"
            aria-label="Select page">
</mat-paginator>
