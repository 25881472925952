import {Component, EventEmitter, forwardRef, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AuthorModel} from '@models/generated.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {distinctUntilChanged} from 'rxjs';
import {AuthorService} from '@services/api-services/author.service';

@UntilDestroy()
@Component({
  selector: 'app-author-select',
  templateUrl: './author-select.component.html',
  styleUrls: ['./author-select.component.scss'],
  providers: [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AuthorSelectComponent
    ),
    multi: true
  }]
})
export class AuthorSelectComponent implements OnInit, ControlValueAccessor {

  // call if value was changed inside our component
  private _onChange = (_: any) => {};
  // call if input was "touched" .. !
  private _onTouched = () => {};

  selectedEmail: string;
  ctrl = new FormControl();
  authors: Array<AuthorModel>;

  @Output()
  authorSelected = new EventEmitter<AuthorModel | undefined>();

  constructor(private authorService: AuthorService) {
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(email: string): void {
    this.selectedEmail = email;
    if (!email) {
      this.ctrl.reset(null, {emitEvent: false});
    } else {
      if (this.authors !== undefined) {
        this.setAuthor();
      }
    }
  }

  ngOnInit(): void {
    this.authorService.authors$.subscribe(p => {
      this.authors = p;
      if (this.selectedEmail) {
        this.setAuthor();
      }
    });
    this.ctrl.valueChanges.pipe(
      untilDestroyed(this),
      distinctUntilChanged())
      .subscribe(p => {
        if (p) {
          this._onChange(p.email);
          this._onTouched();
          this.authorSelected.next(p);
        } else {
          this._onChange(null);
          this.authorSelected.next(undefined);
        }
    });
  }

  private setAuthor() {
    const p = this.authors.find(p => p.email === this.selectedEmail);
    if (p) {
      this.ctrl.setValue(p, {emitEvent: false});
      this.authorSelected.next(p);
    }
  }
}
