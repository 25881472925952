import {Component, forwardRef, HostListener, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {IconsService} from '@services/ui-services/icon.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, filter, switchMap, tap} from 'rxjs';
import {trapEvent} from 'src/lib/event-utils';
import {ProjectService} from '@services/api-services/project.service';


@UntilDestroy()
@Component({
  selector: 'app-branch-select',
  templateUrl: './branch-select.component.html',
  styleUrls: ['./branch-select.component.scss'],
  providers: [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => BranchSelectComponent),
    multi: true
  }]
})
export class BranchSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() projectId: number;

  ctrl = new FormControl();
  branches: Array<string> = [];
  selected?: string;

  // call if value was changed inside our component
  private _onChange = (_: any) => {};
  // call if input was "touched" .. !
  private _onTouched = () => {};

  constructor(public icons: IconsService,
              private projectService: ProjectService) {

  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled || !this.projectId) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }
  }

  writeValue(value: string): void {
    if (!value) {
      delete this.selected;
      this.ctrl.reset(null, {emitEvent: false});
    } else {
      this.selected = value;
      this.ctrl.setValue(value, {emitEvent: false, emitModelToViewChange: false});
    }
  }

  branchSelected(event: MatAutocompleteSelectedEvent) {
    this.selected = event.option.value;
    this._onChange(event.option.value);
  }

  @HostListener('focusout', ['$event'])
  @HostListener('blur', ['$event'])
  onFocusOut() {
    if (this.ctrl.value && (!this.selected || this.selected !== this.ctrl.value)) {
      this.ctrl.reset();
      this._onChange(null);
    }
  }

  ngOnInit(): void {

    if (!this.projectId) {
      this.ctrl.disable();
    }

    this.ctrl.valueChanges.pipe(
      untilDestroyed(this),
      filter( (v) => !!this.projectId && this.selected !== v),
      tap( () => {
        if (!this.ctrl.enabled) {
          this.ctrl.enable();
        }
      }),
      debounceTime(200),
      tap(() => {
        this.branches = [];
      }),
      filter( (v) => !!this.projectId),
      switchMap(s => this.projectService.listBranches(this.projectId, s))
    ).subscribe(branches => {
      this.branches = branches;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ctrl.setValue("");
    this.setDisabledState(!this.projectId);
  }

  clearBranch($event: MouseEvent) {
    this.ctrl.reset();
    delete this.selected;
    this._onChange(null);
    trapEvent($event);
  }
}
