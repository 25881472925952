<mat-form-field appearance="fill" class="autocomplete-field branch-select">
  <mat-label>Branch</mat-label>
  <input type="text" placeholder="Search" matInput
         [formControl]="ctrl" [matAutocomplete]="branchAuto">

  <i class="material-icons caret" *ngIf="!selected">arrow_drop_down</i>
  <mat-autocomplete #branchAuto="matAutocomplete"
                    (optionSelected)="branchSelected($event)">
    <mat-option *ngFor="let branch of branches" [value]="branch">{{ branch }}</mat-option>
  </mat-autocomplete>
  <div matRipple class="clear" (click)="clearBranch($event)" *ngIf="selected" >
    <fa-icon [icon]="icons.close" size="lg" ></fa-icon>
  </div>
</mat-form-field>
