import {Injectable} from '@angular/core';
import {
  AppLogMessage,
  TestRunDetail,
  TestRunStatusFilter,
  TestRunSummaries,
  TestRunSummary
} from '@models/generated.model';
import {map, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {serialiseParams} from '@lib/http-utils';
import {Dated, TestRunDetailExt, TestRunSummaryExt} from '@models/testrun-ext.model';
import {Router} from '@angular/router';
import {AuthenticationService} from '@services/api-services/authentication.service';

export interface TestRunFilter {
  page: number;
  pagesize: number;
  author_email?: string;
  project_id?: number;
  project_name?: string;
  branch?: string;
  status?: TestRunStatusFilter
}

@Injectable({
  providedIn: 'root'
})
export class TestRunService {

  constructor(private http: HttpClient,
              private authService: AuthenticationService,
              private router: Router) { }

  augmentDated(dated: Dated): Dated {
    if (dated.started) {
      dated.started_date = new Date(dated.started);
    }
    if (dated.finished) {
      dated.finished_date = new Date(dated.finished);
    }
    return dated;
  }

  augmentDetail(testrun: TestRunDetail): TestRunDetailExt {
    let m: TestRunDetailExt = {...testrun};
    return this.augmentDated(m) as TestRunDetailExt;
  }

  augmentSummary(testrun: TestRunSummary): TestRunSummaryExt {
    let m: TestRunSummaryExt = {...testrun};
    return this.augmentDated(m) as TestRunSummaryExt;
  }

  getBuildLogs(testrunId: number): Observable<AppLogMessage[]> {
    return this.http.get<AppLogMessage[]>(`/testrun/${testrunId}/logs`);
  }

  cancelTestRun(testrun: TestRunDetail): Observable<any> {
    return this.http.post(`/testrun/${testrun.id}/cancel`, {});
  }

  rerunTestRun(testrunId: number): Observable<TestRunDetail> {
    return this.http.post<TestRunDetail>(`/testrun/${testrunId}/rerun`, {});
  }

  getTestRun(id: number): Observable<TestRunDetailExt> {
    return this.http.get<TestRunDetailExt>(`/testrun/${id}`).pipe(map(tr => this.augmentDetail(tr)));
  }

  getTestRunByProjectName(project: string, local_id: number): Observable<TestRunDetailExt> {
    return this.http.get<TestRunDetailExt>(`/testrun/byproject/${project}/${local_id}`,
      {params: this.authService.currentOrganisationHttpParam})
      .pipe(map(tr => this.augmentDetail(tr)));
  }

  getTestRunSummaries(filter: TestRunFilter): Observable<TestRunSummaries> {
    let params = serialiseParams(filter);
    params = params.set('organisation_id', this.authService.currentOrganisationId.toString());
    return this.http.get<TestRunSummaries>(`/testrun/summary`, {params});
  }

  createTestRun(project_id: number, branch: string): Observable<TestRunSummary> {
    return this.http.post<TestRunSummary>(`/testrun/${project_id}/${branch}/start`, {});
  }
}
