import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

export function setDisabled(control: AbstractControl, state: boolean) {
  if (state) {
    control.disable();
  } else {
    control.enable();
  }
}

//
// Ensure value is not already in the supplied set
//
export function forbiddenValueValidator(values: Set<string>): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (values.has(control.value)) {
      return {existing: {value: control.value}};
    }
    return null;
  };
}

export function setRequiredValidator(flag: boolean, control: AbstractControl, noUpdate?: boolean) {
  if (flag) {
    if (!control.hasValidator(Validators.required)) {
      control.addValidators(Validators.required);
      if (!noUpdate) {
        control.updateValueAndValidity();
      }
    }
  } else {
    if (control.hasValidator(Validators.required)) {
      control.removeValidators(Validators.required);
      if (!noUpdate) {
        control.updateValueAndValidity();
      }
    }
  }
}

export function setRequiredValidators(flag: boolean, form: FormGroup, fields: string[]) {
  fields.forEach( fld => {
    setRequiredValidator(flag, form.get(fld) as AbstractControl);
  });
}
