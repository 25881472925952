<form [formGroup]="form" *ngIf="user">

  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Name</mat-label>
    <input matInput formControlName="name">
    <mat-error *ngIf="form.controls['name'].hasError('required')">This field is required</mat-error>
    <mat-error *ngIf="form.controls['name'].hasError('unique')">Please choose a unique name</mat-error>
  </mat-form-field>

  <mat-accordion multi>

    <mat-expansion-panel [expanded]="expanded || step === 0" (opened)="setStep(0)">

      <mat-expansion-panel-header>
        <mat-panel-title>
          Test framework and browsers
        </mat-panel-title>
        <mat-panel-description>
          Select the test framework and browsers
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="outline">
            <mat-label>Test framework</mat-label>
            <mat-select formControlName="test_framework">
              <mat-option value="cypress">Cypress</mat-option>
              <mat-option value="playwright">Playwright</mat-option>
            </mat-select>

          </mat-form-field>
        </div>
        <div class="info-box">Test framework used (will we attempt to autodetect this)
        </div>
      </div>

      <div class="field-with-info" *ngIf="isCypress">
        <div class="field-wrapper">
          <mat-form-field appearance="fill">
            <mat-label>Browsers</mat-label>
            <mat-select formControlName="browsers" multiple="true">
              <mat-option *ngFor="let browser of browsers" [value]="browser.toLowerCase()">{{ browser }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="info-box">
          Select the <a href="https://docs.cypress.io/guides/guides/launching-browsers" target="_blank">browsers</a> to target. Tests will be run against all selected browsers.
        </div>
      </div>

      <mat-action-row *ngIf="newProject">
        <button mat-button color="primary" (click)="nextStep()">Next</button>
      </mat-action-row>

    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded || step === 1" (opened)="setStep(1)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Builder
        </mat-panel-title>
        <mat-panel-description>
          Building your test app distribution
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="outline">
            <mat-label>Node major version</mat-label>
            <mat-select formControlName="node_major_version">
              <mat-option value="16">16</mat-option>
              <mat-option value="18">18</mat-option>
              <mat-option value="20">20</mat-option>
            </mat-select>

          </mat-form-field>
        </div>
        <div class="info-box">Major node version (we will pick the latest stable release)
        </div>
      </div>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill" class="select">
            <mat-label>App (JS) Framework</mat-label>
            <mat-select formControlName="app_framework">
              <mat-option *ngFor="let name of frameworks" [value]="name">{{ name | titlecase }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="info-box">App framework: we use this to help you choose the build command
        </div>
      </div>

      <div class="field-with-info">
        <div class="field-wrapper full-width">
        <mat-form-field appearance="fill">
          <mat-label>Build command</mat-label>
          <input matInput formControlName="build_cmd">
        </mat-form-field>
        </div>
        <div class="info-box">
          The command used to build your distribution and output to the <em>dist</em> directory: we will serve the compiled app as a standard Single Page App automatically.
        </div>
      </div>

      <div class="field-with-info" *ngIf="!form.controls['build_cmd'].value?.length">
        <div class="field-wrapper full-width">
          <mat-form-field appearance="fill">
            <mat-label>Server command</mat-label>
            <input matInput formControlName="server_cmd">
          </mat-form-field>

        </div>
        <div class="info-box">A command to serve your application
        </div>
      </div>

      <div class="field-with-info">
        <div class="field-wrapper full-width">
        <mat-form-field appearance="fill">
          <mat-label>Server port</mat-label>
          <input matInput formControlName="server_port">
        </mat-form-field>
        </div>
        <div class="info-box">The port used by your server
        </div>
      </div>

      <mat-error class="form-error" *ngIf="form.hasError('invalidCommands')">Please specify either a build command or a server command with port</mat-error>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill" class="storage">
            <mat-label>Build deadline (seconds)</mat-label>
            <input formControlName="build_deadline" matInput type="number" [min]="60" [max]="1800">
          </mat-form-field>
        </div>
        <div class="info-box">
          Build job deadline in seconds.
        </div>
      </div>

      <mat-action-row *ngIf="newProject">
        <button mat-button color="accent" (click)="prevStep()">Previous</button>
        <button mat-button color="primary" (click)="nextStep()" [disabled]="!builderValid">Next</button>
      </mat-action-row>

    </mat-expansion-panel>

    <mat-expansion-panel  [expanded]="expanded || step === 2" (opened)="setStep(2)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Runner
        </mat-panel-title>
        <mat-panel-description>
          Running the tests
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="field-with-info">
        <div class="field-wrapper parallelism">
          <label>Parallel runners</label>
          <div class="slider-wrapper">
            <mat-slider [min]="1" [max]="maxRunners" [step]="1" [discrete]="true">
              <input matSliderThumb formControlName="parallelism">
            </mat-slider>
            <span class="value">{{ form.controls['parallelism'].value | number }}</span>
          </div>
        </div>
        <div class="info-box">
          The maximum number of test runner pods to be run in parallel: in Kubernetes terms this is the
          <a
            href="https://kubernetes.io/docs/concepts/workloads/controllers/job/#parallel-jobs"  target="_blank"><em>.spec.parallelism</em></a>
          number. We will limit this to the number of spec files
        </div>
      </div>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill">
            <mat-label>Timezone</mat-label>
            <input type="text" placeholder="Select timezone" matInput
                  formControlName="timezone"
                [matAutocomplete]="tzauto">
            <mat-autocomplete #tzauto="matAutocomplete" >
              <mat-option *ngFor="let tz of filteredTimezones$ | async" [value]="tz">{{ tz }}</mat-option>

            </mat-autocomplete>

          </mat-form-field>
        </div>
        <div class="info-box">
          Sets the TZ environment variable (Daylight Savings Time can be a common source of error).
        </div>
      </div>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill"  class="number">
            <mat-label>Retries</mat-label>
            <input matInput type="number" formControlName="runner_retries">
          </mat-form-field>
        </div>
        <div class="info-box">
          <a *ngIf="isCypress" href="https://docs.cypress.io/guides/guides/test-retries" target="_blank">Default retries</a>.
          <a *ngIf="!isCypress" href="https://playwright.dev/docs/test-retries#retries" target="_blank">Default retries</a>.
          Note that any retries value specified in the config file or at the test level will take precedence.
        </div>
      </div>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill"  class="number">
            <mat-label>Failure limit</mat-label>
            <input matInput type="number" formControlName="max_failures">
          </mat-form-field>
        </div>
        <div class="info-box">Mark a test run as failed if the number of failed tests exceed this value. The default behaviour is to run all the tests regardless.
        </div>
      </div>

<!--      <div class="field-with-info">-->
<!--        <div class="field-wrapper flex-vertical">-->
<!--          <mat-label>Cypress debug logging enabled</mat-label>-->
<!--          <mat-slide-toggle formControlName="cypress_debug_enabled"></mat-slide-toggle>-->
<!--        </div>-->

<!--        <div class="info-box">-->
<!--          Enable Cypress debug logging-->
<!--        </div>-->
<!--      </div>-->

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill"  class="number">
            <mat-label>Runner deadline</mat-label>
            <input matInput type="number" formControlName="runner_deadline">
          </mat-form-field>
        </div>
        <div class="info-box">
          <p>Maximum time allowed to complete all spec files</p>
        </div>
      </div>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill"  class="number">
            <mat-label>Spec file deadline</mat-label>
            <input matInput type="number" formControlName="spec_deadline">
          </mat-form-field>
        </div>
        <div class="info-box">
          <p>Maximum time allowed to complete an individual spec file. Set to 0 to allow unlimited time to complete (up to the overall test run deadline).</p>
        </div>
      </div>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill">
            <mat-label>Spec filter (glob)</mat-label>
            <input matInput formControlName="spec_filter">
          </mat-form-field>
        </div>
        <div class="info-box">
          <p>We will default to selecting all test specs defined in your config file. Use this to override</p>
        </div>
      </div>

      <mat-action-row *ngIf="newProject">
        <button mat-button color="accent" (click)="prevStep()">Previous</button>
        <button mat-button color="primary" (click)="nextStep()" [disabled]="!runnerValid">Next</button>
      </mat-action-row>

    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded || step === 3" (opened)="setStep(3)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Resources
        </mat-panel-title>
        <mat-panel-description>
          CPU / memory / storage
        </mat-panel-description>
      </mat-expansion-panel-header>

      <p *ngIf="!project">The resource limits that are assigned to the build and runner jobs.</p>
      <p>Unless you have an unusually complex app you can probably just go with these default values.</p>

      <br>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill" class="number">
            <mat-label>Build CPU</mat-label>
            <mat-select formControlName="build_cpu">
              <mat-option *ngFor="let opt of cpuOptions" [value]="opt">{{ opt }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="info-box">
          CPU resource to assign to the builder Job. Most frameworks will make use of extra cores but you'll get
          diminishing returns after 2.
        </div>
      </div>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill" class="number">
            <mat-label>Build memory</mat-label>
            <mat-select formControlName="build_memory">
              <mat-option *ngFor="let opt of memoryOptions" [value]="opt">{{ opt }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="info-box">
          Memory resource to assign to the builder Job.
        </div>
      </div>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill" class="storage">
            <mat-label>Build storage</mat-label>
            <mat-select formControlName="build_storage">
              <mat-option *ngFor="let opt of storageOptions" [value]="opt">{{ opt }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="info-box">Maximum size of the build volume, including the cloned repository, node_modules / yarn cache and compiled distribution.
        </div>
      </div>


      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill"  class="number">
            <mat-label>Runner CPU</mat-label>
            <mat-select formControlName="runner_cpu">
              <mat-option *ngFor="let opt of cpuOptions" [value]="opt">{{ opt }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="info-box">
          CPU resource to assign to the runner Job
        </div>
      </div>

      <div class="field-with-info">
        <div class="field-wrapper">
          <mat-form-field appearance="fill" class="number">

            <mat-label>Runner memory</mat-label>
            <mat-select formControlName="runner_memory">
              <mat-option *ngFor="let opt of memoryOptions" [value]="opt">{{ opt }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="info-box">
          Memory resource to assign to the runner Job
        </div>
      </div>

    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="expanded || step === 4" *ngIf="agents.length > 1"
                         [disabled]="singleAgent"
                         (opened)="setStep(4)">
      <mat-expansion-panel-header>
        <mat-panel-title>Agent
        </mat-panel-title>
        <mat-panel-description>
          <ng-container *ngIf="singleAgent">Single cluster</ng-container>
          <ng-container *ngIf="!singleAgent">Target cluster</ng-container>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="field-with-info">
        <mat-form-field appearance="fill">
            <mat-label>Agent</mat-label>
            <mat-select formControlName="agent_id">
              <mat-option *ngFor="let agent of agents" [value]="agent.id">{{ agent.name }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="info-box">
            You will need an agent installed in each cluster that you want to run tests in. A project is associated
            with a single agent.
          </div>
      </div>
    </mat-expansion-panel>

  </mat-accordion>

  <div class="end-button-row">
    <button mat-raised-button color="primary" (click)="save()"
            [disabled]="!form.valid || (!newProject && form.pristine) || saving || !frameworkChecked"
            type="button">
      <fa-icon [icon]="icons.spinner" animation="spin" *ngIf="saving"></fa-icon>
      <span>{{(newProject)?'Create project':'Save updates' }}</span>
    </button>
  </div>

</form>
