<table class="simple">
  <colgroup>
    <col class="project"/>
    <col class="triggers"/>
    <col class="name_or_channel"/>
    <col class="branch"/>
    <col class="actions"/>
  </colgroup>

  <thead>
  <tr>
    <th>Project</th>
    <th>Triggers</th>
    <th><ng-container *ngIf="isNotificationTable else webhookTableHeader">Channel</ng-container></th>
    <th>Branch</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of items">
    <td><span *ngIf="item.project_id else all">{{ item.project_name }}</span></td>
    <td>{{ getTriggerSummary(item) }}</td>
    <td>
      <ng-container *ngIf="isNotificationTable">
        <fa-icon [icon]="icons.platform(item.platform)"></fa-icon>
        {{ item.channel_name }}
      </ng-container>
      <ng-container *ngIf="!isNotificationTable">
        {{ item.name }}
      </ng-container>
    </td>
    <td><ng-container *ngIf="item.branch_regex else all">{{ item.branch_regex }}</ng-container></td>
    <td>
      <span class="row-centered">
        <button *ngIf="type === 'webhook'" mat-icon-button matTooltip="Show history" (click)="historyClicked(item)">
            <fa-icon [icon]="historyIcon"></fa-icon>
        </button>
        <button mat-icon-button matTooltip="Edit"
                (click)="select(item)"><mat-icon>edit</mat-icon></button>

      </span>
    </td>
  </tr>
  </tbody>

</table>

<ng-template #all>All</ng-template>

<ng-template #webhookTableHeader>Name</ng-template>
