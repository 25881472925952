<h2 mat-dialog-title>{{ data.title }}</h2>
<div mat-dialog-content>
  <div class="error-report">
    <div class="icon">
      <fa-icon [icon]="icons.error" size="4x"></fa-icon>
    </div>
    <div class="text">
      <p *ngIf="data.message">{{ data.message }}</p>
      <p *ngIf="!data.message">We've been notified of the error, but feel free to contact us at <a href="mailto:support@cykubed.com">support&#64;cykubed.com</a></p>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close cdkFocusInitial>OK</button>
</div>
