<div *ngIf="plans" class="main-container" [class.mobile]="mobile">

  <div class="heading no-subscript">

    <mat-form-field appearance="fill">
      <mat-select [formControl]="currencyCtrl">
        <mat-option value="usd">$ USD</mat-option>
        <mat-option value="gbp">£ GBP</mat-option>
        <mat-option value="eur">€ EUR</mat-option>
      </mat-select>
    </mat-form-field>

  </div>

  <div class="options">

    <app-sub-plan-option *ngFor="let plan of plans" [plan]="plan" [currency]="currencyCtrl.value"
                         class="option"
                         [currentPlan]="currentPlan"
                         [readOnly]="readOnly"
                         [showHosted]="showHosted"
                         (selected)="planSelected($event)"></app-sub-plan-option>

  </div>

  <div class="cancel-box" *ngIf="currentPlan !== 'Free' && !hideCancelPlan">
    <button type="button" mat-button color="warn" (click)="showConfirmCancel()">Cancel subscription</button>
  </div>
</div>


<ng-template #deleteConfirm>

  <h1 mat-dialog-title>Confirm plan cancellation?</h1>
  <div mat-dialog-content>
    <div class="cancel-message">
      Warning: this will immediately switch you back to the free plan limits. You will only be charged for time used
      this billing month.

    </div>
  </div>
  <div mat-dialog-actions>
    <div class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>No</button>
      <button mat-raised-button mat-dialog-close color="warn" (click)="cancelPlan()">Confirm cancellation</button>

    </div>
  </div>
</ng-template>

