import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {faCircle as circleOutline} from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-dot-pager',
  templateUrl: './dot-pager.component.html',
  styleUrls: ['./dot-pager.component.scss']
})
export class DotPagerComponent implements OnInit, OnChanges {

  @Input() total: number = 0;

  @Input() current: number = 0;
  @Output() clicked = new EventEmitter<number>();

  items: number[];

  protected readonly selectedCircle = faCircle;
  protected readonly nonSelectedCircle = circleOutline;

  select(idx: number) {
    this.current = idx;
    this.clicked.next(idx);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['total']) {
      this.setItems();
    }
  }

  ngOnInit(): void {
    this.setItems();
  }

  private setItems() {
    if (this.total === 0) {
      this.items = [];
    } else {
      this.items = Array(this.total).fill(1);
    }
  }


}
