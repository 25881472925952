import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, filter, iif, map, Observable, of, tap} from 'rxjs';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {
  AdminUser,
  OAuthPostInstall,
  UserOrganisationSummary,
  UserProfile,
  UserUISettingsModel
} from '@models/generated.model';
import {HttpClient, HttpParams} from '@angular/common/http';

export const ORG_SIGNUP_TOKEN = 'orgtoken';
const CYKUBE_AUTH_TOKEN_KEY='cykubemaintoken';
export const OAUTH_STATE_KEY='oauthstate';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private token?: string;
  private profile$ = new BehaviorSubject<UserProfile | undefined>(undefined);
  private _impersonatingUser?: AdminUser;
  private oldProfile?: UserProfile;
  private _freeTierExceeded = false;

  currentOrganisation$: BehaviorSubject<UserOrganisationSummary>;

  constructor(private router: Router,
              private http: HttpClient) {

    const v = localStorage.getItem(CYKUBE_AUTH_TOKEN_KEY);
    if (v) {
      this.token = v.toString();
    }
  }

  setFreeTierExceeded(v: boolean) {
    this._freeTierExceeded = v;
  }

  get freeTierExceeded(): boolean {
    return this._freeTierExceeded;
  }

  get newRunsDiabled(): boolean {
    return this.freeTierExceeded || this.isImpersonating;
  }

  async setImpersonating(user: AdminUser) {
    if (this.profile.is_staff) {
      this._impersonatingUser = user;
      this.oldProfile = this.profile;
      this.http.get<UserProfile>('/profile').subscribe(async p => {
        this.profile$.next(p);
        this.currentOrganisation$.next(this.getCurrentOrgFromProfile(p));
        await this.router.navigateByUrl('/');
      })
    }
  }

  async clearImpersonation() {
    delete this._impersonatingUser;
    this.profile$.next(this.oldProfile);
    this.currentOrganisation$.next(this.getCurrentOrgFromProfile(this.oldProfile!));
    await this.router.navigate(['/settings/admin/users']);
  }

  get impersonatingUserId(): number | undefined {
    return this._impersonatingUser?.id;
  }

  get impersonatingUser(): AdminUser | undefined {
    return this._impersonatingUser;
  }

  get isImpersonating(): boolean {
    return !!this._impersonatingUser;
  }


  checkAuthenticated(route: ActivatedRouteSnapshot): boolean  {
    if (!this.isAuthenticated) {
      const token = route.queryParams['token'];
      if (token) {
        this.setToken(token);
      } else {
        return false;
      }
    }
    return true;
  }

  ensureProfile(): Observable<UserProfile | undefined> {
    // ensure we have a profile
    return iif(() => !this.profile,
      this.fetchProfile(),
      of(this.profile));
  }

  acceptInvite(token: string): Observable<UserProfile>{
    return this.http.post<UserProfile>(`/accept-invite/${token}`, {})
      .pipe( tap( profile => {
        this.profile$.next(profile);
        this.setCurrentOrganisation(profile.organisations[profile.organisations.length-1]);
    }));
  }

  setOrgSignupToken(token: string) {
    localStorage.setItem(ORG_SIGNUP_TOKEN, token);
  }

  fetchProfile(): Observable<UserProfile | undefined> {
    return this.http.get<UserProfile>('/profile').pipe(
      catchError( resp => {
        if (resp.status === 403) {
          this.cleanToken();
        }
        return of()
      }),
      tap(profile => {
        this.setProfile(profile);
     })
    )
  }

  logOut(switchLocation = true) {
    localStorage.removeItem(CYKUBE_AUTH_TOKEN_KEY);
    delete this.token;
    if (switchLocation) {
      window.location.href = '/'
    }
  }

  public getProfile$(): Observable<UserProfile> {
    return this.profile$.pipe(filter(x => !!x),
                              map(x => x as UserProfile));
  }

  private getCurrentOrgFromProfile(profile: UserProfile): UserOrganisationSummary {
    let org = profile.organisations.find(
      o => o.id === profile.uisettings.current_org_id)!;
    if (!org) {
      org = profile.organisations[0];
    }
    return org;
  }

  public setProfile(profile: UserProfile) {
    let org;
    let saveSettings = false;
    if (profile.organisations?.length) {
      if (!profile.uisettings.current_org_id) {
        profile.uisettings.current_org_id = profile.organisations[0].id;
        saveSettings = true;
      }
      org = this.getCurrentOrgFromProfile(profile);
      if (!profile.uisettings.current_org_id) {
        profile.uisettings.current_org_id = org.id;
      }
      if (!this.currentOrganisation$) {
        this.currentOrganisation$ = new BehaviorSubject<UserOrganisationSummary>(org);
      } else {
        this.currentOrganisation$.next(org);
      }
      this.setFreeTierExceeded(profile.account?.exceeded_free_plan || false);
    }
    this.profile$.next(profile);
    if (org && saveSettings) {
      this.http.patch('/settings', {current_org_id: org.id}).subscribe();
    }
  }

  public setCurrentOrganisation(org: UserOrganisationSummary) {
    if (!this.currentOrganisation$) {
      this.currentOrganisation$ = new BehaviorSubject<UserOrganisationSummary>(org);
    } else {
      this.currentOrganisation$.next(org);
    }
    const idx = this.profile.organisations.findIndex(o => o.id === org.id);
    this.profile.organisations[idx] = org;

    if (this.profile.uisettings.current_org_id !== org.id) {
      this.profile.uisettings.current_org_id = org.id;
      this.http.patch('/settings', {current_org_id: org.id}).subscribe();
    }
  }

  public get currentOrganisation(): UserOrganisationSummary | undefined {
    return this.profile?.organisations.find(o => o.id === this.currentOrganisationId)!;
  }

  public get isOrgInitialised(): boolean {
    return this.currentOrganisation?.onboarding_state === 'completed';
  }

  public get currentOrganisationId(): number {
    return this.currentOrganisation$?.value?.id || 0;
  }

  public get currentOrganisationHttpParam(): HttpParams {
    return new HttpParams({fromObject:
        {organisation_id: this.currentOrganisationId.toString()}});
  }

  public get isAdmin(): boolean {
    return this.currentOrganisation?.is_admin || false;
  }

  public get profile(): UserProfile {
    return <UserProfile>this.profile$.value;
  }

  public get settings(): UserUISettingsModel | undefined {
    return this.profile?.uisettings;
  }

  public get preferredCurrency(): string {
    return this.settings?.preferred_currency || 'usd';
  }

  public getAndClearOrgSignupToken(): string | null {
    const orgtoken = localStorage.getItem(ORG_SIGNUP_TOKEN);
    if (orgtoken) {
      localStorage.removeItem(ORG_SIGNUP_TOKEN);
    }
    return orgtoken;
  }

  // public requestUserAuthToken(platform: string, code: string): Observable<OAuthPostInstall> {
  //   const orgtoken = localStorage.getItem(ORG_SIGNUP_TOKEN);
  //   let payload: any = {code};
  //   if (orgtoken) {
  //     localStorage.removeItem(ORG_SIGNUP_TOKEN);
  //     payload.orgtoken = orgtoken;
  //   }
  //   if (this.currentOrganisationId) {
  //     payload.organisation_id = this.currentOrganisationId;
  //   }
  //   return this.http.post<OAuthPostInstall>(`/${platform}/auth`, payload);
  // }

  public async checkOAuthState(state: string): Promise<boolean> {
    const uuid = localStorage.getItem(OAUTH_STATE_KEY);
    if (uuid) {
      localStorage.removeItem(OAUTH_STATE_KEY);
    }
    if (!uuid || uuid !== state) {
      return false;
    }
    return true;
  }

  public setOAuthPostInstallToken(oauthresult: OAuthPostInstall, state?: string): boolean {
    if (state && !this.checkOAuthState(state)) {
      return false;
    }
    if (!this.isAuthenticated) {
      this.setToken(oauthresult.profile.token);
    }
    if (!this.profile) {
      this.setProfile(oauthresult.profile);
    }
    return true;
  }

  public setToken(token: string) {
    this.token = token;
    localStorage.setItem(CYKUBE_AUTH_TOKEN_KEY, this.token);
  }

  public cleanToken() {
    this.token = undefined;
    localStorage.removeItem(CYKUBE_AUTH_TOKEN_KEY);
  }

  public getToken(): string | undefined {
    return this.token;
  }

  get isAuthenticated(): boolean {
    return !!this.token;
  }


}
