import {Pipe, PipeTransform} from '@angular/core';
import {formatDuration, intervalToDuration} from 'date-fns';

@Pipe({
  name: 'shortDuration'
})
export class ShortDurationPipe implements PipeTransform {

  transform(durationSeconds: number): unknown {
    const dstr = formatDuration(intervalToDuration({start: 0, end: durationSeconds*1000}), {delimiter:','})
    const dsplit = dstr.split(',');
    return dsplit[0];
  }

}
