import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  AccountDetails,
  Currency,
  OrganisationDelete,
  OrganisationUpdate,
  Subscription,
  UserOrganisationSummary
} from '@models/generated.model';
import {Observable, tap} from 'rxjs';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {Router} from '@angular/router';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';


@Injectable({
  providedIn: 'root'
})
export class AccountService {

  leavingToken: string;

  constructor(private http: HttpClient,
              private router: Router,
              private errorDialogService: ErrorDialogService,
              private authService: AuthenticationService) {
  }

  updateOrganisation(orgupdate: OrganisationUpdate): Observable<any> {
    return this.http.patch<UserOrganisationSummary>(`/organisation/${this.authService.currentOrganisationId}`,
      orgupdate).pipe(
      tap(org => {
        this.authService.setCurrentOrganisation(org);
      })
    );
  }

  getAccountDetails(): Observable<AccountDetails> {
    return this.http.get<AccountDetails>(`/organisation/${this.authService.currentOrganisationId}/account`).pipe(
      tap(account => {
        this.authService.setFreeTierExceeded(account.exceeded_free_plan || false);
      })
    );
  }

  createSubscription(currency: Currency, name: string): Observable<AccountDetails> {
    return this.http.post<AccountDetails>(`/organisation/${this.authService.currentOrganisationId}/new-subscription`, {
      currency, name
    });
  }

  checkoutNewPlan(currency: Currency, name: string) {
    this.http.post(`/organisation/${this.authService.currentOrganisationId}/change-plan/checkout`,
      {
        currency,
        name
      }, {responseType: 'text'}).subscribe({
          next: url => {
            window.location.href = url;
          },
          error: err => {
            this.errorDialogService.show("Failed to contact payment provider",
              "Sorry - we cannot contact our payment provider. Please contact support@cykubed.com");
          }
        });
  }


  gotoCustomerPortal() {
    this.http.get(`/organisation/${this.authService.currentOrganisationId}/customer-portal-session-url`,
      {responseType: 'text'}).subscribe(url => {
      window.location.href = url as string;
    });
  }


  deleteOrganisation() {
    return this.http.delete(`/organisation/${this.authService.currentOrganisationId}`, {responseType: 'text'})
      .subscribe(async (token) => {
        this.authService.logOut(false);
        this.leavingToken = token;
        await this.router.navigate(['/leaving-message']);
      });
  }

  orgDeleteReason(reason: OrganisationDelete) {
    return this.http.post('/organisation-delete-reason', reason);
  }

  planChangeSucceeded(session_id: string): Observable<Subscription> {
    return this.http.post<Subscription>(`/order/success/${session_id}`, {});
  }
}
