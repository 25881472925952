import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {SpecFile, SpecFileStatus, TestRunDetail, TestRunJobStats} from '@models/generated.model';
import {IconsService} from '@services/ui-services/icon.service';
import {WebsocketService} from '@services/api-services/websocket.service';
import {takeWhile} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ACTIVE_STATES} from '@models/states';
import {TestRunService} from '@services/api-services/test-run.service';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {AgentService} from '@services/api-services/agent.service';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {SpecSummary, TestRunDetailExt, TestSummary} from '@models/testrun-ext.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSelectionListChange} from '@angular/material/list';
import {BreadcrumbService} from 'xng-breadcrumb';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AuthenticationService} from '@services/api-services/authentication.service';

type SpecFileStatusMap = {
  [key in SpecFileStatus]: number;
}

class ProgressTableSource extends MatTableDataSource<SpecSummary> {
  specFileStatusOrder: SpecFileStatusMap = {
    started: 0,
    timeout: 1,
    failed: 2,
    passed: 3,
    flakey: 4,
    cancelled: 5
  }

  override sortingDataAccessor: (data: SpecSummary, sortHeaderId: string) => string | number = (
    data: SpecSummary,
    sortHeaderId: string,
  ): string | number => {
    const value = (data as unknown as Record<string, any>)[sortHeaderId];
    if (sortHeaderId === 'status') {
      const status = value as SpecFileStatus;
      if (!status) {
        return -10000;
      }
      let idx = this.specFileStatusOrder[status];
      if (idx < 0) {
        idx = -1000000;
      }
      if (status === 'passed' && data.flakeyTests?.length) {
        idx = 2;
      }
      return -idx;
    }
    if (sortHeaderId === 'duration') {
      return Number(value);
    }
    return value;
  }
}

@UntilDestroy()
@Component({
  selector: 'app-test-run',
  templateUrl: './test-run.component.html',
  styleUrls: ['./test-run.component.scss']
})
export class TestRunComponent implements AfterViewInit {


  @ViewChild(MatTable) specTable: MatTable<SpecSummary>;
  @ViewChild(MatSort) sort: MatSort;

  specDataSource: ProgressTableSource = new ProgressTableSource([]);
  testrun?: TestRunDetailExt;

  totalSpecs = 0;
  completedSpecs = 0;
  startedSpecs = 0;
  progress = 0;
  progressPlusStarted = 0;

  failedTests: TestSummary[] = [];
  flakeyTests: TestSummary[] = [];
  failFlakeSummaries: SpecSummary[];
  allSpecs: Array<SpecSummary> = [];

  selectedSpecSummaries: SpecSummary[]; // annoyingly even for single-select
  selectedSpecSummary: SpecSummary;

  selectedFailedTestSummary?: TestSummary;
  selectedFlakeyTestSummary?: TestSummary;

  shownFirstFail = false;

  mainTabIndex = 0;

  // Once the user has selected any tab we don't change the tab for them
  // Annoyingly it seems the only way to differentiate between a user clicking a tab and us selecting one is to assume
  // that all tab changes are human-directed: if we change the tab them we set this latch first, so we know it was us
  canChangeTab = true;
  nextTabIndexSelected: number;

  tabNameToIndex: { [key: string]: number } = {'logs': 0, 'progress': 1,
    'results': 1, 'failed': 2, 'flakes': 3, 'stats': 4};
  tabNamesLowerCase = ['logs', 'results', 'failed', 'flakes', 'stats' ];

  displayedColumns: string[] = ['shortFileName', 'pod', 'duration', 'status'];


  constructor(private testrunService: TestRunService,
              private socket: WebsocketService,
              private error: ErrorDialogService,
              public agentService: AgentService,
              public icons: IconsService,
              private router: Router,
              private snackbar: MatSnackBar,
              private authService: AuthenticationService,
              private breadcrumbService: BreadcrumbService,
              private location: Location,
              private errorDialogService: ErrorDialogService,
              private activatedRoute: ActivatedRoute) {
  }

  ngAfterViewInit() {
    this.specDataSource.sort = this.sort;
  }

  get rerunDisabled(): boolean {
    return this.authService.newRunsDiabled;
  }

  ngOnInit(): void {

    this.activatedRoute.data.subscribe(({testrun}) => {
      this.testrun = testrun;
      this.testRunChanged(testrun);
      const tab = this.activatedRoute.snapshot.paramMap.get('tab');
      if (tab) {
        this.mainTabIndex = this.tabNameToIndex[tab];
        if (tab === 'failed' && this.failedTests?.length) {
          this.selectFailedTest(this.failedTests[0]);
        }
        if (tab == 'flakes' && this.flakeyTests?.length) {
          this.selectFlakeyTest(this.flakeyTests[0]);
        }
      } else {
        this.switchToTab();
      }
    });
  }

  private summariseSpecFile(specfile: SpecFile): SpecSummary {
    const lastidx = specfile.file.lastIndexOf('/');
    let specsummary: SpecSummary = {
      ...specfile,
      shortFileName: specfile.file.slice(lastidx + 1),
      flakeyTests: [],
      failedTests: []
    }
    this.updateSpecSummary(specfile, specsummary);
    this.allSpecs.push(specsummary);
    return specsummary;
  }

  private updateSpecSummary(specfile: SpecFile, specsummary: SpecSummary) {
      specfile.result?.tests?.forEach(test => {
        test.results?.forEach(result => {
          // we only care about fails
          if (result.status === 'failed' || (result.status === 'passed' && result.retry)) {
            const summary: TestSummary = {
              ...result,
              file: specsummary.file,
              shortFileName: specsummary.shortFileName,
              title: test.title,
              line: test.line,
              context: test.context,
              specStatus: test.status,
              status: result.status
            }
            if (test.status == 'flakey') {
              specsummary.flakeyTests.push(summary);
              this.flakeyTests.push(summary);
            } else {
              specsummary.failedTests.push(summary);
              this.failedTests.push(summary);
            }
          }
        });
      });
      if (specsummary.flakeyTests?.length || specsummary.failedTests?.length) {
        this.failFlakeSummaries.push(specsummary);
      }
  }

  private summariseSpecFiles(testrun: TestRunDetail) {
    this.failFlakeSummaries = [];
    this.failedTests = [];
    this.flakeyTests = [];
    this.allSpecs = [];

    testrun.files?.forEach(specfile => {
      this.summariseSpecFile(specfile);
    });
  }

  private testRunChanged(testrun: TestRunDetail) {

    this.canChangeTab = true;
    this.breadcrumbService.set('@testrun', `${testrun.project.name}: #${testrun.local_id}`);

    if (testrun?.commit?.message) {
      testrun.commit.message = testrun.commit.message.trim();
    }
    const trid = testrun.id;

    this.summariseSpecFiles(testrun);

    const isActive = () => !!this.testrun && ACTIVE_STATES.includes(this.testrun.status) && this.testrun.id === trid;

    if (ACTIVE_STATES.includes(testrun.status)) {
      // start updates
      this.socket.getStatus$(testrun.id).pipe(
        untilDestroyed(this),
        takeWhile(isActive)
      ).subscribe(msg => {
        this.testrun!.status = msg.status;
        this.switchToTab();
        if (msg.status === 'cancelled') {
          this.specDataSource.data.forEach(spec => {
            if (!spec.finished) {
              spec.status = undefined;
            }
          });
        }
      });

      this.socket.getJobStatsChanged$(testrun.id).pipe(
        untilDestroyed(this)
      ).subscribe((stats: TestRunJobStats) => {
        this.testrun!.jobstats = stats;
      });

      this.socket.getSpecChanged$(testrun.id).pipe(
        untilDestroyed(this),
        takeWhile(isActive)
      ).subscribe((spec: SpecFile) => {
        const existing = this.specDataSource.data.find((f: SpecFile) => f.file === spec.file);
        if (existing) {
          existing.status = spec.status;
          // this nastiness is apparently needed
          // (@see https://stackoverflow.com/questions/68619461/angular-material-table-renderrows-does-not-work-with-matsort)
          this.specDataSource._updateChangeSubscription();
          existing.pod_name = spec.pod_name;
          if (spec.finished) {
            existing.finished = spec.finished;
            existing.duration = spec.duration;
            existing.failures = spec.failures;
            if (spec.finished) {
              this.completedSpecs++;
            }
            this.updateSpecSummary(spec, existing);
          } else if (spec.started) {
            this.startedSpecs++;
          }
          this.switchToTab();
          this.setProgress();
        }
      });

      this.socket.getTestRunDetail$(testrun.id).pipe(
        untilDestroyed(this),
        takeWhile(isActive)
      ).subscribe(testrun => {
        this.testrun = testrun;
        this.summariseSpecFiles(testrun);
        this.filterSpecs();
        this.switchToTab();
      });

    }
    this.filterSpecs();
  }

  private switchToTab() {
    if (!this.canChangeTab) {
      return;
    }
    const status = this.testrun!.status;
    let idx;
    if (status === 'pending' || status === 'building' || status === 'started') {
      // show the logs
      idx = 0;
    } else {
      if (status === 'timeout') {
        if (this.completedSpecs) {
          // show the result page if we had any specs completed
          idx = 1;
        } else {
          // otherwise show the logs
          idx = 0;
        }
      } else {
        if (status === 'failed') {
          // show the first failed test
          if (this.testrun?.error) {
            idx = 0;
          } else {
            idx = 2;
            if (this.failedTests?.length) {
              this.selectFailedTest(this.failedTests[0]);
            }
          }
        } else {
          // default to the result pane
          idx = 1;
        }
      }
    }
    if (idx >= 0) {
      this.selectTab(idx);
    }
  }

  private filterSpecs() {

    this.totalSpecs = this.allSpecs.length;
    this.completedSpecs = this.allSpecs.filter(spec => spec.finished).length;
    this.startedSpecs = this.allSpecs.filter(spec => spec.status === 'started').length || 0;

    this.setProgress();

    this.specDataSource.data = this.allSpecs;
    this.specTable?.renderRows();
  }

  setProgress() {
    if (this.totalSpecs) {
      this.progress = 100 * this.completedSpecs / this.totalSpecs;
      this.progressPlusStarted = 100 * (this.completedSpecs + this.startedSpecs) / this.totalSpecs;
    } else {
      this.progress = 100;
      this.progressPlusStarted = 100;
    }
  }

  get isRunning(): boolean {
    return !!this.testrun && ACTIVE_STATES.includes(this.testrun.status);
  }

  cancel() {
    if (this.testrun) {
      this.testrunService.cancelTestRun(this.testrun!).subscribe(() => {
        if (this.testrun) {
          this.testrun.status = 'cancelled';
        }
      });
    }
  }

  tabSelected(idx: number, persist = false) {
    this.mainTabIndex = idx;
    if (idx !== this.nextTabIndexSelected) {
      // user-directed
      this.canChangeTab = false;
    }
    if (idx == 2) {
      this.selectSpecGivenTestSummary( this.selectedFailedTestSummary || this.failedTests[0]);
    } else if (idx == 3) {
      this.selectSpecGivenTestSummary( this.selectedFlakeyTestSummary || this.flakeyTests[0]);
    }

    if (persist) {
      const url = this.router.createUrlTree(['.', {
        tab: this.tabNamesLowerCase[idx]
      }], {relativeTo: this.activatedRoute}).toString();
      this.location.replaceState(url);
    }
  }

  rerun() {
    if (!this.testrun) {
      return;
    }
    this.shownFirstFail = false;
    this.mainTabIndex = 0;
    this.canChangeTab = true;
    this.failFlakeSummaries = [];
    this.flakeyTests = [];
    this.failedTests = [];
    this.allSpecs = [];

    const trid = this.testrun.id;
    delete this.testrun;

    this.testrunService.rerunTestRun(trid).subscribe({
      next: async (newtr) => {
        await this.router.navigate(['/main/dashboard/testrun', newtr.project.name,
          newtr.local_id]);
      }, error: (msg) => {
        this.error.show("Rerun failed", msg.error?.detail);
      }
    });
  }

  selectFailedTest(test: TestSummary) {
    this.selectedFailedTestSummary = test;
    this.selectSpecGivenTestSummary(test);
    this.mainTabIndex = 2;
    this.canChangeTab = false;
  }

  selectFlakeyTest(test: TestSummary) {
    this.selectedFlakeyTestSummary = test;
    this.selectSpecGivenTestSummary(test);
    this.mainTabIndex = 3;
    this.canChangeTab = false;
  }

  private selectSpecGivenTestSummary(test: TestSummary) {
    this.selectedSpecSummary = this.failFlakeSummaries.find(s => s.file === test.file)!;
    this.selectedSpecSummaries = [this.selectedSpecSummary];
  }

  clearCache() {
    this.agentService.clearAllCaches().subscribe({
      next: () => {
        this.snackbar.open("Cleared", "OK", {duration: 2000});
      }, error: (err) => {
        this.errorDialogService.show("Failed to clear the caches", err.message);
      }
    });
  }


  specSummarySelected(event: MatSelectionListChange) {
    this.selectSpecSummary(event.options[0].value);
  }

  specSelected(spec: SpecFile) {
    const summary = this.failFlakeSummaries.find(s => s.file === spec.file);
    if (summary) {
      this.selectSpecSummary(summary);
    }
  }

  private selectSpecSummary(specsummary: SpecSummary) {
    this.selectedSpecSummaries = [specsummary];
    this.selectedSpecSummary = specsummary;
    if (specsummary.failedTests?.length) {
      this.selectFailedTest(specsummary.failedTests[0]);
    } else {
      if (specsummary.flakeyTests?.length) {
        this.selectFlakeyTest(specsummary.flakeyTests[0]);
      }
    }
  }

  selectTab(number: number) {
    this.nextTabIndexSelected = this.mainTabIndex = number;
  }

  tabChanged(event: MatTabChangeEvent) {
    console.log(event);
  }
}
