import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-circle-usage',
  templateUrl: './circle-usage.component.html',
  styleUrls: ['./circle-usage.component.scss']
})
export class CircleUsageComponent  implements OnInit, OnChanges {
  @Input() label: string;
  @Input() total: number;
  @Input() value: number;

  progress: number;

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  ngOnInit(): void {
    this.update();
  }

  private update() {
    if (!this.total) {
      this.progress = 0;
    } else {
      this.progress = (this.value * 100.0 / this.total);
    }
  }
}
