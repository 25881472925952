import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Currency, SubscriptionPlanWithPrices} from '@models/generated.model';
import {PlanWithDescription} from '@models/plans';

@Component({
  selector: 'app-sub-plan-option',
  templateUrl: './sub-plan-option.component.html',
  styleUrls: ['./sub-plan-option.component.scss']
})
export class SubPlanOptionComponent {

  @Input() showHosted: boolean;
  @Input() readOnly = false;
  @Input() currentPlan: string | undefined;
  @Input() plan: PlanWithDescription;
  @Input() currency: Currency;

  @Output() selected = new EventEmitter<SubscriptionPlanWithPrices>();
  @Output() cancelled = new EventEmitter<SubscriptionPlanWithPrices>();

  planSelected() {
    this.selected.next(this.plan);
  }

  get buttonText(): string {
    return (this.plan.name === 'Free')?'Try for free': 'Select';
  }

  cancelPlan() {
    this.cancelled.next(this.plan);
  }
}
