<h1>Webhook History</h1>

<h2>{{ webhook?.url }}</h2>

<div class="main-container">

  <div class="no-items" *ngIf="items && !items.length">No events recorded for this hook</div>

  <div class="list-wrapper">
    <mat-selection-list [multiple]="false" *ngIf="webhookId" (selectionChange)="selected($event)" [(ngModel)]="selectedItems">
      <mat-list-option *ngFor="let item of items" [value]="item" [class.passed]="item.status_code === 200">
        <span matListItemTitle class="row-centered" >
          <fa-icon *ngIf="item.status_code == 200 else failicon" class="passed" [icon]="successIcon"></fa-icon>
          <ng-template #failicon><fa-icon [icon]="failIcon" class="failed"></fa-icon></ng-template>
          <span class="status-code">{{ item.status_code }}</span>
          <span class="timestamp">{{ item.created | date:'MMM d, y, h:mm:ss' }}</span>
        </span>

      </mat-list-option>
    </mat-selection-list>
  </div>

  <div class="details">
    <ng-container *ngIf="selectedItem">

      <div class="response">
        <h2>Response</h2>

        <div class="label-value" *ngIf="selectedItem.error">
          <label>Error</label>
          <div class="value">{{ selectedItem.error }}</div>
        </div>
        <div class="response-data">{{ selectedItem.response }}</div>
      </div>

      <div class="request">
        <h2>Request</h2>

        <pre *ngIf="request">
          <code [highlight]="request"
                [languages]="['json']"></code>
        </pre>
      </div>
    </ng-container>

  </div>
</div>
