<div class="page">

  <div class="splash">

    <app-large-cykubed-logo></app-large-cykubed-logo>

  </div>

  <div class="auth-buttons">

    <button mat-raised-button (click)="githubSignup()" color="primary">
      <fa-icon [icon]="icons.github" size="lg"></fa-icon>
      <span>Sign in with GitHub</span>
    </button>

    <button mat-raised-button (click)="bitbucketSignup()" color="primary">
      <fa-icon [icon]="icons.bitbucket" size="lg"></fa-icon>
      <span>Sign in with Bitbucket</span>
    </button>

    <button mat-raised-button (click)="gitlabSignup()" color="primary">
      <fa-icon [icon]="icons.gitlab" size="lg"></fa-icon>
      <span>Sign in with GitLab</span>
    </button>

<!--    <asl-google-signin-button type="standard" size="large" width="300"></asl-google-signin-button>-->

  </div>
</div>
