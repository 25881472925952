<div class="page">

  <div class="main-heading">

    <h1><fa-icon [icon]="platformIcon"></fa-icon> {{ project.name }}</h1>
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="showConfirmDelete()">
        <mat-icon class="warning">delete</mat-icon>
        <span>Delete project</span>
      </button>
    </mat-menu>
  </div>

  <app-project-form [project]="project" (saved)="saved($event)"></app-project-form>

</div>



<ng-template #deleteConfirm>
  <h1 mat-dialog-title>Confirm delete project?</h1>
  <div mat-dialog-content>
    This will delete all test runs and associated artifacts. Are you sure?
  </div>
  <div mat-dialog-actions>
    <div  class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>No</button>
      <button mat-raised-button mat-dialog-close color="warn" (click)="deleteProject()">Confirm delete</button>

    </div>
  </div>
</ng-template>



