import {Component} from '@angular/core';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {UserOrganisationSummary} from '@models/generated.model';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {
  org: UserOrganisationSummary;

  constructor(private authService: AuthenticationService) {
    this.org = authService.currentOrganisation!;
  }
}
