import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {BillingService} from '@services/api-services/billing.service';
import {
  AccountDetails,
  SubscriptionPlan,
  SubscriptionPlanWithPrices,
  UserOrganisationSummary
} from '@models/generated.model';
import {FormControl} from '@angular/forms';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AccountService} from '@services/api-services/account.service';
import {switchMap} from 'rxjs';
import {UiSettingsService} from '@services/api-services/ui-settings.service';
import {MatDialog} from '@angular/material/dialog';
import {PlanWithDescription} from '@models/plans';
import {PlanOrder} from '@models/consts';

@UntilDestroy()
@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent implements OnInit {

  currencyCtrl = new FormControl();

  plansByName = new Map<string, SubscriptionPlanWithPrices>();

  plans: PlanWithDescription[];
  org: UserOrganisationSummary | undefined;
  accountDetails: AccountDetails;
  currentPlan: string | undefined;
  freeButtonText = 'Try for free';

  @ViewChild('deleteConfirm', { static: true }) deleteConfirm: TemplateRef<any>;

  @Input() mobile = false;
  @Input() readOnly = false;
  @Input() onboarding = false;
  @Input() showHosted = true;
  @Input() organisation: UserOrganisationSummary;
  @Input() hideCancelPlan = true;
  @Input() hideFreePlan = false;
  @Output() selected = new EventEmitter<SubscriptionPlan>();
  @Output() hostTypeChanged = new EventEmitter<string>();

  constructor(private billingService: BillingService,
              private uiSettingsService: UiSettingsService,
              private accountService: AccountService,
              public dialog: MatDialog,
              private authService: AuthenticationService) {
    this.org = authService.currentOrganisation;
  }

  ngOnInit(): void {

    this.billingService.getSubscriptionPlans().subscribe(plans => {
      plans.forEach(plan => {
        this.plansByName.set(plan.name, plan);
      });

      this.plans = PlanOrder.map(po => ({...this.plansByName.get(po.name)!,
        description: po.description}));
      if (this.hideFreePlan) {
        this.plans = this.plans.filter(p => p.name !== 'Free');
      }

      const currency = this.authService.preferredCurrency;
      this.currencyCtrl.setValue(currency);
    });

    if (!this.authService.isOrgInitialised) {
      this.onboarding = true;
    }
    if (!this.onboarding) {
      // fetch details
      this.accountService.getAccountDetails().subscribe(d => {
        this.accountDetails = d;
        this.currentPlan = d.subscription.plan.name;
        if (this.currentPlan === 'Free' && !this.onboarding) {
          this.freeButtonText = 'Stick with free';
        } else {
          this.freeButtonText = 'Cancel paid plan';
        }
      });
    }

    if (this.authService.isAuthenticated) {
      this.currencyCtrl.valueChanges.pipe(
        untilDestroyed(this),
        switchMap(currency => this.uiSettingsService.setUISettings({preferred_currency: currency})))
        .subscribe();
    }
  }

  planSelected(plan: SubscriptionPlan) {
    this.selected.next(plan);
    if (plan.name !== 'Free') {
      // we'll need to checkout
      this.accountService.checkoutNewPlan(this.currencyCtrl.value, plan.name);
    }
  }

  showConfirmCancel() {
    this.dialog.open(this.deleteConfirm);
  }

  cancelPlan() {

  }
}
