import {Component, OnInit} from '@angular/core';
import {Notification} from '@models/generated.model';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-notifications-form-page',
  templateUrl: './notifications-form-page.component.html',
  styleUrls: ['./notifications-form-page.component.scss']
})
export class NotificationsFormPageComponent implements OnInit {

  notification: Notification;

  constructor(private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.notification = this.route.snapshot.data['notification'];
  }

  async cancelled() {
    if (this.notification) {
      await this.router.navigate(['../..'], {relativeTo: this.route})
    } else {
      await this.router.navigate(['..'], {relativeTo: this.route})
    }
  }

  async saved() {
    await this.cancelled();
  }
}
