import {Component, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AgentService} from '@services/api-services/agent.service';
import {IconsService} from '@services/ui-services/icon.service';
import {AgentModel, Project, UserProfile} from '@models/generated.model';
import semver from 'semver/preload';
import {faStarExclamation} from '@fortawesome/pro-solid-svg-icons';
import {AdminService} from '@services/api-services/admin.service';
import {AuthenticationService} from '@services/api-services/authentication.service';


interface ExtendedAgentModel extends AgentModel {
  updateAvailable: boolean
}

@UntilDestroy()
@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.scss']
})
export class AgentListComponent implements OnInit {
  agents: ExtendedAgentModel[] = [];
  connectedAgents: AgentModel[];
  agentsFetched = false;
  latestVersion: string;
  updatesAvailableIcon = faStarExclamation;
  projects: Project[];
  profile: UserProfile;

  constructor(private agentService: AgentService,
              private adminService: AdminService,
              private authService: AuthenticationService,
              public icons: IconsService) {
  }

  ngOnInit(): void {

    this.profile = this.authService.profile;

    this.agentService.agents$.pipe(untilDestroyed(this)).subscribe(agents => {
      this.latestVersion = this.agentService.latestVersion;
      this.agents = agents.map(agent => {
        return {...agent, updateAvailable: this.isUpdateAvailable(agent)};
      });
      this.agentsFetched = true;

      if (!agents?.length) {
        this.connectedAgents = [];
      } else {
        this.connectedAgents = agents.filter(c => c.connected);
      }
    });
  }

  private isUpdateAvailable(agent: AgentModel) {
    const x = !!this.latestVersion && !!agent.version && semver.gt(this.latestVersion, agent.version);
    return x;
  }

  agentDeleted(agent: AgentModel) {
    const idx = this.agents.findIndex(a => a.id === agent.id);
    this.agents.splice(idx, 1);
  }

  // deleteAgent(agent: AgentModel) {
  //   this.agentService.deleteAgent(agent.id).subscribe(() => {
  //   });
  // }


  addNewAgent() {
    this.agentService.addNewAgent();
  }

  async editAgent(agent: AgentModel) {
    await this.agentService.editAgent(agent);
  }

}
