import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Project} from '@models/generated.model';
import {setDisabled} from '@lib/form-utils';
import {distinctUntilChanged} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ProjectService} from '@services/api-services/project.service';


@UntilDestroy()
@Component({
  selector: 'app-project-select',
  templateUrl: './project-select.component.html',
  styleUrls: ['./project-select.component.scss'],
  providers: [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ProjectSelectComponent),
    multi: true
  }]
})
export class ProjectSelectComponent implements OnInit, ControlValueAccessor {

  @Input() placeholder: string;
  @Input() selectSingle: boolean = false;
  @Output() projectSelected = new EventEmitter<Project>();

  projectCtrl = new FormControl();
  projects: Array<Project>;
  selectedId: number;

  // call if value was changed inside our component
  private _onChange = (_: any) => {};
  // call if input was "touched" .. !
  private _onTouched = () => {};

  constructor(private projectService: ProjectService) {
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(id: any): void {
    if (typeof id === 'string') {
      id = parseInt(id as string);
    }
    this.selectedId = id;
    if (!id) {
      this.projectCtrl.reset(null, {emitEvent: false});
    } else {
      if (this.projects !== undefined) {
        this.setProject();
      }
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    setDisabled(this.projectCtrl, isDisabled);
  }

  private setProject() {
    const p = this.projects.find(p => p.id === this.selectedId);
    if (p) {
      this.projectCtrl.setValue(p, {emitEvent: false});
      this.projectSelected.next(p);
    }
  }

  ngOnInit(): void {
    this.projectService.projects$.subscribe(p => {
      this.projects = p;
      if (this.selectedId) {
        this.setProject();
      } else {
        if (this.selectSingle && this.projects?.length === 1 && !this.selectedId) {
          this.projectCtrl.setValue(p[0]);
          this._onChange(p[0].id);
        }
      }
    });
    this.projectCtrl.valueChanges.pipe(
      untilDestroyed(this),
      distinctUntilChanged())
      .subscribe(p => {
        if (p) {
          this._onChange(p.id);
          this.projectSelected.next(p);
        } else {
          this._onChange(null);
        }
    });
  }
}
