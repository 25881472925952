import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../services/api-services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class SignupGuard  {
  constructor(private router: Router,
              private authService: AuthenticationService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const token = route.queryParams['token'];
      if (token) {
        this.authService.setToken(token);
        return this.router.navigate(['/main/dashboard']);
      }

      // if we have projects then go to the testrun-table, otherwise go to setup
      if (!this.authService.isAuthenticated) {
        return true;
      }
      return this.router.navigate(['/main/dashboard']);
  }

}
