import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@services/api-services/authentication.service';

@Component({
  selector: 'app-holding-page',
  templateUrl: './holding-page.component.html',
  styleUrls: ['./holding-page.component.scss']
})
export class HoldingPageComponent implements OnInit {

  showNavBar = false;

  constructor(public auth: AuthenticationService) {

  }

  ngOnInit(): void {
    this.auth.getProfile$().subscribe(p => {
      this.showNavBar = true;
    });
  }

}
