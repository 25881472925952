import {Component} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';


@UntilDestroy()
@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss']
})
export class NotificationsPageComponent {


}
