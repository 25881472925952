<div class="page">
  <div class="logo">
    <app-large-cykubed-logo></app-large-cykubed-logo>
  </div>

  <div class="form">


    <div class="contents">

      <ng-container *ngIf="!submitted else thanks">
        <h1>We're sorry to see you go</h1>

        <p>If you have the time we would really appreciate some feedback on why you're leaving. Thanks.</p>

        <form [formGroup]="form">
          <mat-form-field>
            <mat-label>Reason</mat-label>
            <mat-select formControlName="reason">
              <mat-option value="too_expensive">Too expensive</mat-option>
              <mat-option value="missing_features">Missing features</mat-option>
              <mat-option value="unused">Did not use the service enough</mat-option>
              <mat-option value="other">Other (please comment below)</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Comments</mat-label>
            <textarea matInput placeholder="Please tell us what we can do"></textarea>
          </mat-form-field>

          <button mat-raised-button color="primary" (click)="submit()" type="submit"
                  [disabled]="form.invalid">Submit</button>


        </form>
      </ng-container>
      <ng-template #thanks>
        <h1>Thanks for the feedback! Goodbye</h1>
      </ng-template>
    </div>
  </div>
</div>
