import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {IntegrationsService} from '@services/api-services/integrations.service';

@Component({
  selector: 'app-github-app-installed',
  templateUrl: './github-app-installed.component.html',
  styleUrls: ['./github-app-installed.component.scss']
})
export class GithubAppInstalledComponent implements OnInit {

  constructor(private integrationsService: IntegrationsService,
              private router: Router) {

  }

  ngOnInit(): void {
    this.integrationsService.githubAppInstalled().subscribe(async () => {
      await this.router.navigate(['/main/settings/projects/new'])
    });
  }

}
