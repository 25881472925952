<div class="page">
  <div class="filters no-subscript">
    <app-project-select data-testid="project-select" [formControl]="projectCtrl" [selectSingle]="true" (projectSelected)="projectSelected($event)"></app-project-select>

    <app-author-select data-testid="author-select" [formControl]="authorCtrl"></app-author-select>
    <app-branch-select [formControl]="branchCtrl" [projectId]="projectCtrl.value"
                        data-testid="branch-select"
    ></app-branch-select>

    <mat-form-field appearance="fill" class="status-select"  data-testid="status-select">
      <mat-label>Status</mat-label>
      <mat-select [formControl]="statusCtrl">
        <mat-option>All</mat-option>
        <mat-option value="failed">Failed</mat-option>
        <mat-option value="flakey">Flakey</mat-option>
        <mat-option value="passed">Passed</mat-option>
        <mat-option value="timeout">Timeout</mat-option>
        <mat-option value="cancelled">Cancelled</mat-option>
      </mat-select>
    </mat-form-field>

    <span class="flex-spacer"></span>

    <button mat-raised-button color="primary" (click)="startRun()" [disabled]="startDisabled">Start</button>
  </div>

  <div class="testruns" #tablewrap>
    <table>
      <colgroup>
        <col class="col-project" *ngIf="!projectCtrl.value"/>
        <col class="col-id"/>
        <col class="col-avatar"/>
        <col class="col-description"/>
        <col/>
        <col class="col-started"/>
        <col class="col-duration"/>
        <col class="col-status"/>
      </colgroup>
      <thead>
      <tr>
        <th *ngIf="!projectCtrl.value">Project</th>
        <th colspan="4">Run</th>
        <th>Started</th>
        <th>Duration</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let run of service.summaries.items" (click)="runClicked(run)" class="testrun-summary">
          <td class="project" *ngIf="!projectCtrl.value" aria-label="Project"><span>{{ run.project_name }}</span></td>
          <td class="id" aria-label="Test run ID"><span>#{{ run.local_id | number }}</span></td>
          <td class="avatar"><span *ngIf="run.commit?.author?.avatar_url">
            <img [alt]="run.commit!.author.name!" class="avatar"
                 [src]="run.commit!.author.avatar_url"></span>
          </td>
          <td class="description" colspan="2" aria-label="Description">
            <div class="heading">{{ run.commit?.message }}</div>
            <div class="sub-heading">
              <span class="author-name" aria-label="Author">
                <fa-icon [icon]="icons.user"></fa-icon> {{ run.commit?.author?.name }}
              </span>
              <span class="branch" aria-label="Branch">
                <fa-icon [icon]="icons.branch"></fa-icon> {{ run.branch }}
              </span>
              <span class="commit" aria-label="Git Commit">
                <a (click)="gotoCommit($event, run)"><fa-icon [icon]="icons.commit"></fa-icon> {{ run.sha | slice:0:7 }}</a>
              </span>
            </div>
          </td>
          <td class="started" aria-label="Started"><ng-container *ngIf="run.started">{{ toDate(run.started) | dfnsFormatDistanceToNowStrict }} ago</ng-container></td>
          <td class="duration" aria-label="Duration"><ng-container *ngIf="run.duration">{{ run.duration | shortDuration }}</ng-container></td>
          <td class="status"   aria-label="Status"
                matTooltipPosition="left"
                matTooltipShowDelay="0">
            <fa-icon [icon]="icons.status(run.status)" [class]="run.status" class="status foreground"
                     (click)="gotoFailedRun(run, $event)"
                     [spin]="isRunning(run)" [matTooltip]="getStatusTooltip(run)" [matTooltipShowDelay]="0"></fa-icon>
            <fa-icon [icon]="icons.warning" class="flake status foreground" *ngIf="run.flakey_tests"
                     (click)="gotoRunWithFlakes(run, $event)"
                     [matTooltip]="getFlakeyTooltip(run)" [matTooltipShowDelay]="0"></fa-icon>
          </td>
        </tr>

      </tbody>

    </table>

    <div class="no-content" *ngIf="!service.fetching && !service.summaries.total">
      <div class="no-runs" *ngIf="!isFiltered" data-testid="no-runs-message">
        <h2>No test runs yet</h2>

        <ng-container *ngIf="!projects?.length">
          <button mat-raised-button color="primary" class="add-project-button" data-testid="add-project-button"
                  [disabled]="!allowAdd"
                  [routerLink]="'/main/settings/projects/new'">Add a project</button>
        </ng-container>
      </div>
      <div class="filtered" *ngIf="isFiltered">
        <h2>No test runs match this filter</h2>
        <p>Try changing your filter</p>
      </div>
    </div>

  </div>

  <mat-paginator [length]="service.summaries.total" *ngIf="service.summaries.total"
              [pageIndex]="trfilter.page"
              [pageSize]="trfilter.pagesize"
              [pageSizeOptions]="pageSizeOptions"
               (page)="pageChanged($event)"
              aria-label="Select page">
  </mat-paginator>

</div>
