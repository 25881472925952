import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {from, map, Observable, of} from 'rxjs';
import {GitOrganisation, PlatformEnum, Repository, Workspace} from '@models/generated.model';

export interface GenericOwner {
  id: string;
  name: string;
  is_user: boolean
}


@Injectable({
  providedIn: 'root'
})
export class RepositoriesService {

  constructor(private http: HttpClient) { }

  getOwners(platform: PlatformEnum): Observable<GenericOwner[]> {
    if (platform === 'github') {
      return this.getGithubOrganisations().pipe(
        map(orgs => {
        return orgs.map(org => ({name: org.name, id: org.login, is_user: false}));
      }))
    } else if (platform === 'bitbucket') {
      return this.getBitbucketWorkspaces().pipe(
        map(orgs => {
          return orgs.map(org => ({name: org.name, id: org.slug, is_user: false}));
        }))
    } else {
      return of([]);
    }
  }

  getGithubOrganisations(): Observable<GitOrganisation[]> {
    return this.http.get<GitOrganisation[]>(`/git/github/organisation`)
  }

  getRepositories(platform: PlatformEnum, owner_id: any, search?: string, is_user: boolean = false):
    Observable<Repository[]> {
    let url: string;
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    if (is_user) {
      params = params.set('is_user', true);
    }
    if (platform === 'github') {
      params = params.set('login', owner_id.toString());
      url = '/git/github/repository'
    } else if (platform === 'bitbucket') {
      url = `/git/bitbucket/repository/${owner_id}`
    } else if (platform === 'gitlab') {
      url = `/git/gitlab/repository`
    } else {
      return from([]);
    }
    return this.http.get<Repository[]>(url, {params});
  }

  getGitLabRepositories(search?: string): Observable<Repository[]> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    return this.http.get<Repository[]>('/git/gitlab/repository', {params});
  }

  getBitbucketWorkspaces(): Observable<Workspace[]> {
    return this.http.get<Workspace[]>('/git/bitbucket/workspace');
  }

  getBitbucketRepositories(workspace_slug: string, search?: string): Observable<Repository[]> {
    let params = new HttpParams();
    if (search) {
      params = params.set('search', search);
    }
    return this.http.get<Repository[]>(`/git/bitbucket/repository/${workspace_slug}`,
      {params});
  }
}
