import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PlatformEnum, UserUISettingsModel} from '@models/generated.model';
import {Observable} from 'rxjs';
import {AuthenticationService} from '@services/api-services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class UiSettingsService {
  constructor(private http: HttpClient,
              private authService: AuthenticationService) { }

  setGitUISettings(platform: PlatformEnum, git_org_id: string) {
    let existing = this.authService.profile.uisettings;
    if (git_org_id && (platform !== existing.last_git_platform || git_org_id !== existing.last_git_org_id)) {
      existing.last_git_org_id = git_org_id;
      existing.last_git_platform = platform;
      this.setUISettings(existing).subscribe();
    }
  }

  setUISettings(settings: UserUISettingsModel): Observable<any>{
    return this.http.patch<UserUISettingsModel>('/settings', settings);
  }

}
