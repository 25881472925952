import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ErrorDialogComponent} from '../../features/widgets/error-dialog/error-dialog.component';
import {Observer} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class ErrorDialogService {

  constructor(private dialog: MatDialog,
              private snackBar: MatSnackBar) {
  }

  show(title: string, message?: string) {
    this.dialog.open(ErrorDialogComponent, {
      data: {
        title,
        message
      },
      width: '600px'});
  }

  genericUpdateObserver(success: string, fail: string): Partial<Observer<any>> {
    return {
      next: () => {
        this.snackBar.open(success, "OK", {duration: 2000});
      },
      error: (resp) => {
        this.show(fail, resp?.error?.detail);
      }
    }
  }
}
