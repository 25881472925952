import {NgModule} from '@angular/core';

import {SettingsRoutingModule} from './settings-routing.module';
import {SettingsComponent} from './settings.component';
import {SharedModule} from '../shared/shared.module';
import {WidgetsModule} from '../widgets/widgets.module';
import {ProjectModule} from './projects/project.module';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {HighlightModule} from 'ngx-highlightjs';
import {MatRadioModule} from '@angular/material/radio';
import {IntegrationsModule} from './integrations/integrations.module';
import {MatStepperModule} from '@angular/material/stepper';
import {NavModule} from '../nav/nav.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {AccountModule} from './account/account.module';
import {CommonSettingsModule} from './common/common-settings.module';
import {WebhooksModule} from './webhooks/webhooks.module';
import {NotificationsModule} from './notifications/notifications.module';
import {OnboardingModule} from './onboarding/onboarding.module';
import {UsersModule} from './users/users.module';
import {AgentsModule} from './agents/agents.module';
import {ApiTokensModule} from './api-tokens/api-tokens.module';
import {AdminModule} from './admin/admin.module';


@NgModule({
  declarations: [
    SettingsComponent
  ],
  imports: [
    SharedModule,
    AdminModule,
    ApiTokensModule,
    AccountModule,
    AgentsModule,
    UsersModule,
    WebhooksModule,
    CommonSettingsModule,
    NotificationsModule,
    NavModule,
    ProjectModule,
    SettingsRoutingModule,
    WidgetsModule,
    MatStepperModule,
    MatButtonToggleModule,
    HighlightModule,
    MatRadioModule,
    IntegrationsModule,
    MatDatepickerModule,
    OnboardingModule
  ]
})
export class SettingsModule { }
