<div class="page">


  <div *ngIf="!selected" class="select-repos">
    <h1>Create a new project</h1>

    <ng-container *ngIf="platforms?.length; else noConnectedPlatforms">
      <h4>Select a repository:</h4>

      <div class="search-row">
        <mat-form-field appearance="outline" class="select-platform">
          <mat-label>Platform</mat-label>
          <mat-select [formControl]="platformControl">
            <mat-select-trigger>
              <app-platform-name [platform]="platformControl.value"></app-platform-name>
            </mat-select-trigger>
            <mat-option *ngFor="let platform of platforms" [value]="platform">
              <app-platform-name [platform]="platform"></app-platform-name>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="select-owner" *ngIf="showOwner">
          <mat-label>
            <span *ngIf="selectedPlatform==='github'">User / organisation</span>
            <span *ngIf="selectedPlatform==='bitbucket'">Workspace</span>
          </mat-label>
          <mat-select [formControl]="ownerControl" >
            <mat-select-trigger>
              {{ ownerControl.value?.name }}
            </mat-select-trigger>
            <mat-option *ngFor="let owner of owners" [value]="owner">
              {{ owner.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="select-repos-list">
        <div class="search">
          <mat-form-field appearance="outline">
            <input matInput placeholder="Search" [formControl]="searchControl" name="ignoreme" autocomplete="off"
                   type="search">
          </mat-form-field>
        </div>

        <div class="fetching">
          <fa-icon [icon]="icons.spinner" *ngIf="fetching" [spin]="true" size="2xl"></fa-icon>
        </div>

        <div class="no-content" *ngIf="!fetching && !repositories?.length">
          No matching repositories
        </div>

        <div class="list-wrapper">
          <mat-selection-list [multiple]="false" *ngIf="repositories">
            <mat-list-option *ngFor="let repos of repositories" (click)="selectRepos(repos)">
              {{ repos.name }}
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>

    </ng-container>

   <ng-template #noConnectedPlatforms>
      <p>Please connect to one of the following supported platforms:</p>

     <app-integrations-list [redirectHere]="true" filter="git"></app-integrations-list>
   </ng-template>
  </div>

  <ng-container *ngIf="selected">
    <h1><fa-icon [icon]="reposIcon"></fa-icon> {{ selected.name }}</h1>
    <app-project-form [repos]="selected"></app-project-form>
  </ng-container>

</div>
