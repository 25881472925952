import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {WidgetsModule} from '../../widgets/widgets.module';
import {OrganisationDetailsComponent} from './organisation-details/organisation-details.component';
import {PlanUsageComponent} from './plan-usage/plan-usage.component';
import {UpgradePlanComponent} from './plan-usage/upgrade-plan/upgrade-plan.component';
import {SubPlanOptionComponent} from './sub-plan-option/sub-plan-option.component';
import {SubPlanSettingsComponent} from './sub-plan-settings/sub-plan-settings.component';
import {SubscriptionPlanPricesComponent} from './subscription-plan-prices/subscription-plan-prices.component';
import {SubscriptionPlansComponent} from './subscription-plans/subscription-plans.component';
import {AccountComponent} from './account.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';


@NgModule({
  declarations: [
    OrganisationDetailsComponent,
    PlanUsageComponent,
    UpgradePlanComponent,
    SubPlanOptionComponent,
    SubPlanSettingsComponent,
    SubscriptionPlanPricesComponent,
    SubscriptionPlansComponent,
    AccountComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    WidgetsModule,
    MatButtonToggleModule
  ],
  exports: [
    SubscriptionPlansComponent
  ]
})
export class AccountModule { }
