import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SettingsComponent} from './settings.component';
import {ProjectResolver} from '@services/resolvers/project.resolver';
import {AgentListComponent} from './agents/agent-list.component';
import {ApiTokensComponent} from './api-tokens/api-tokens.component';
import {ProjectsComponent} from './projects/projects.component';
import {NewProjectPageComponent} from './projects/new-project-page/new-project-page.component';
import {ProjectsListComponent} from './projects/projects-list/projects-list.component';
import {ProjectComponent} from './projects/project.component';
import {AgentsComponent} from './agents/agents.component';
import {EditAgentComponent} from './agents/edit-agent/edit-agent.component';
import {AccountComponent} from './account/account.component';
import {UsersComponent} from './users/users.component';
import {UsersTableComponent} from './users/users-table/users-table.component';
import {InviteUsersComponent} from './users/invite-users/invite-users.component';
import {AdminGuard} from '@guards/admin.guard';
import {IntegrationsComponent} from './integrations/integrations.component';
import {OnboardingGuard} from '@guards/onboarding.guard';
import {ChooseOrgNameComponent} from './onboarding/choose-org-name/choose-org-name.component';
import {OnboardingComponent} from './onboarding/onboarding.component';
import {ChooseHostedComponent} from './onboarding/choose-hosted/choose-hosted.component';
import {OnboardingChoosePlanComponent} from './onboarding/choose-plan/onboarding-choose-plan.component';
import {PlanUsageComponent} from './account/plan-usage/plan-usage.component';
import {OrganisationDetailsComponent} from './account/organisation-details/organisation-details.component';
import {NotificationsPageComponent} from './notifications/notifications-page/notifications-page.component';
import {WebhooksPageComponent} from './webhooks/webhooks-page/webhooks-page.component';
import {ChooseNotificationComponent} from './onboarding/choose-notification/choose-notification.component';
import {UpgradePlanComponent} from './account/plan-usage/upgrade-plan/upgrade-plan.component';
import {StaffGuard} from '@guards/staff.guard';
import {AdminOrganisationsComponent} from './admin/organisations/admin-organisations.component';
import {AdminComponent} from './admin/admin.component';
import {SubPlanSettingsComponent} from './account/sub-plan-settings/sub-plan-settings.component';
import {NotificationTableComponent} from './notifications/notification-table/notification-table.component';
import {
  NotificationsFormPageComponent
} from './notifications/notifications-form-page/notifications-form-page.component';
import {notificationResolver} from '@services/resolvers/notification.resolver';
import {WebhooksTableComponent} from './webhooks/webhooks-table/webhooks-table.component';
import {WebhooksFormPageComponent} from './webhooks/webhooks-form-page/webhooks-form-page.component';
import {webhookResolver} from '@services/resolvers/webhook.resolver';
import {WebhookHistoryComponent} from './webhooks/webhook-history/webhook-history.component';
import {AdminOrgDetailsComponent} from './admin/admin-org-details/admin-org-details.component';
import {adminOrgResolver} from '@services/resolvers/admin-org.resolver';
import {AdminUsersComponent} from './admin/admin-users/admin-users.component';

const routes: Routes = [
  {
    path: 'onboarding',
    component: OnboardingComponent,
    data: { breadcrumb: { skip: true } },
    canActivate: [OnboardingGuard],
    children: [
      {
        path: 'choose-org-name',
        component: ChooseOrgNameComponent
      },
      {
        path: 'choose-hosted',
        component: ChooseHostedComponent
      },
      {
        path: 'choose-notification',
        component: ChooseNotificationComponent
      },
      {
        path: 'choose-plan',
        component: OnboardingChoosePlanComponent
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'choose_org_name'
      }
    ],
  },
  {
    path: '',
    component: SettingsComponent,
    data: { breadcrumb: { disable: true } },
    canActivate: [AdminGuard],
    children: [
      {
        path: 'admin',
        canActivate: [StaffGuard],
        component: AdminComponent,
        children: [
          {
            path: 'organisations',
            component: AdminOrganisationsComponent
          },
          {
            path: 'organisation/:id',
            component: AdminOrgDetailsComponent,
            resolve: {
              org: adminOrgResolver
            }
          },
          {
            path: 'users',
            component: AdminUsersComponent
          },
          {
            path: '',
            redirectTo: 'users',
            pathMatch: 'prefix'
          }
        ]
      },
      {
        path: 'projects',
        component: ProjectsComponent,
        data: {
          breadcrumb: 'projects'
        },
        children: [
          {
            path: '',
            component: ProjectsListComponent
          },
          {
            path: 'new',
            component: NewProjectPageComponent,
            data: {
              breadcrumb: 'new project'
            }
          },
          {
            path: 'project/:id',
            component: ProjectComponent,
            resolve: {
              project: ProjectResolver
            },
            data: {
              breadcrumb: {
                alias: 'projectName'
              }
            }
          }
        ]
      },
      {
        path: 'agents',
        component: AgentsComponent,
        data: {
          breadcrumb: 'agents'
        },
        children: [
          {
            path: '',
            component: AgentListComponent
          },
          {
            path: 'manage/:id',
            component: EditAgentComponent,
            data: {
              breadcrumb: 'manage agent'
            }
          }
        ]
      },
      {
          path: 'api',
          component: ApiTokensComponent,
          data: {
            breadcrumb: 'api'
          }
      },
      {
        path: 'account',
        component: AccountComponent,
        data: {
            breadcrumb: 'account'
        },
        children: [
          {
            path: 'plan',
            component: SubPlanSettingsComponent,
            data: {
              breadcrumb: 'subscription plan'
            },
            children: [
              {
                path: 'usage',
                component: PlanUsageComponent,
                data: {
                  breadcrumb: 'usage'
                },
              },
              {
                path: 'upgrade',
                component: UpgradePlanComponent,
                data: {
                  breadcrumb: 'upgrade'
                }
              },
              {
                path: '',
                pathMatch: 'full',
                redirectTo: 'usage'
              }
            ]
          },
          {
            path: 'org-details',
            component: OrganisationDetailsComponent,
            data: {
              breadcrumb: 'organisation details'
            }
          },
          {
              path: '',
              pathMatch: 'full',
              redirectTo: 'plan'
            }
        ]
      },
      {
        path: 'users',
        component: UsersComponent,
        data: {
          breadcrumb: 'users'
        },
        children: [
          {
            path: '',
            component: UsersTableComponent
          }, {
            path: 'invite',
            component: InviteUsersComponent
          }
        ],
      },
      {
        path: 'notifications',
        component: NotificationsPageComponent,
        data: {
          breadcrumb: 'notifications'
        },
        children: [
          {
            path: '',
            component: NotificationTableComponent
          }, {
            path: 'new',
            component: NotificationsFormPageComponent,
            data: {
              breadcrumb: 'new notification'
            }
          }, {
            path: 'edit/:id',
            component: NotificationsFormPageComponent,
            data: {
              breadcrumb: 'edit'
            },
            resolve: {
              notification: notificationResolver
            }
          }
        ]
      },
      {
        path: 'webhooks',
        component: WebhooksPageComponent,
        data: {
          breadcrumb: 'webhooks'
        },
        children: [
          {
            path: '',
            component: WebhooksTableComponent
          }, {
            path: 'new',
            component: WebhooksFormPageComponent,
            data: {
              breadcrumb: 'new webhook'
            }
          }, {
            path: 'edit/:id',
            component: WebhooksFormPageComponent,
            data: {
              breadcrumb: 'edit'
            },
            resolve: {
              webhook: webhookResolver
            }
          }, {
            path: 'history/:id',
            component: WebhookHistoryComponent,
            data: {
              breadcrumb: 'history'
            }
          }
        ]
      },
      {
        path: 'integrations',
        component: IntegrationsComponent,
        data: {
          breadcrumb: 'integrations'
        },
      },
      {
        path: '',
        pathMatch: 'prefix',
        redirectTo: 'account'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)], exports: [RouterModule]
})
export class SettingsRoutingModule {
}
