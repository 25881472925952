<div class="page">
  <div class="main-heading">
    <h1>API</h1>
    <a mat-button [href]="apiDocUrl" target="_blank"><fa-icon [icon]="icons.externalLink"></fa-icon> Documentation</a>
  </div>


  <p>We use standard bearer token authentication to access the API: you can <a [href]="apiDocUrl" class="standard" target="_blank">find the docs here</a></p>

  <mat-card>
      <mat-card-header>
          <mat-card-title>API Tokens</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div *ngFor="let token of tokens" class="token-item">
          <div class="token-and-date">
            <div class="token mat-elevation-z1 p1mat-body-1">
              <span>{{ token.token }}</span>
              <app-copy-text-button [value]="token.token"></app-copy-text-button>
            </div>
            <div class="date label-value-vertical"><label>Created</label><span>{{ token.created_date | date }}</span></div>
          </div>
          <button mat-button color="warn" class="revoke-button" (click)="revokeToken(token.id)">Revoke token</button>
        </div>
        <div *ngIf="!tokens?.length">No tokens created</div>
    </mat-card-content>
     <mat-card-actions>
       <button mat-raised-button color="primary"  (click)="createToken()">Add token</button>
     </mat-card-actions>
  </mat-card>
</div>
