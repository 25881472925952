import {Component} from '@angular/core';

@Component({
  selector: 'app-large-cykubed-logo',
  templateUrl: './large-cykubed-logo.component.html',
  styleUrls: ['./large-cykubed-logo.component.scss']
})
export class LargeCykubedLogoComponent {

}
