<app-org-name-heading [showHostedState]="false"></app-org-name-heading>

<div class="contents">
  <h2>How do you want to run your tests?</h2>

  <p class="legend">You can try either option for free, and change your mind later</p>

  <div class="options">

    <div class="option">

      <div class="contents">
        <h2>Host on your own cluster</h2>

        <h3>This is the most cost-effective option with the most control:</h3>

        <ul>
          <li>
            <h4>Simple install using Helm</h4>
            <label>helm repo update and helm install. And that's it.</label>
          </li>
          <li>
            <h4>We only charge for each completed test</h4>
            <label>We define a test as an individual it() function. Skipped tests are not charged.</label>
          </li>
          <li>
            <h4>Unlimited concurrency</h4>
            <label>You are only limited by the quotas set by your cluster provider.</label>
          </li>
          <li>
            <h4>Use custom Docker runner images</h4>
            <label>Allows you to run other services alongside the runners, or even change the open-source runner completely.</label>
          </li>
        </ul>

      </div>
      <button mat-raised-button color="primary" type="button" (click)="selectSelfHosted(true)">Select</button>
    </div>

    <div class="option">

      <div class="contents">
        <h2>...or let us host for you</h2>

        <h3>If you don't already have a Kubernetes cluster, or don't want to run one:</h3>

        <ul>
          <li>
            <h4>We will charge for each completed test, and for resource usage</h4>
            <label>We operate on a per-minute pay-as-you-go basis for build credits, with 1 vCPU = 10 credits and 1 GB = 1 credit.</label>
          </li>
          <li>
            <h4>Limited concurrency</h4>
            <label>Up to 5 concurrent tests.</label>
          </li>
          <li>
            <h4>Pre-built docker images only</h4>
            <label>We've got images for all the main versions of Node and the most popular browsers</label>
          </li>
        </ul>
      </div>
      <button mat-raised-button color="primary" type="button" (click)="selectSelfHosted(false)">Select</button>

    </div>


  </div>

</div>
