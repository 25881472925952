import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {WebhooksService} from '@services/api-services/webhooks.service';
import {NewWebHook, WebHook} from '@models/generated.model';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormUtilsService} from '@services/form-utils.service';
import {MatDialog} from '@angular/material/dialog';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AuthenticationService} from '@services/api-services/authentication.service';

@UntilDestroy()
@Component({
  selector: 'app-webhook-form',
  templateUrl: './webhook-form.component.html',
  styleUrls: ['./webhook-form.component.scss']
})
export class WebhookFormComponent implements OnInit, OnChanges {

  @Input() projectId: number | undefined;
  @Input() webhook: WebHook;

  @Output() saved = new EventEmitter<WebHook>();
  @Output() deleted = new EventEmitter<number>();
  @Output() cancelled = new EventEmitter<any>();

  @ViewChild('confirmDeleteRef', { static: true }) confirmDeleteRef: TemplateRef<any>;

  form: FormGroup;
  saving = false;

  constructor(private service: WebhooksService,
              private errorDialogService: ErrorDialogService,
              private authService: AuthenticationService,
              private snackBar: MatSnackBar,
              private matDialog: MatDialog,
              private formUtils: FormUtilsService,
              private fb: FormBuilder) {

  }

  save() {
    this.saving = true;

    let base = this.form.value;
    if (!base.on_pass) {
      base.on_pass = false;
    }

    if (!this.webhook) {
      const newhook: NewWebHook = {...base, project_id: this.projectId,
                                  organisation_id: this.authService.currentOrganisationId};
      this.service.create(newhook).subscribe({
        next: created => {
          this.snackBar.open('Webhook created', "OK", {duration: 2000});
          this.saved.next(created);
        },
        error: err => {
          this.errorDialogService.show("Failed to create webhook", err.message);
          this.cancelled.next(null);
        },
        complete: () => { this.saving = false; }
      })
    } else {
      const hook = {...this.webhook, ...base};
      this.service.update(hook).subscribe({
        next: created => {
          this.snackBar.open('Webhook updated', "OK", {duration: 2000});
          this.saved.next(created);
        },
        error: err => {
          this.errorDialogService.show("Failed to update webhook", err.message);
          this.cancelled.next(null);
        },
        complete: () => { this.saving = false; }
      })
    }
  }

  createForm(webhook?: WebHook): void {
    this.form = this.fb.group({
      name: [webhook?.name, Validators.required],
      project_id: [webhook?.project_id],
      on_pass: [webhook?.on_pass],
      on_fixed: [webhook?.on_fixed],
      on_fail: [webhook?.on_fail],
      on_flake: [webhook?.on_flake],
      branch_regex: [webhook?.branch_regex, {updateOn: 'blur'}],
      url: [webhook?.url, [Validators.required, this.formUtils.urlValidator()]]
    })

    if (!webhook) {
      this.form.patchValue({on_pass: true, on_fixed: true, on_fail: false, on_flake: false});
    }

    this.form.controls['branch_regex'].valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
      if (!!v && !this.form.value.name) {
        this.form.patchValue({name: v});
      }
    });
  }

  ngOnInit(): void {
    this.createForm(this.webhook);
  }

  cancel() {
    this.cancelled.next(null);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.createForm(this.webhook);
  }

  delete() {
    this.service.delete(this.webhook.id).subscribe({
      next: () => {
        this.snackBar.open('Webhook deleted', "OK", {duration: 2000});
        this.cancel();
      }, error: (err) => {
        this.errorDialogService.show('Failed to delete webhook', err.message);
      }
    });
  }

  deleteConfirm() {
    this.matDialog.open(this.confirmDeleteRef);
  }
}
