import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from "@sentry/angular-ivy";
import {BrowserTracing} from "@sentry/tracing";

if (environment.production) {
  enableProdMode();
}

if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ["localhost", "https://api.cykubed.com"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ]
  });
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
