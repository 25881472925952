import {Injectable} from '@angular/core';
import {WebsocketService} from './websocket.service';
import {AgentListModel, AgentModel, Project} from '../../models/generated.model';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, filter, map, Observable, tap} from 'rxjs';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AgentService {

  private agentsSubject = new BehaviorSubject<AgentModel[]>([]);
  private latestAgentVersion: string;

  constructor(private http: HttpClient,
              private router: Router,
              private authService: AuthenticationService,
              private socket: WebsocketService)
  {
    socket.agent$.subscribe(agent => {
      const agents = this.agentsSubject.value;
      const idx = agents.findIndex(a => a.id === agent.id);
      if (idx < 0) {
        agents.push(agent);
      } else {
        agents[idx] = agent;
      }
      this.agentsSubject.next(agents);
    });

    this.fetchAndSetAgents();
  }

  fetchAndSetAgents() {
    this.listAgents().subscribe(agents => {
      this.agentsSubject.next(agents);
    });
  }

  listAgents(): Observable<AgentModel[]> {
    return this.http.get<AgentListModel>('/agent').pipe(map(m => {
      this.latestAgentVersion = m.latest_version;
      return m.agents;
    }));
  }

  get latestVersion(): string {
    return this.latestAgentVersion;
  }

  watchAgent$(id: number): Observable<AgentModel> {
    return this.socket.agent$.pipe(filter(agent => agent.id === id))
  }

  getAgent(id: number): Observable<AgentModel> {
    return this.http.get<AgentModel>(`/agent/${id}`);
  }

  get agents(): AgentModel[] {
    return this.agentsSubject.value;
  }

  createAgent(): Observable<AgentModel> {
    return this.http.post<AgentModel>('/agent',
      {organisation_id: this.authService.profile.uisettings.current_org_id})
      .pipe(tap(agent => {
      let agents = this.agents;
      agents.push(agent);
      this.agentsSubject.next(agents);
    }));
  }

  deleteAgent(id: number): Observable<any> {
    return this.http.delete<any>(`/agent/${id}`).pipe(tap( () => {
      const idx = this.agents.findIndex(x => x.id === id);
      this.agents.splice(idx, 1);
      this.agentsSubject.next(this.agents);
    }));
  }

  clearAllCaches(): Observable<any> {
    return this.http.post<any>(`/agent/clear-all-caches/${this.authService.currentOrganisationId}`, {});
  }

  update(agent: AgentModel): Observable<AgentModel> {
    return this.http.patch<AgentModel>(`/agent/${agent.id}`, agent).pipe(tap(agent => {
      const idx = this.agents.findIndex(x => x.id === agent.id);
      this.agents[idx] = agent;
      this.agentsSubject.next(this.agents);
    }));
  }

  get agents$(): Observable<AgentModel[]> {
    return this.agentsSubject.asObservable();
  }

  isAgentConnected$(project: Project): Observable<boolean> {
    return this.agentsSubject.pipe(
      map(agents => {
        if (!agents) return false;
        return !!agents.find(a => (!project.agent_id || a.id === project.agent_id) && a.connected);
    }));
  }

  addNewAgent() {
    this.createAgent().subscribe(async agent => {
      await this.editAgent(agent);
    });
  }

  async editAgent(agent?: AgentModel) {
    if (!agent) {
      // assume first
      agent = this.agents[0];
    }
    await this.router.navigate(['/main/settings/agents/manage', agent.id]);
  }

  get connected$(): Observable<number> {
    return this.agentsSubject.pipe(map(agents => {
      if (!agents) return 0;
      return agents.filter(a => a.connected).length;
    }));
  }

}
