<h2 mat-dialog-title>Start test run</h2>

<div mat-dialog-content>
  <form [formGroup]="form">
      <app-project-select formControlName="projectId"
                          class="field"></app-project-select>

      <app-branch-select formControlName="branch"  class="field"
                         [projectId]="form.controls['projectId'].value"></app-branch-select>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button mat-button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-button color="primary"
          mat-raised-button (click)="start()"
   [disabled]="form.invalid || starting">Start</button>
</div>
