import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {IntegrationsComponent} from './integrations.component';
import {WidgetsModule} from '../../widgets/widgets.module';
import {IntegrationsListComponent} from './git-platforms/integrations-list.component';
import {RocketchatCredentialsDialog} from './rocketchat-credentials/rocketchat-credentials-dialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';


@NgModule({
  declarations: [
    IntegrationsComponent,
    IntegrationsListComponent,
    RocketchatCredentialsDialog
  ],
  exports: [
    IntegrationsListComponent
  ],
  imports: [
    SharedModule,
    WidgetsModule,
    MatDatepickerModule
  ]
})
export class IntegrationsModule { }
