import {Component, OnInit} from '@angular/core';
import {ProjectService} from '@services/api-services/project.service';
import {Project} from '@models/generated.model';
import {IconsService} from '@services/ui-services/icon.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {IntegrationsService} from '@services/api-services/integrations.service';

@UntilDestroy()
@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss']
})
export class ProjectsListComponent implements OnInit {
  projects: Project[] = [];
  noProjects = false

  constructor(private projectService: ProjectService,
              private integrationService: IntegrationsService,
              public icons: IconsService) {
  }

  fetch() {
    this.projectService.projects$.subscribe(p => {
      this.projects = p;
      if (!p?.length) {
        this.noProjects = true;
      }
    })

  }

  ngOnInit(): void {
    this.fetch();
  }

  getIcon(project: Project) {
    return this.integrationService.getIcon(project.platform)
  }
}
