<div [ngSwitch]="platform" class="logo">
  <ng-container *ngSwitchCase="'github'">
      <fa-icon [icon]="icons.github" size="lg"></fa-icon>
      <img src="/assets/img/GitHub_Logo_cropped.png" alt="GitHub logo">
  </ng-container>
  <ng-container *ngSwitchCase="'bitbucket'">
      <img src="/assets/img/logo-gradient-blue-bitbucket.png" alt="Bitbucket logo">
  </ng-container>
  <ng-container *ngSwitchCase="'jira'">
      <img src="/assets/img/logo-gradient-blue-jira.png" alt="Jira logo">
  </ng-container>
  <ng-container *ngSwitchCase="'gitlab'">
      <img src="/assets/img/gitlab-logo-100.png" alt="GitLab logo">
  </ng-container>
  <ng-container *ngSwitchCase="'slack'">
      <img src="/assets/img/Slack-logo-RGB.png" alt="Slack logo">
  </ng-container>
  <ng-container *ngSwitchCase="'rocketchat'">
      <img src="/assets/img/rocketchat_logo.png" alt="Rocket.chat logo">
  </ng-container>
</div>
