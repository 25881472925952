import {Injectable} from '@angular/core';
import {TestRunFilter, TestRunService} from '@services/api-services/test-run.service';
import {TestRunSummaries, TestRunSummary} from '@models/generated.model';
import {debounceTime, distinctUntilChanged, finalize, Subject, switchMap, tap} from 'rxjs';
import {WebsocketService} from '@services/api-services/websocket.service';
import {extendOwn} from 'underscore';
import {Router} from '@angular/router';

const emptyTable: TestRunSummaries = {
  total: 0,
  page: 0,
  pagesize: 20,
  items: []
}


@Injectable({
  providedIn: 'root'
})
export class TestrunSummaryService {
  filter$ = new Subject<TestRunFilter>();
  fetching = false;
  scrollPosition: number;

  summaries: TestRunSummaries = {
    total: 0,
    page: 0,
    pagesize: 20,
    items: []
  }

  constructor(private socketService: WebsocketService,
              private router: Router,
              private testrunService: TestRunService) {

    this.filter$.pipe(
      tap( () => this.fetching = true),
      finalize( () => this.fetching = false),
      debounceTime(50),
      distinctUntilChanged(),
      switchMap( filter => this.testrunService.getTestRunSummaries(filter)),
    ).subscribe(result => {
      this.summaries = result;
      this.fetching = false;
    });

    this.socketService.getTestRunDetail$().subscribe(detail => {
      let summ = this.summaries;
      let existing = summ.items.find(tr => tr.id === detail.id);
      if (existing) {
        // detail is a superset of the summary
        extendOwn(existing, detail);
      } else {
        const summary = {...detail, project_id: detail.project.id, project_name: detail.project.name};
        summ.items.splice(0,0, summary);
      }
    });

    this.socketService.getStatus$().subscribe(msg => {
      const existing = this.getSummary(msg.testrun_id);
      if (existing) {
        existing.status = msg.status;
      }
    });

  }

  getSummary(trid: number): TestRunSummary | undefined {
    return this.summaries.items.find(tr => tr.id === trid);
  }

  async gotoTestRunDetail(projectName: string, localId: number) {
    await this.router.navigate(['/main/dashboard/testrun', projectName, localId]);
  }

  async gotoFailTestRunDetail(run: TestRunSummary) {
    await this.router.navigate(['/main/dashboard/testrun', run.project_name, run.local_id, {tab: 'failed'}]);
  }

  async gotoFlakeTestRunDetail(run: TestRunSummary) {
    await this.router.navigate(['/main/dashboard/testrun', run.project_name, run.local_id, {tab: 'flakes'}]);
  }
}
