<div class="page">
  <h1>Users</h1>

  <div class="user-table">
    <div class="standard-toolbar">
      <button mat-raised-button [cdkCopyToClipboard]="orgInviteLink"
              [disabled]="userLimitReached"
              (click)="showCopiedResponse()">Copy invite link</button>

      <button mat-raised-button color="primary"  [disabled]="userLimitReached"
              [routerLink]="['./invite']">Invite users</button>

      <span class="flex-spacer"></span>

      <button *ngIf="userLimitReached" mat-raised-button color="accent" type="button"
              matTooltip="Upgrade your subscription plan to allow more users"><fa-icon [icon]="icons.warning"></fa-icon> Maximum users</button>

    </div>
    <div class="card-contents">
      <div class="table-wrapper">
        <table mat-table [dataSource]="users">
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let user">
              <div class="row-centered" [class.pending]="user.is_invite">
                <img class="avatar" *ngIf="user.avatar_url" [src]="user.avatar_url">
                <span class="no-avatar" *ngIf="!user.avatar_url"><fa-icon size="xl"
                                                                          [icon]="icons.user"></fa-icon></span>
                <span class="name" *ngIf="!user.is_invite">{{ user.name }}</span>
                <span class="name" *ngIf="user.is_invite">(Pending)</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let user"> {{ user.email }}</td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef> Role </th>
            <td mat-cell *matCellDef="let user"> {{ (user.is_admin)?'Admin':'Member' }}</td>
          </ng-container>
          <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let user">
                <span class="action-buttons">
                  <ng-container  *ngIf="!user.is_invite">
                    <button mat-icon-button matTooltip="Edit permissions" [disabled]="profile.id == user.id"
                            (click)="editClicked(user)"><mat-icon>edit</mat-icon></button>
                  </ng-container>
                  <button mat-flat-button *ngIf="user.is_invite" (click)="resendInvite(user.email)" matTooltip="Resend invite"><mat-icon>mail</mat-icon> Resend</button>
                  <button mat-icon-button matTooltip="Remove from organisation" (click)="removeUserClicked(user)"><mat-icon>delete</mat-icon></button>
                </span>


              </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </div>
  </div>
</div>


<ng-template #confirmRemoveRef>
  <h1 mat-dialog-title>Remove {{ selected?.name }} from organisation?</h1>
  <div mat-dialog-actions>
    <div  class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>No</button>
      <button mat-raised-button mat-dialog-close color="warn" (click)="removeUser()">Yes</button>
    </div>
  </div>
</ng-template>

<ng-template #editUserRef>
  <h1 mat-dialog-title>Edit {{ selected?.name }}</h1>
  <div mat-dialog-content class="permissions">
    <mat-radio-group [formControl]="permissions">
      <mat-radio-button value="member">Member</mat-radio-button>
      <mat-radio-button value="admin">Admin</mat-radio-button>
    </mat-radio-group>
  </div>
  <div mat-dialog-actions>
    <div class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-raised-button color="primary"
              mat-dialog-close [disabled]="permissions.pristine" (click)="updateUser()">Update
      </button>
    </div>
  </div>
</ng-template>
