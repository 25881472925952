import {Component, OnInit} from '@angular/core';
import {IntegrationsService} from '@services/api-services/integrations.service';
import {NotificationsService} from '@services/api-services/notifications.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PlatformEnum} from '@models/generated.model';
import {IconsService} from '@services/ui-services/icon.service';
import {AccountService} from '@services/api-services/account.service';

@Component({
  selector: 'app-choose-notification',
  templateUrl: './choose-notification.component.html',
  styleUrls: ['./choose-notification.component.scss']
})
export class ChooseNotificationComponent implements OnInit {

  connectedPlatforms: PlatformEnum[];

  constructor(private integrationsService: IntegrationsService,
              private accountService: AccountService,
              private router: Router,
              private route: ActivatedRoute,
              public icons: IconsService,
              private notificationsService: NotificationsService) {
  }

  ngOnInit(): void {

    this.notificationsService.list().subscribe(async notifications => {
      if (notifications?.length) {
        // shouldn't really have got here - go to the next stage
        await this.router.navigate(['/main/settings/onboarding/choose-plan']);
      }
    });

    this.integrationsService.integrations$.subscribe(integrations => {
      this.connectedPlatforms = integrations.filter(
        i => i.connected && i.type === 'messaging').map(i => i.name);
    })
  }

  next() {
    this.accountService.updateOrganisation({onboarding_state: 'choose-plan'}).subscribe(
      async () => {
          await this.router.navigate(['../choose-plan'], {relativeTo: this.route});
    });
  }
}
