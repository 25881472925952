import {Component, Inject} from '@angular/core';
import {IconsService} from '@services/ui-services/icon.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

interface DialogData {
  title: string;
  message?: string;
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
              public icons: IconsService) {

  }
}
