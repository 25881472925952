import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '@environment';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {IconsService} from '@services/ui-services/icon.service';
import {AccountService} from '@services/api-services/account.service';
import {IntegrationsService} from '@services/api-services/integrations.service';

@Component({
  selector: 'app-oauth-callback',
  templateUrl: './oauth-callback.component.html',
  styleUrls: ['./oauth-callback.component.scss']
})
export class OauthCallbackComponent implements OnInit {
  constructor(private authService: AuthenticationService,
              private route: ActivatedRoute,
              private integrationsService: IntegrationsService,
              private snackBar: MatSnackBar,
              private accountService: AccountService,
              private errorDialog: ErrorDialogService,
              public icons: IconsService,
              private router: Router) {

  }

  installGithubApp() {
    const appname = (environment.production)?'cykubed':'cykubed-dev';
    window.location.href = `https://github.com/apps/${appname}/installations/new`;
  }

  async ngOnInit() {
    const platform = this.route.snapshot.params['platform'];
    const code = this.route.snapshot.queryParams['code'];
    const state = this.route.snapshot.queryParams['state'];

    if (!platform || !code) {
      await this.router.navigate(['/signup']);
      return;
    }

    // request the token
    this.integrationsService.requestAuthToken(platform, code).subscribe({
      next: (result) => {
        // if we're not in the beta programme, head to the holding page
        if (platform == 'github' && !result.integration.app_installed) {
          // Github needs an app installation
          this.installGithubApp();
        } else {
          const org = this.authService.currentOrganisation!;
          if (org.onboarding_state !== "completed") {
            this.router.navigate(['/main/settings/onboarding', org.onboarding_state]);
            return;
          }

          const redirect = this.integrationsService.getPostOAuthRedirect();
          if (redirect) {
            window.location.href = redirect;
          } else {
            // all good
            this.router.navigate(['/main/dashboard']);
            return;
          }
        }
      }, error: async (err) => {
        this.snackBar.open(`Failed to authenticate with ${platform}`);
        await this.router.navigate(['/signup']);
      }
    });

  }
}
