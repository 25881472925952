import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminOrganisationsComponent} from './organisations/admin-organisations.component';
import {AdminComponent} from './admin.component';
import {SharedModule} from '../../shared/shared.module';
import {WidgetsModule} from '../../widgets/widgets.module';
import {AdminUsersComponent} from './admin-users/admin-users.component';
import {AdminOrgDetailsComponent} from './admin-org-details/admin-org-details.component';
import {AdminUserListComponent} from './admin-user-list/admin-user-list.component';
import {AdminUserDetailsComponent} from './admin-user-details/admin-user-details.component';


@NgModule({
  declarations: [
    AdminOrganisationsComponent,
    AdminComponent,
    AdminUsersComponent,
    AdminOrgDetailsComponent,
    AdminUserListComponent,
    AdminUserDetailsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    WidgetsModule
  ]
})
export class AdminModule { }
