<div class="page">
  <h1>Integrations</h1>

  <mat-tab-group *ngIf="integrations" mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="150ms">
    <mat-tab label="Available">

      <section>
        <h2>Git</h2>
        <app-integrations-list filter="git"  [showAvailable]="true"
                               [redirectHere]="true"></app-integrations-list>
      </section>

      <section>
        <h2>Messaging</h2>
        <app-integrations-list filter="messaging" [showAvailable]="true"
                               [redirectHere]="true"></app-integrations-list>
      </section>

    </mat-tab>
    <mat-tab label="Installed">

      <section>
        <h2>Git</h2>
        <app-integrations-list filter="git"  [showAvailable]="false"></app-integrations-list>
      </section>

      <section>
        <h2>Messaging</h2>
        <app-integrations-list filter="messaging"
                               [showAvailable]="false"></app-integrations-list>
      </section>

    </mat-tab>
  </mat-tab-group>

</div>
