import {Component} from '@angular/core';
import {IconsService} from '@services/ui-services/icon.service';
import {AdminUser} from '@models/generated.model';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent {

  selectedUser: AdminUser;

  constructor(public icons: IconsService) {
  }

  selected(user: AdminUser) {
    this.selectedUser = user;
  }
}
