import {HttpParams} from '@angular/common/http';

export function serialiseParams(filter?: object): HttpParams {
  let params = new HttpParams();
  if (filter) {
    for (const [key, value] of Object.entries(filter)) {
      if (value instanceof Date) {
        params = params.append(key, value.toISOString());
      } else {
        if (value !== null && value !== undefined) {
          params = params.append(key, value.toString());
        }
      }
    }
  }
  return params;
}
