import {Component, Input} from '@angular/core';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {UserOrganisationSummary} from '@models/generated.model';

@Component({
  selector: 'app-org-name-heading',
  templateUrl: './org-name-heading.component.html',
  styleUrls: ['./org-name-heading.component.scss']
})
export class OrgNameHeadingComponent {
  @Input() showHostedState: boolean;

  org: UserOrganisationSummary;

  constructor(private authService: AuthenticationService) {
    this.org = authService.currentOrganisation!;
  }

  setHosted(st: boolean) {
    this.org.prefer_self_host = !st;
  }
}
