import {NgModule} from '@angular/core';
import {ProjectFormComponent} from './project-form/project-form.component';
import {SharedModule} from '../../shared/shared.module';
import {ProjectConfigComponent} from './project-config/project-config.component';
import {NewProjectPageComponent} from './new-project-page/new-project-page.component';
import {ReactiveFormsModule} from '@angular/forms';
import {WidgetsModule} from '../../widgets/widgets.module';
import {ProjectComponent} from './project.component';
import {ProjectsComponent} from './projects.component';
import {ProjectsListComponent} from './projects-list/projects-list.component';
import {IntegrationsModule} from '../integrations/integrations.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DropdownsModule} from '../../dropdowns/dropdowns.module';
import {CommonSettingsModule} from '../common/common-settings.module';
import {MatSliderModule} from '@angular/material/slider';


@NgModule({
  declarations: [
    ProjectFormComponent,
    ProjectConfigComponent,
    NewProjectPageComponent,
    ProjectsListComponent,
    ProjectComponent,
    ProjectsComponent
  ],
  imports: [
    SharedModule,
    WidgetsModule,
    ReactiveFormsModule,
    IntegrationsModule,
    MatCheckboxModule,
    DropdownsModule,
    CommonSettingsModule,
    MatSliderModule
  ]
})
export class ProjectModule { }
