<div class="nav-bar">

  <a class="logo row-centered" href="/">
    <img src="/assets/img/logos/white-text/Original.svg" alt="Cykubed app">
  </a>

  <ng-container *ngIf="showNavButtons">
    <button mat-button class="nav-menu-item" routerLinkActive="active" routerLink="dashboard">
      <fa-icon [icon]="icons.dashboard"></fa-icon>
      Dashboard
    </button>
    <button mat-button class="nav-menu-item" routerLinkActive="active" routerLink="settings" *ngIf="authService.isAdmin">
      <fa-icon [icon]="icons.settings"></fa-icon>
      Settings
    </button>

  </ng-container>

  <div class="flex-spacer"></div>

  <div class="impersonating" *ngIf="authService.impersonatingUser">
    <fa-icon size="2x" [icon]="icons.impersonatingIcon"></fa-icon> {{ authService.impersonatingUser.name }}

    <button mat-raised-button color="accent" type="button"
            (click)="authService.clearImpersonation()">Clear</button>
  </div>

  <button *ngIf="freePlan" mat-raised-button color="accent"
          routerLinkActive="hide"
          [routerLink]="['/main/settings/account/plan/upgrade']">Upgrade from Free Plan</button>

  <mat-select [formControl]="orgControl" class="org-select white-caret" *ngIf="showOrgSelect">

    <mat-select-trigger>
      <span class="row-centered selected-org-name">
        <mat-icon>domain</mat-icon> {{ orgControl.value?.name }}
      </span>
    </mat-select-trigger>
    <mat-option *ngFor="let org of profile.organisations" [value]="org">{{ org.name}}</mat-option>
  </mat-select>



  <ng-container *ngIf="profile">
    <button mat-icon-button [matMenuTriggerFor]="profileMenu" class="user-menu large-icon-button"
            data-testid="profile-button">
      <img *ngIf="profile.avatar_url" class="avatar" [src]="profile.avatar_url">
      <fa-icon [icon]="icons.user" *ngIf="!profile.avatar_url"></fa-icon>
    </button>

    <mat-menu #profileMenu="matMenu">
      <div mat-menu-item  class="user-details">
        <h4>{{ profile.name }}</h4>
        <label>{{ profile.email }}</label>
      </div>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="logOut()">Sign out</button>
    </mat-menu>
  </ng-container>
</div>
