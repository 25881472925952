<div class="page" *ngIf="agent">

  <div class="heading">
    <div *ngIf="!agent.first_connected">

      <div class="title-and-indicator">
        <h1>Install the Cykubed Agent</h1>
        <app-agent-indicator [showText]="true" [agent]="agent"></app-agent-indicator>
      </div>


      <p>You will need to install an agent in each Kubernetes cluster that you want to use to run tests.</p>
      <p>The agent is a lightweight single-node <a target="_blank"
                                                   href="https://kubernetes.io/docs/concepts/workloads/controllers/statefulset/">
        statefulset</a> that connects to our API servers via a websocket.</p>
      <p>Once connected it can then create <a
        href="https://kubernetes.io/docs/concepts/workloads/controllers/job/">Jobs</a> to build and test your apps.
      </p>

    </div>

    <div *ngIf="agent.first_connected">

      <div class="title-and-indicator">
        <div class="title">
          <h1>Agent connected <span *ngIf="agent.name !== 'Agent'">({{ agent.name }})</span></h1>
          <span *ngIf="agent.is_public" class="row-centered">
            <fa-icon  [icon]="icons.publicIcon" size="2x"></fa-icon>
            <h3>Public</h3>
          </span>
        </div>
        <app-agent-indicator [showText]="true" [agent]="agent"></app-agent-indicator>
      </div>

    </div>

  </div>

  <form class="main-contents" [formGroup]="form">
    <div class="instructions">

      <ng-container *ngIf="!agent?.first_connected && !this.installCommand">

        <div class="field-row">
          <mat-form-field appearance="outline" class="select-platform" *ngIf="!agent.first_connected">
            <mat-label>Target Kubernetes platform:</mat-label>
            <mat-select formControlName="platform">
              <mat-option [value]="'gke'">GKE (Google)</mat-option>
              <mat-option [value]="'aks'">AKS (Azure)</mat-option>
              <!--        <mat-option [value]="'eks'">EKS (Amazon)</mat-option>-->
              <mat-option [value]="'minikube'">Minikube</mat-option>
              <mat-option [value]="'generic'">Generic</mat-option>
            </mat-select>
            <mat-error>This field is required</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name">
            <mat-error>This field is required</mat-error>
          </mat-form-field>
        </div>

        <div class="vertical-form">

          <mat-form-field appearance="fill" class="medium">
            <mat-label>Namespace</mat-label>
            <input matInput type="text" formControlName="namespace">
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="platform === 'generic'">
            <mat-label>Storage class</mat-label>
            <input matInput formControlName="storage_class">
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="isGCP">
            <mat-label>GCP project ID</mat-label>
            <input formControlName="platform_project_id" matInput>
            <mat-hint>You only need to supply this if you want to enable Google Cloud Logging</mat-hint>
          </mat-form-field>

          <div class="field-wrapper" *ngIf="profile.is_staff">
            <mat-slide-toggle formControlName="is_public">Public?</mat-slide-toggle>
          </div>

        </div>

        <button mat-raised-button color="primary" (click)="saveInstallOptions()" [disabled]="form.invalid || form.pristine">Save options</button>

      </ng-container>
      <ng-container *ngIf="installCommand && !agent.first_connected && isPlatformSelected">
        <ng-container *ngIf="platform === 'minikube'">
          <p><a href="https://minikube.sigs.k8s.io/docs/" target="_blank">Minikube</a> is a great way to test out
            Cykubed locally for no cost: just follow the instructions <a
              href="https://minikube.sigs.k8s.io/docs/start/"
              target="_blank">here</a> to install it.</p>
          <p>Once it's running you'll need the following plugins:</p>
          <app-code-snippet [value]="minikubeInstall" class="minikube-install"></app-code-snippet>
        </ng-container>

        <p>We use <a href="https://helm.sh/" target="_blank">Helm</a> to install the agent. If you've not done so
          already you'll need to <a href="https://helm.sh/docs/intro/install/" target="_blank">install it locally</a>.
        </p>

        <p>If you have a strict firewall, you'll need to whitelist the following domains:</p>
        <ul>
          <li><a href="charts.cykubed.com">charts.cykubed.com</a>: the Helm charts</li>
          <li><a href="europe-docker.pkg.dev">europe-docker.pkg.dev</a>: our Docker repository</li>
          <li><a href="api.cykubed.com">api.cykubed.com</a>: our API servers</li>
        </ul>
        <p>Once Helm is installed, add and update the Cykubed chart repository:</p>

        <app-code-snippet [value]="addAndUpdateRepo"></app-code-snippet>
      </ng-container>

      <section *ngIf="form.valid && installCommand && !agent.first_connected">
        <p>You can now install the Cykubed agent with:</p>

        <app-code-snippet [value]="installCommand" class="full-width"></app-code-snippet>

        <p>Depending on your platform this can take a few minutes to install: if you want to track the install you can
          use <em>kubectl rollout</em> in the usual way:</p>

        <app-code-snippet [value]="rolloutCommand"></app-code-snippet>

      </section>

      <section *ngIf="agent.first_connected && upgradeCommand">

        <p>You may upgrade the agent via the following command:</p>

        <app-code-snippet [value]="upgradeCommand" class="full-width"></app-code-snippet>

      </section>
    </div>
    <div class="options">

      <mat-accordion>

        <mat-expansion-panel *ngIf="profile.is_staff && agent.first_connected">

          <mat-expansion-panel-header>
            <mat-panel-title>Public?</mat-panel-title>
            <mat-panel-description><ng-container *ngIf="isPublicAgent else disabled">ENABLED</ng-container></mat-panel-description>
          </mat-expansion-panel-header>

          <mat-slide-toggle formControlName="is_public">Public?</mat-slide-toggle>

        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="isSpotAvailable">
          <mat-expansion-panel-header>
            <mat-panel-title>Spot</mat-panel-title>
            <mat-panel-description><ng-container *ngIf="isSpotEnabled else disabled">ENABLED</ng-container></mat-panel-description>
          </mat-expansion-panel-header>
          <p>
            Some Kubernetes providers such as <a
            href="https://cloud.google.com/kubernetes-engine/docs/concepts/spot-vms#scheduling-workloads" target="_blank">GKE</a> and <a href="https://learn.microsoft.com/en-us/azure/aks/spot-node-pool" target="_blank">AKS</a>
            provide support for "Spot VMs".
            These are VMs that are priced lower than standard VMs and provide no guarantee of availability.</p>
          <p>Cykubed
            runs are inherently resilient and will seamlessly handle the case where the platform reclaims the VMs for
            higher priority workloads.</p>

          <ng-container *ngIf="platform!=='aks'">
            <p>To use Spot VMs where available, set a percentage greater than 0</p>
            <div class="field-wrapper">
              <mat-form-field appearance="fill">
                <mat-label>Spot percentage</mat-label>
                <input matInput formControlName="spot_percentage"
                       [min]="0" [max]="100" type="number">
                <mat-error>Please enter a valid percentage</mat-error>
              </mat-form-field>
              <app-info-icon tooltip="You may want to still run some percentage of runs on non-Spot VMs to
                  guarantee that your runs will complete within the overall deadline"></app-info-icon>

             </div>

          </ng-container>

          </mat-expansion-panel>
          <mat-expansion-panel [expanded]="false" *ngIf="allowPreProvision">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Capacity pre-provisioning
              </mat-panel-title>
              <mat-panel-description>
                <ng-container *ngIf="isPreprovisionEnabled else disabled">ENABLED</ng-container>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>
              Kubernetes platforms can take a while to scale up to handle new demand. This can be alleviated somewhat
              by
              pre-provisioning capacity using lower priority (and therefore <a
              href="https://kubernetes.io/docs/concepts/scheduling-eviction/pod-priority-preemption/" target="_blank">pre-emptible</a>)
              Jobs using the technique outlined <a
              href="https://cloud.google.com/kubernetes-engine/docs/how-to/capacity-provisioning#use-job"
              target="_blank">in this article.</a></p>
            <p>Note that these pods are still chargeable, but they will be terminated as soon as the runner pods
              start.</p>
            <mat-slide-toggle formControlName="preprovision">Enable pre-provisioning?</mat-slide-toggle>

          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="gcpLoggingCommands">
            <mat-expansion-panel-header>
              <mat-panel-title>
                GCP Logging
              </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="logging-instructions" >

              <p>The following instructions will create two named <a
                href="https://console.cloud.google.com/logs/query" target="_blank">Cloud
                Logging</a> loggers: <strong>cykubed-agent</strong> and <strong>cykubed-runner</strong> for the agent
                and runners respectively</p>

              <p>The instructions assume that you have <a
                href="https://cloud.google.com/kubernetes-engine/docs/how-to/workload-identity">Workload Identity</a>
                enabled for your GKE cluster. This is enabled by default for Autopilot clusters.</p>

              <p *ngIf="isProjectSet">
                You can create a service account and bind it to the Cykubed Kubernetes service account from the
                command line with the following snippet. You should wait a few minutes after applying this before
                installing the agent.

                <app-code-snippet [value]="gcpLoggingCommands"></app-code-snippet>
              </p>
            </section>
          </mat-expansion-panel>

        </mat-accordion>



      </div>
  </form>

  <ng-container *ngIf="showProjectButton">
    <p>Now create a project to start running tests:</p>
  </ng-container>

  <div class="button-toolbar" [class.show-create-project]="showProjectButton">
    <button *ngIf="showProjectButton" type="button" color="accent" mat-raised-button
            [routerLink]="['/main/settings/projects/new']">Create
      Project
    </button>

    <button mat-raised-button color="warn" (click)="showDeleteConfirm()" type="button">
      <mat-icon>delete</mat-icon>
      <span>Delete agent</span>
    </button>

  </div>

</div>


<ng-template #confirmDeleteRef>
  <h1 mat-dialog-title>Delete agent?</h1>
  <mat-dialog-content>
    <p>This will delete the reference to the agent in our servers, so it can no longer be used to perform tests.</p>
    <p>This will <strong>not</strong> delete it from your cluster. If you have already installed the agent, you'll need
      to delete it with the following Helm command:</p>

    <app-code-snippet [value]="deleteCommand" class="full-width"></app-code-snippet>

  </mat-dialog-content>
  <div mat-dialog-actions>
    <div class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>No</button>
      <button mat-raised-button mat-dialog-close color="warn" (click)="delete()">Delete</button>
    </div>
  </div>
</ng-template>

<ng-template #disabled><span class="strong">DISABLED</span></ng-template>
<ng-template #no>NO</ng-template>
