<div class="page">

  <div class="logo">
    <a href="/">
      <img src="/assets/img/logos/Original.svg" >
    </a>
  </div>


  <h1>Temporary Connection Error</h1>

  <a href="/">
    <img ngSrc="/assets/img/dead-parrot.png" width="350" height="312" priority>
  </a>

  <div class="text">
    <p>Sorry: it looks like we can't contact the main API servers.</p>
    <p>We'll keep trying - hang tight....</p>

  </div>

</div>

