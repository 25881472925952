import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {UserProfile} from '@models/generated.model';
import {UntilDestroy} from '@ngneat/until-destroy';
import {NavItem} from '@models/nav';


@UntilDestroy()
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  profile: UserProfile;
  navItems: NavItem[] = [
    { path: 'account', label: 'Account'},
    { path: 'projects', label: 'Projects'},
    { path: 'users', label: 'Users'},
    { path: 'notifications', label: 'Notifications'},
    { path: 'webhooks', label: 'Webhooks'},
    { path: 'api', label: 'API'},
    { path: 'integrations', label: 'Integrations'}
  ]

  constructor(private authService: AuthenticationService) {
    this.profile = this.authService.profile;
  }

  ngOnInit() {
    if (this.profile.is_staff) {
      this.navItems.push({path: 'admin', label: 'Admin'});
    }

    this.authService.currentOrganisation$.subscribe(org => {
      if (org.prefer_self_host) {
        this.navItems.splice(0, 0, { path: 'agents', label: 'Agents'});
      } else {
        if (this.navItems[0].path === 'agents') {
          this.navItems.splice(0, 1);
        }
      }
    });
  }
}
