import {Component, OnInit} from '@angular/core';
import {WebhooksService} from '@services/api-services/webhooks.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TriggerableModel} from '@models/triggerable.model';
import {WebHook} from '@models/generated.model';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '@environment';

@UntilDestroy()
@Component({
  selector: 'app-webhooks-table',
  templateUrl: './webhooks-table.component.html',
  styleUrls: ['./webhooks-table.component.scss']
})
export class WebhooksTableComponent implements OnInit {
  webhooks: WebHook[];
  fetched = false;
  apiUrl: string;

  constructor(private webhookService: WebhooksService,
              private router: Router,
              private route: ActivatedRoute) {
    this.apiUrl = environment.server;
  }

  ngOnInit(): void {
     this.webhookService.webhooks$.pipe(untilDestroyed(this)).subscribe(items => {
      this.webhooks = items;
      this.fetched = true;
    });
  }

  get triggerables(): TriggerableModel[] {
    return this.webhooks as TriggerableModel[];
  }

  async selected(wh: WebHook) {
    await this.router.navigate(['./edit', wh.id], {relativeTo: this.route})
  }

}
