import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {AgentListComponent} from './agent-list.component';
import {AgentsComponent} from './agents.component';
import {AgentIndicatorComponent} from './agent-indicator/agent-indicator.component';
import {EditAgentComponent} from './edit-agent/edit-agent.component';
import {WidgetsModule} from '../../widgets/widgets.module';


@NgModule({
  declarations: [
    AgentListComponent,
    AgentsComponent,
    AgentIndicatorComponent,
    EditAgentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    WidgetsModule
  ]
})
export class AgentsModule { }
