import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersTableComponent} from './users-table/users-table.component';
import {InviteUsersComponent} from './invite-users/invite-users.component';
import {UsersComponent} from './users.component';
import {SharedModule} from '../../shared/shared.module';


@NgModule({
  declarations: [
    UsersTableComponent,
    InviteUsersComponent,
    UsersComponent,
    InviteUsersComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class UsersModule { }
