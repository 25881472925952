import {NgModule} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {CardComponent} from './card/card.component';
import {CodeSnippetComponent} from './code-snippet/code-snippet.component';
import {CopyTextButtonComponent} from './copy-text-button/copy-text-button.component';
import {DelayedSpinnerComponent} from './delayed-spinner/delayed-spinner.component';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {LogoSpinnerComponent} from './logo-spinner/logo-spinner.component';
import {SaveButtonComponent} from './save-button/save-button.component';
import {SpecStatusBadgeComponent} from './spec-status/spec-status-badge.component';
import {HighlightModule} from 'ngx-highlightjs';
import {ScreenshotsComponent} from './screenshots/screenshots.component';
import {PlatformLogoComponent} from './platform-logo/platform-logo.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../shared/material.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {PlatformNameComponent} from './platform-name/platform-name.component';
import {IconToggleButtonComponent} from './toggle-button/icon-toggle-button.component';
import {DotPagerComponent} from './dot-pager/dot-pager.component';
import {NgOptimizedImage} from '@angular/common';
import {CircleProgressComponent} from './circle-progress/circle-progress.component';
import {CircleUsageComponent} from './circle-usage/circle-usage.component';
import {InfoIconComponent} from './info-icon/info-icon.component';
import {DropdownsModule} from '../dropdowns/dropdowns.module';


@NgModule({
  declarations: [
    CardComponent,
    CodeSnippetComponent,
    CopyTextButtonComponent,
    DelayedSpinnerComponent,
    ErrorDialogComponent,
    LogoSpinnerComponent,
    SaveButtonComponent,
    ScreenshotsComponent,
    PlatformLogoComponent,
    SpecStatusBadgeComponent,
    BreadcrumbsComponent,
    PlatformNameComponent,
    IconToggleButtonComponent,
    DotPagerComponent,
    CircleProgressComponent,
    CircleUsageComponent,
    InfoIconComponent
  ],
  imports: [
    SharedModule,
    MaterialModule,
    ReactiveFormsModule,
    HighlightModule,
    FontAwesomeModule,
    BreadcrumbModule,
    DropdownsModule,
    NgOptimizedImage
  ],
  exports: [
    CardComponent,
    DropdownsModule,
    CodeSnippetComponent,
    CopyTextButtonComponent,
    DelayedSpinnerComponent,
    ErrorDialogComponent,
    LogoSpinnerComponent,
    SaveButtonComponent,
    ScreenshotsComponent,
    InfoIconComponent,
    PlatformLogoComponent,
    SpecStatusBadgeComponent,
    ReactiveFormsModule,
    MaterialModule,
    FontAwesomeModule,
    BreadcrumbsComponent,
    PlatformNameComponent,
    IconToggleButtonComponent,
    CircleProgressComponent,
    CircleUsageComponent
  ]
})
export class WidgetsModule { }
