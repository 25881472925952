import {Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable, switchMap} from 'rxjs';
import {AuthorModel} from '@models/generated.model';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '@services/api-services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorService {

  _authors = new BehaviorSubject<AuthorModel[]>([]);

  constructor(private http: HttpClient,
              private authService: AuthenticationService) {

    authService.currentOrganisation$.pipe(switchMap( () => this.list())).subscribe(
      authors => {
        this._authors.next(authors);
      }
    );
  }

  get authors$(): Observable<AuthorModel[]> {
      return this._authors.asObservable();
  }

  list(search?: string): Observable<AuthorModel[]> {
    let params = this.authService.currentOrganisationHttpParam;
    if (search) {
      params = params.set('search', search);
    }
    return this.http.get<AuthorModel[]>('/author', {params});
  }

  getAuthorByEmail(email: string): Observable<AuthorModel | null> {
    let params = this.authService.currentOrganisationHttpParam;
    params = params.set('email', email);
    return this.http.get<AuthorModel[]>('/author', {params}).pipe(
      // there will be 1 or 0
      map(authors => (authors)?authors[0]:null)
    )
  }

}
