import {Component, OnInit} from '@angular/core';
import {IconsService} from '@services/ui-services/icon.service';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Router} from '@angular/router';
import {IntegrationsService} from '@services/api-services/integrations.service';


@UntilDestroy()
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  constructor(public icons: IconsService,
              private integrationService: IntegrationsService,
              private router: Router,
              private authService: AuthenticationService) { }

  ngOnInit(): void {

  }

  githubSignup() {
    this.integrationService.authorizeGithub();
  }

  bitbucketSignup() {
    this.integrationService.authorizeBitbucket();
  }

  gitlabSignup() {
    return this.integrationService.authorizeGitLab();
  }


}
