import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {IconsService} from '@services/ui-services/icon.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-trigger-type',
  templateUrl: './trigger-type.component.html',
  styleUrls: ['./trigger-type.component.scss']
})
export class TriggerTypeComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() showHint: boolean = false;

  constructor(public icons: IconsService) {

  }

  ngOnInit(): void {

    const validatorFn: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
      const v = control.value;
      return (!v.on_fixed && !v.on_pass && !v.on_fail && !v.on_flake) ? {noTriggers: true}: null;
    };

    this.form.addValidators([validatorFn]);

    this.form.controls['on_pass'].valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
      if (v) {
        this.form.patchValue({'on_fixed': true});
        this.form.controls['on_fixed'].disable();
      } else {
        this.form.controls['on_fixed'].enable();
      }
    });

  }
}
