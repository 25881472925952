import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {ChooseHostedComponent} from './choose-hosted/choose-hosted.component';
import {ChooseNotificationComponent} from './choose-notification/choose-notification.component';
import {NotificationsModule} from '../notifications/notifications.module';
import {ChooseOrgNameComponent} from './choose-org-name/choose-org-name.component';
import {OrgNameHeadingComponent} from './org-name-heading/org-name-heading.component';
import {OnboardingComponent} from './onboarding.component';
import {OnboardingChoosePlanComponent} from './choose-plan/onboarding-choose-plan.component';
import {AccountModule} from '../account/account.module';
import {IntegrationsModule} from '../integrations/integrations.module';


@NgModule({
  declarations: [
    ChooseHostedComponent,
    ChooseNotificationComponent,
    ChooseOrgNameComponent,
    OnboardingChoosePlanComponent,
    OrgNameHeadingComponent,
    OnboardingComponent
  ],
  imports: [
    CommonModule,
    AccountModule,
    IntegrationsModule,
    SharedModule,
    NotificationsModule
  ]
})
export class OnboardingModule { }
