import {PlatformEnum} from '@models/generated.model';
import {PlanDescription} from '@models/plans';
import {pluck} from 'underscore';

export const gitPlatforms = ['github', 'bitbucket'];

export const NotificationPlatforms: PlatformEnum[] = ['slack', 'rocketchat'];

export const PageSizeOptions = [10, 50, 100];

export const PlanOrder: PlanDescription[] = [
  {
    name: 'Free',
    description: 'Try Cykubed for free'
  }, {
    name: 'Starter',
    description: 'For small teams and projects'
  }, {
    name: 'Team',
    description: 'For growing teams wishing to test at scale'
  }, {
    name: 'Scale',
    description: 'For large teams with many users and projects'
  }
]

export const Plans: string[] = pluck(PlanOrder, 'name');
