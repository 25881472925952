<h2>{{ user.name }}</h2>

<button mat-raised-button color="accent" *ngIf="profile.id !== user.id" type="button" (click)="impersonate()">Impersonate</button>

<dl class="info-list">

  <div>
    <dt>ID</dt>
    <dd>{{ user.id }}</dd>
  </div>
  <div>
    <dt>Email</dt>
    <dd>{{ user.email }}</dd>
  </div>
  <div>
    <dt>Joined</dt>
    <dd>{{ user.created | date }}</dd>
  </div>
</dl>

<div class="organisations">
  <mat-tab-group *ngIf="orgs?.length" mat-stretch-tabs="false" animationDuration="0ms" >
    <mat-tab *ngFor="let org of orgs">
      <ng-template mat-tab-label>
        <span class="icon-and-name"><fa-icon [icon]="icons.organisation"></fa-icon> {{ org.name }}</span>
      </ng-template>
      <app-admin-org-details [org]="org" [showName]="false"></app-admin-org-details>
    </mat-tab>
  </mat-tab-group>
</div>

