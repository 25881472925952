import {Component, TemplateRef, ViewChild} from '@angular/core';
import {Project} from '@models/generated.model';
import {ActivatedRoute, Router} from '@angular/router';
import {ProjectService} from '@services/api-services/project.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {IconDefinition} from '@fortawesome/free-brands-svg-icons';
import {IconsService} from '@services/ui-services/icon.service';
import {BreadcrumbService} from 'xng-breadcrumb';

@UntilDestroy()
@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent {

  @ViewChild('deleteConfirm', { static: true }) deleteConfirm: TemplateRef<any>;

  project: Project;
  activeLink = 'config';

  constructor(private router: Router,
              private projectService: ProjectService,
              private errorDialogService: ErrorDialogService,
              private snackBar: MatSnackBar,
              public dialog: MatDialog,
              public icons: IconsService,
              private breadcrumbService: BreadcrumbService,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.project = this.activatedRoute.snapshot.data['project'];
    this.breadcrumbService.set('@projectName', this.project.name);
  }


  deleteProject() {
    this.dialog.closeAll();
    this.projectService.deleteProject(this.project!).subscribe({
      next: async () => {
        this.snackBar.open('Project deleted', "OK", {duration: 2000});
        await this.router.navigate(['/'])
      },
      error: (msg) => {
        this.errorDialogService.show('Failed to delete project', msg.detail);
      }
    });
  }

  showConfirmDelete() {
    this.dialog.open(this.deleteConfirm);
  }


  get platformIcon(): IconDefinition {
    return this.icons.platform(this.project!.platform);
  }

  saved($event: Project) {
    
  }
}
