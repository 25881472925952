<div class="indicator row-centered">
  <ng-container *ngIf="agent.connected">
    <fa-icon [icon]="icons.connected" size="2x" class="success"></fa-icon>
    <div *ngIf="showText" class="text">
      <h3>Agent connected</h3>
      <span class="mat-body-2">v {{ agent.version }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="!agent.connected">
    <fa-icon [icon]="icons.disconnected" size="2x" class="warn"></fa-icon>
    <h3 *ngIf="showText">Agent not connected</h3>
  </ng-container>
</div>
