import {Component, Input} from '@angular/core';
import {IconsService} from '../../../services/ui-services/icon.service';

@Component({
  selector: 'app-logo-spinner',
  templateUrl: './logo-spinner.component.html',
  styleUrls: ['./logo-spinner.component.scss']
})
export class LogoSpinnerComponent {

  @Input() label: string;

  constructor(public icons: IconsService) {

  }

}
