export const environment = {
  production: true,
  webUrl: 'https://app.cykubed.com',
  websocket: 'wss://api.cykubed.com/ws',
  server: 'https://api.cykubed.com',
  googleClientId: "489847359241-ahj3tp1295od01k3s4k2057cutm4oh0h.apps.googleusercontent.com",
  sentryDsn: "",
  googleMapsApi: 'AIzaSyAf5ELWOy8cLIj5zLlz0kfmSdk4XfMiDhs',
  ipLookupApiKey: 'OBNYzAhxqx4ANbOLuPCc',
  bitbucketClientId: 'sL3DN3q9faYQwdUqu9',
  githubClientId: "Iv1.efb14a271fa9d0e5",
  gitlabClientId: "a9dcdc970fe08bca465bc8dd3ecf810a645bc96a0f6c4a294e784b069ce4dc0e",
  jiraClientId: "hyo9h8EmNMffHJqPwq48ahWdfPTSEqzn",
  slackClientId: "4589118879090.4922826035888",
  stripeKey: "pk_live_51NVehpFMROOnH13K8wd94QP9keszDsAj1nEolxbBrMvrPFqlmcmVAth1vpusOzWYhNJsvjrc44IaF2CQ5mINYlB4002ouVKz5i"
};
