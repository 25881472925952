import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AgentModel, Project, Repository, UserProfile} from '@models/generated.model';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {map, Observable} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IconsService} from '@services/ui-services/icon.service';
import {ProjectService} from '@services/api-services/project.service';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {AgentService} from '@services/api-services/agent.service';
import {TimezoneService} from '@services/ui-services/timezone.service';
import {setRequiredValidator} from '@lib/form-utils';

@UntilDestroy()
@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})
export class ProjectFormComponent implements OnInit {

  @Input() stepped = false;
  @Input() expanded = false;
  @Input() repos: Repository;
  @Input() project?: Project;
  @Output() saved = new EventEmitter<Project>();

  form: FormGroup;
  newProject: boolean;
  timeZones: string[];
  filteredTimezones$: Observable<string[]>;
  saving = false;
  frameworkChecked = false;

  cpuOptions = ['1', '1.5', '2', '2.5', '3', '3.5', '4']
  memoryOptions = ['1', '2', '3', '4', '5', '6', '7', '8']
  storageOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14']

  user: UserProfile;

  agents: AgentModel[] = [];
  singleAgent = true;

  browsers: string[] = ['Electron', 'Chrome', 'Edge', 'Firefox'];

  step: number;

  frameworks = ['angular', 'vue', 'next.js', 'generic']
  maxRunners = 10;


  constructor(private authService: AuthenticationService,
              public icons: IconsService,
              private projectService: ProjectService,
              private router: Router,
              public dialog: MatDialog,
              private agentService: AgentService,
              private errorDialogService: ErrorDialogService,
              private snackBar: MatSnackBar,
              private timezoneService: TimezoneService) {
    this.timeZones = timezoneService.timezones;
    this.user = this.authService.profile;
  }

  ngOnInit(): void {
    const form = this.form = this.projectService.projectForm(this.project, this.repos);

    form.controls['app_framework'].valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
      const value = form.value;
      form.patchValue({server_cmd: null, build_cmd: null, server_port: null});
      if (v === 'angular') {
        form.patchValue({build_cmd: 'ng build --output-path=dist', server_port: '4200'})
      }
      if (v === 'next.js') {
        form.patchValue({server_cmd: 'next dev', server_port: '3000'});
      }

      if (this.form.valid && !this.project) {
          this.form.markAsDirty();
      }
    });

    form.controls['server_cmd'].valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
      setRequiredValidator(!!v, form.controls['server_port']);
    });

    if (this.authService.currentOrganisation?.prefer_self_host) {
      this.maxRunners = 20;
    } else {
      this.maxRunners = this.authService.profile.account?.subscription.plan.max_parallelism || 4;
    }


    if (!this.frameworkChecked &&
      !this.project?.app_framework && !this.project?.test_framework)
    {
      this.projectService.detectFrameworks(this.repos).subscribe(fr => {
        this.frameworkChecked = true;
        this.form.patchValue(fr);
        if (!this.form.value.server_port && fr.app_framework === 'angular') {
          this.form.patchValue({'server_port': '4200'});
        }
      });
    } else {
      this.frameworkChecked = true;
    }

    if (this.authService.currentOrganisation?.prefer_self_host) {
      this.agentService.agents$.pipe(untilDestroyed(this)).subscribe(agents => {
        this.agents = agents;
        if (agents?.length > 1) {
          this.singleAgent = false;
        }
        if (agents?.length === 1) {
          this.form.patchValue({agent_id: agents[0].id});
          this.singleAgent = true;
        }
      });
    } else {
      this.form.patchValue({'agent_id': undefined});
    }

    this.filteredTimezones$ = this.form.controls['timezone'].valueChanges.pipe(
      untilDestroyed(this),
      map(v => this.timezoneService.getFiltered(v))
    );

    if (!this.project) {
      this.step = 0;
      this.newProject = true;
    }

    if (this.form.valid && !this.project) {
      this.form.markAsDirty();
    }
  }

  get builderValid(): boolean {
    const value = this.form.value;
    // Must either have a build command OR a server command (or both).
    // If there is a server command then we must have a port
    return (value.build_cmd?.length > 2 || (value.server_cmd && value.server_port));
  }

  get runnerValid(): boolean {
    const value = this.form.value;
    return (!!value.runner_cpu && !!value.runner_memory && !!value.parallelism);
  }

  get isCypress(): boolean {
    return this.form.controls['test_framework'].value === 'cypress';
  }

  setStep(number: number) {
    this.step = number;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  save() {
    let value = this.form.getRawValue();
    this.saving = true;
    if (this.project) {
      value = {...this.project, ...value};
      this.projectService.updateProject(value).subscribe({
        next: (project) => {
          this.snackBar.open('Project updated', "OK", {duration: 2000});
          this.saving = false;
          this.saved.next(project);
          this.form.markAsPristine();
        },
        error: (err) => {
          this.saving = false;
          this.errorDialogService.show("Failed to save project", err.message);
        }
      });
    } else {
      // force bool
      value.notify_on_passed = !!value.notify_on_passed;
      value.spot_enabled = !!value.spot_enabled;

      this.projectService.createProject({
        platform_id: this.repos.id,
        repos: this.repos.full_path || this.repos!.name,
        url: this.repos!.url,
        organisation_id: this.authService.currentOrganisationId,
        owner: this.repos.owner,
        platform: this.repos!.platform,
        default_branch: this.repos!.default_branch,
        ...value
      }).subscribe({
        next: async createdProject => {
          this.saving = false;
          this.snackBar.open("Project saved", "OK", {duration: 2000});

          await this.router.navigate(['/main/dashboard',
            {project: createdProject.name}]);
        },
        error: err => {
          // TODO inform the user
          this.saving = false;
          this.errorDialogService.show("Failed to create project", err.message);
        }
      });
    }
  }

  setBrowsers(browsers: string[]) {
    this.browsers = browsers;
  }
}
