import {Component, OnInit} from '@angular/core';
import {AdminService, OrganisationFilter} from '@services/api-services/admin.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, distinctUntilChanged} from 'rxjs';
import {AdminOrganisation, AdminOrganisationList} from '@models/generated.model';
import {PageEvent} from '@angular/material/paginator';
import {UiSessionSettingsService} from '@services/ui-session-settings.service';
import {PageSizeOptions, Plans} from '@models/consts';
import {IconsService} from '@services/ui-services/icon.service';
import {MatDialog} from '@angular/material/dialog';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {MatSelectionListChange} from '@angular/material/list';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthenticationService} from '@services/api-services/authentication.service';

@UntilDestroy()
@Component({
  selector: 'app-admin-organisations',
  templateUrl: './admin-organisations.component.html',
  styleUrls: ['./admin-organisations.component.scss']
})
export class AdminOrganisationsComponent implements OnInit {
  filter: OrganisationFilter = {page: 0, pagesize: 50}
  searchControl = new FormControl();
  orgList: AdminOrganisationList = {...this.filter, total: 0, items: []};
  pageSizeOptions = PageSizeOptions;
  selectedOrg: AdminOrganisation;
  selectedOrganisations: AdminOrganisation[];
  plans = Plans;

  constructor(private adminService: AdminService,
              public icons: IconsService,
              authService: AuthenticationService,
              fb: FormBuilder,
              private errorDialogService: ErrorDialogService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private uiSettings: UiSessionSettingsService) {
    this.filter.pagesize = uiSettings.pageSize;
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      untilDestroyed(this),
      debounceTime(100),
      distinctUntilChanged()
    ).subscribe(v => {
      this.filter.search = v;
      this.fetch();
    });

    this.fetch();
  }

  private fetch() {
    this.adminService.listOrganisations(this.filter).subscribe(result => {
      this.orgList = result;
    });
  }

  async pageChanged(e: PageEvent) {
    if (e.pageSize !== this.filter.pagesize) {
      this.uiSettings.pageSize = this.filter.pagesize = e.pageSize;
    }
    this.filter.page = e.pageIndex;
    this.fetch();
  }

  orgChanged(org: AdminOrganisation) {
    const idx = this.orgList.items.findIndex(o => o.id === org.id);
    this.orgList.items[idx] = org;
  }

  select(org: AdminOrganisation) {
    this.selectedOrg = org;
    this.selectedOrganisations = [org];
  }

  selected(event: MatSelectionListChange) {
    this.select(event.options[0].value);
  }

}
