import {Component, Input} from '@angular/core';
import {IconsService} from '@services/ui-services/icon.service';
import {AgentModel} from '@models/generated.model';

@Component({
  selector: 'app-agent-indicator',
  templateUrl: './agent-indicator.component.html',
  styleUrls: ['./agent-indicator.component.scss']
})
export class AgentIndicatorComponent {

  @Input() agent: AgentModel;
  @Input() showText: boolean = false;


  constructor(public icons: IconsService) {

  }

}
