<form class="vertical-form" [formGroup]="form" *ngIf="form">

  <mat-form-field appearance="fill" class="name-field">
    <mat-label>Name</mat-label>
    <input matInput formControlName="name">
    <mat-hint>Webhook name</mat-hint>
    <mat-error *ngIf="form.controls['name'].hasError('required')">This field is required</mat-error>
  </mat-form-field>

  <app-trigger-type [form]="form" ></app-trigger-type>

  <div class="row-centered project">
    <app-project-select data-testid="project-select"
                        formControlName="project_id" placeholder="All projects"></app-project-select>
    <app-info-icon tooltip="Optionally restrict this notification to a single project"></app-info-icon>
  </div>

  <mat-form-field appearance="fill" class="branch-field">
    <mat-label>Branch regex</mat-label>
    <input matInput formControlName="branch_regex">
    <mat-hint>Branches matching this regex will trigger</mat-hint>
    <mat-error *ngIf="form.controls['branch_regex'].hasError('required')">This field is required</mat-error>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>URL</mat-label>
    <input matInput formControlName="url">
    <mat-hint>Webhook payloads are POSTed to this URL</mat-hint>
    <mat-error *ngIf="form.controls['url'].hasError('required')">This field is required</mat-error>
    <mat-error *ngIf="form.controls['url'].hasError('invalidUrl')">Invalid URL format</mat-error>
  </mat-form-field>

  <div class="end-button-row">

    <button mat-raised-button (click)="cancel()">Cancel</button>

    <button mat-raised-button *ngIf="webhook" [routerLink]="['../../history', webhook.id]" matTooltip="View all events for this webhook">View history</button>

    <app-save-button [label]="(webhook===undefined)?'Create webhook':'Save changes'"
                     [form]="form" [saving]="saving" (savedClicked)="save()"></app-save-button>

    <span class="flex-spacer"></span>

    <button mat-raised-button color="warn" (click)="deleteConfirm()" *ngIf="webhook?.id">Delete</button>

  </div>

</form>

<ng-template #confirmDeleteRef>
  <h1 mat-dialog-title>Delete webhook?</h1>
  <div mat-dialog-actions>
    <div  class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>No</button>
      <button mat-raised-button mat-dialog-close color="warn" (click)="delete()">Delete</button>
    </div>
  </div>
</ng-template>
