import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AccountService} from '@services/api-services/account.service';

@Component({
  selector: 'app-delete-org-message',
  templateUrl: './organisation-deleted-message.component.html',
  styleUrls: ['./organisation-deleted-message.component.scss']
})
export class OrganisationDeletedMessage {
  form: FormGroup;
  submitted = false;

  constructor(private fb: FormBuilder,
              private accountService: AccountService,
              private router: Router) {

    this.form = fb.group({
      reason: ['', Validators.required],
      message: ['']
    });
  }

  submit() {
    this.accountService.orgDeleteReason({
      ...this.form.value, token: this.accountService.leavingToken
    }).subscribe(async () => {
      this.submitted = true;
    });
  }
}
