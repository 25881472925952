<div class="thumbnails">

    <div class="image-container" *ngFor="let img of images; let idx=index">
      <img (click)="onClick(idx)"  [src]="img"
        alt="Screenshot">
    </div>

</div>

<!-- Actually this is silly: the lightbox is just the same but large. Use the same component inside and outside the dialog -->
<ng-template #lightbox>
  <div class="lightbox">
    <div class="image-container">
      <ng-container *ngFor="let img of images">
        <img *ngIf="img === current"
             [ngSrc]="img" fill priority
          alt="Screenshot"
        >
      </ng-container>
    </div>

    <div class="left-button">
      <button mat-icon-button (click)="prev()" [disabled]="!hasPrev">
        <fa-icon [icon]="icons.left"></fa-icon>
      </button>
    </div>

    <div class="right-button">
      <button mat-icon-button (click)="next()" [disabled]="!hasNext">
        <fa-icon [icon]="icons.right"></fa-icon>
      </button>
    </div>

    <div class="close-button">
      <button mat-icon-button (click)="closeDialog()">
        <fa-icon [icon]="icons.close"></fa-icon>
      </button>
    </div>

    <app-dot-pager [total]="images.length"
                   [current]="currentIndex"
                   (clicked)="imageSelected($event)"></app-dot-pager>
  </div>
</ng-template>
