import {Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IconDefinition} from '@fortawesome/free-brands-svg-icons';
import {faChevronDown, faChevronRight, faLock, faTerminal, faUnlock} from '@fortawesome/free-solid-svg-icons';
import {of, Subscription, switchMap, takeWhile} from 'rxjs';
import {AppLogMessage} from '@models/generated.model';
import {WebsocketService} from '@services/api-services/websocket.service';
import {IconsService} from '@services/ui-services/icon.service';
import {TestRunService} from '@services/api-services/test-run.service';
import {FormControl} from '@angular/forms';
import {UiSessionSettingsService} from '@services/ui-session-settings.service';

@UntilDestroy()
@Component({
  selector: 'app-build-log',
  templateUrl: './build-log.component.html',
  styleUrls: ['./build-log.component.scss']
})
export class BuildLogComponent implements OnInit, OnChanges {

  locked = false;
  logs = new Array<AppLogMessage>();
  firstLoad = true;
  sub: Subscription;
  logIcons = {
    debug: this.icons.info,
    info: this.icons.info,
    cmd: faTerminal,
    cmdout: faTerminal,
    warning: this.icons.error,
    error: this.icons.error,
    critical: this.icons.error
  }
  showHostControl: FormControl;
  openIcon = faChevronDown;
  closedIcon = faChevronRight;
  closedSteps = new Set<number>();

  @Input() testrunId: number;
  @ViewChild('last') lastEl!: ElementRef;

  constructor(private testrunService: TestRunService,
              private socket: WebsocketService,
              private uiSettings: UiSessionSettingsService,
              public icons: IconsService) {
    this.showHostControl = new FormControl(this.uiSettings.showHostsInBuildLog);
  }

  ngOnInit(): void {

    this.showHostControl.setValue(this.uiSettings.showHostsInBuildLog);
    this.showHostControl.valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
      this.uiSettings.showHostsInBuildLog = v;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['testrunId'].currentValue) {
      this.logs.length = 0;
    } else {
      const trid = changes['testrunId'];
      if (trid.firstChange || (trid.currentValue !== trid.previousValue)) {
        this.logs.length = 0;
        this.startLogFetch(trid.currentValue);
      }
    }
  }

  isStepClosed(msg: AppLogMessage): boolean {
    return (!!msg.step && this.closedSteps.has(msg.step));
  }

  closeStep(msg: AppLogMessage) {
    if (!!msg.step) {
      this.closedSteps.add(msg.step);
    }
  }

  openStep(msg: AppLogMessage) {
    if (!!msg.step) {
      this.closedSteps.delete(msg.step);
    }
  }

  showLogLine(msg: AppLogMessage): boolean {
    return msg.level !== 'cmdout' || !this.isStepClosed(msg);
  }

  get lockIcon(): IconDefinition {
    return (this.locked) ? faLock : faUnlock;
  }

  get showLockIcon(): boolean {
    return this.logs.length > 10;
  }

  private startLogFetch(testrunId: number) {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.testrunService.getBuildLogs(testrunId).pipe(
      switchMap(logs => {
        this.logs = logs;
        return this.socket.getBuildLogs$(testrunId);
      }),
      takeWhile(() => this.testrunId === testrunId),
      switchMap(logupdate => {
        if (this.logs.length + 1 === logupdate.line_num) {
          // incremental update
          this.logs.push(logupdate.msg);
          this.scroll();
          return of(null);
        } else {
          // we've got gaps: refetch everything
          return this.testrunService.getBuildLogs(this.testrunId);
        }
      })
    ).subscribe(fulllogs => {
      if (fulllogs) {
        this.logs = fulllogs;
      }
    });
  }

  private scroll() {
   if (!this.locked) {
     setTimeout(() => {
       this.lastEl.nativeElement.scrollIntoView({block: "start"})
     }, 0);
    }
  }

  toggleScrollLock() {
    this.locked = !this.locked;
  }

}
