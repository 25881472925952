<div class="page">

  <h1>Welcome to Cykubed</h1>

  <div class="main-contents">

    <router-outlet></router-outlet>

  </div>

</div>
