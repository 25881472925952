import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CommonTriggerModel, NewWebHook, WebHook, WebhookHistory} from '../../models/generated.model';
import {BehaviorSubject, filter, map, Observable, switchMap, take, tap} from 'rxjs';
import {AuthenticationService} from '@services/api-services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class WebhooksService {
  private _webhooks = new BehaviorSubject<WebHook[]>([]);

  constructor(private http: HttpClient,
              private authService: AuthenticationService) {

     authService.currentOrganisation$.pipe(switchMap( () => this.list())).subscribe(
      webhooks => {
        this._webhooks.next(webhooks);
      }
    );
  }

  get webhooks$(): Observable<WebHook[]> {
    return this._webhooks.asObservable();
  }

  get webhooks(): WebHook[] {
    return this._webhooks.value;
  }

  get$(id: number): Observable<WebHook> {
    return this.webhooks$.pipe(
      map(items  => items.find(n => n.id === id)),
      filter(n => !!n),
      take(1),
      map(n => n as WebHook)
    )
  }

  create(webhook: NewWebHook): Observable<WebHook> {
    return this.http.post<WebHook>(`/webhook`, webhook).pipe(
      tap( (created) => {
        this._webhooks.value.push(created);
        this.notify();
      })
    );
  }

  private notify() {
    this._webhooks.next(this.webhooks);
  }

  getTriggerList(trigger: CommonTriggerModel): string[] {
    let triggers: string[] = [];
    if (trigger?.on_pass) {
      triggers.push('passed');
    }
    if (trigger?.on_fixed) {
      triggers.push('fixed');
    }
    if (trigger?.on_fail) {
      triggers.push('failed');
    }
    if (trigger?.on_flake) {
      triggers.push('flakes')
    }
    return triggers;
  }

   getTriggerSummary(notification: CommonTriggerModel): string  {
    const triggers = this.getTriggerList(notification);
    if (triggers.length == 4) {
      return 'every run';
    }
    return triggers.join(', ');
  }

  update(webhook: WebHook): Observable<WebHook> {
    return this.http.put<WebHook>(`/webhook/${webhook.id}`, webhook).pipe(
      tap( (created) => {
        const idx = this.webhooks.findIndex(n => n.id === webhook.id);
        this.webhooks[idx] = created;
        this.notify();
      })
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete<any>(`/webhook/${id}`).pipe(
      tap( () => {
        const idx = this.webhooks.findIndex(n => n.id === id);
        this.webhooks.splice(idx, 1);
        this.notify();
      }));
  }

  get(webhook_id: number): Observable<WebHook> {
    return this.http.get<WebHook>(`/webhook/${webhook_id}`);
  }

  list(project_id?: number): Observable<WebHook[]> {
    let params = new HttpParams();
    if (project_id) {
      params = params.set('project_id', project_id.toString());
    }
    return this.http.get<WebHook[]>(`/webhook`, {params});
  }

  getHistory(webhook_id: number): Observable<WebhookHistory[]> {
    return this.http.get<WebhookHistory[]>(`/webhook/${webhook_id}/history`);
  }
}
