import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {AgentService} from '@services/api-services/agent.service';
import {AgentModel, UserOrganisationSummary, UserProfile} from '@models/generated.model';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {environment} from '@environment';
import semver from 'semver/preload';
import {MatDialog} from '@angular/material/dialog';
import {ProjectService} from '@services/api-services/project.service';
import {debounceTime, switchMap} from 'rxjs';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {AccountService} from '@services/api-services/account.service';
import {AdminService} from '@services/api-services/admin.service';
import {setRequiredValidator} from '@lib/form-utils';
import {IconsService} from '@services/ui-services/icon.service';

@UntilDestroy()
@Component({
  selector: 'app-edit-agent',
  templateUrl: './edit-agent.component.html',
  styleUrls: ['./edit-agent.component.scss']
})
export class EditAgentComponent implements OnInit {

  @ViewChild('confirmDeleteRef', { static: true }) confirmDeleteRef: TemplateRef<any>;

  form: FormGroup;
  installCommand?: string;
  deleteCommand = 'helm uninstall NAMESPACE -n NAMESPACE'
  minikubeInstall = 'minikube addons enable metrics-server\n' +
    'minikube addons enable volumesnapshots\n' +
    'minikube addons enable csi-hostpath-driver\n';
  baseInstall = 'helm install NAMESPACE cykubed/agent --create-namespace -n NAMESPACE';
  addAndUpdateRepo = 'helm repo add cykubed https://charts.cykubed.com\nhelm repo update\n';
  upgradeCommand?: string;
  agent?: AgentModel;
  latestVersion?: string;
  updateAvailable = false;
  gcpLoggingCommands: string = '';
  allowPreProvision = false;
  showProjectButton: boolean = false;
  org: UserOrganisationSummary;
  showProjectsSwitched = false;
  expandLoggingSection = false;
  profile: UserProfile;
  saved = false;
  rolloutCommand = "kubectl rollout status statefulsets -n cykubed"

  constructor(private agentService: AgentService,
              private adminService: AdminService,
              private snackbar: MatSnackBar,
              private matDialog: MatDialog,
              private projectService: ProjectService,
              private authService: AuthenticationService,
              private errorDialogService: ErrorDialogService,
              private accountService: AccountService,
              private route: ActivatedRoute,
              private router: Router,
              public icons: IconsService,
              private fb: FormBuilder) {
    this.profile = authService.profile;
    if (this.profile.is_staff) {
      this.allowPreProvision = true;
    }
  }

  ngOnInit(): void {
    this.org = this.authService.currentOrganisation!;
    const id = this.route.snapshot.params['id'];
    if (!id) {
      // create a new one
      this.agentService.createAgent().subscribe(agent => {
        this.setAgent(agent);
      })
    } else {
      this.agentService.getAgent(id).subscribe(agent => {
        this.setAgent(agent);
      })
    }
  }

  get isPublicAgent(): boolean {
    return !!this.form?.controls['is_public'].value;
  }

  get isReplicated(): boolean {
    return !!this.form?.controls['replicated'].value;
  }

  get isGCP(): boolean {
    return this.form.controls['platform'].value === 'gke';
  }

  get isSpotAvailable(): boolean {
    return ['gke', 'aks'].includes(this.platform);
  }

  get isPreprovisionEnabled(): boolean {
    return !!this.form.controls['preprovision'].value;
  }

  get isSpotEnabled(): boolean {
    return this.form.controls['spot_percentage'].value > 0;
  }

  get isCloudLoggingEnabled(): boolean {
    return this.form.controls['platform'].value === 'gke' && !!this.form.controls['platform_project_id'].value;
  }

  private checkForProjects() {
    if (this.agent?.connected) {
        this.expandLoggingSection = false;
        if (!this.org.prefer_self_host) {
          this.org.prefer_self_host = true;
          this.accountService.updateOrganisation({prefer_self_host: true}).subscribe();
        }
        this.showProjectButton = !this.projectService.projects?.length;
      }
  }

  private checkVersion() {
    this.updateAvailable = !!this.agent?.version && !!this.latestVersion &&
      semver.gt(this.latestVersion, this.agent.version);
  }

  private setAgent(agent: AgentModel) {
    this.agent = agent;

    this.form = this.fb.group({
      platform: [this.agent.platform, Validators.required],
      name: [this.agent.name, {validators: [Validators.required], updateOn: 'blur'}],
      service_account: [this.agent.service_account, Validators.email],
      namespace: [this.agent.namespace || "cykubed"],
      storage_class: [this.agent.storage_class],
      platform_project_id: [this.agent.platform_project_id, {updateOn: 'blur'}],
      preprovision: [this.agent.preprovision],
      spot_percentage: [this.agent.spot_percentage?.toString() || '0', {
                        disabled: !this.isSpotAvailable,
                        updateOn: 'blur'}]
    });

    const v = this.form.value;

    if (this.profile.is_staff) {
      this.form.addControl('is_public', new FormControl(this.agent.is_public));
    }

    this.adminService.getLatestAgentVersion().subscribe(v => {
      this.latestVersion = v;
      if (this.agent) {
        this.checkVersion();
      }
    });

    this.form.controls['platform'].valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
      this.platformChanged(v);
    });

    this.agentService.watchAgent$(this.agent.id).pipe(untilDestroyed(this)).subscribe(agent => {
      this.agent = agent;
      this.checkForProjects();
    });

    if (this.agent.version) {
      this.checkVersion();
    }

    this.platformChanged(this.platform);
    if (this.form.valid) {
      this.calcInstallCommands();
    }

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
      if (!this.form.pristine && !this.saved) {
        delete this.installCommand;
      }
    });

    if (this.agent?.first_connected) {
      this.form.controls['spot_percentage'].valueChanges.pipe(
        untilDestroyed(this),
        debounceTime(100),
        switchMap(v => this.agentService.update({...this.agent!, spot_percentage: v})))
        .subscribe(v => {
          this.agent = v;
          this.snackbar.open("Spot VM state updated", "OK", {duration: 1000});
      });

      const enabled = (v: boolean)  => (v)? 'enabled': 'disabled';

      this.form.controls['preprovision'].valueChanges.pipe(
        untilDestroyed(this),
        switchMap(v => this.agentService.update({...this.agent!, preprovision: v})))
        .subscribe(v => {
          this.agent = v;
          this.snackbar.open(`Pre-provision ${enabled(v.preprovision!)}`, "OK", {duration: 1000});
      });

      this.form.controls['is_public'].valueChanges.pipe(
        untilDestroyed(this),
        switchMap(v => this.agentService.update({...this.agent!, is_public: v})))
        .subscribe(v => {
          this.agent = v;
          const msg = v.is_public ? 'Agent is now public': 'Agent is now private';
          this.snackbar.open(msg, "OK", {duration: 1000});
      });
    }
  }

  saveInstallOptions() {
     const newagent = {...this.agent, ...this.form.value};
     this.agentService.update(newagent).subscribe(a => {
       this.setAgent(a);
       if (a.first_connected) {
         this.snackbar.open("Agent updated", "OK", {duration: 1000});
       }
     });
     this.calcInstallCommands();
  }

  private calcInstallCommands() {
    if (this.form.valid) {
      const value = this.form.value;
      this.checkForProjects();
      if (value.platform_project_id && value.platform === 'gke') {
        this.calcGcpLoggingCommands();
      }
      this.createInstallCommand(this.agent!);
      this.rolloutCommand = `kubectl rollout status statefulsets -n ${value.namespace}`;
      // for (const nm of ['namespace', 'storage_class', 'replicated']) {
      //   this.form.controls[nm].disable();
      // }
    }
  }

  private platformChanged(v: string) {
    if (v === 'gke' && !this.form.value.spot_percentage) {
      this.form.patchValue({'spot_percentage': '100'});
    }
    setRequiredValidator(v === 'generic', this.form.controls['storage_class']);
    if (this.form.valid) {
      delete this.installCommand;
    }
  }

  private createInstallCommand(agent: AgentModel) {
    // create an agent
    const v = this.form.value;
    let installcmd = this.baseInstall + ` \\\n --set token=${agent.token}`;
    if (environment.server === 'https://dev.cykubed.com/api') {
      installcmd += ' \\\n --set apiUrl=https://dev.cykubed.com/api'
    }

    if (v.storage_class) {
      installcmd += ` \\\n --set storageClass=${v.storage_class}`;
    }

    if (v.platform === 'minikube') {
      installcmd += ' \\\n --set platform=minikube --set imagePullPolicy=IfNotPresent'
    } else if (v.platform == 'gke') {
      installcmd += ' \\\n --set platform=GKE'
      if (v.service_account) {
        installcmd += ` \\\n --set gcpServiceAccount=${v.service_account}`
      }
    } else {
      if (v.platform) {
        let platform = v.platform;
        if (['gke', 'eks', 'aks'].includes(platform)) {
          platform = platform.toUpperCase();
        }
        installcmd += ` \\\n --set platform=${platform}`
      }
    }

    const namespace = v.namespace || 'cykubed';
    this.installCommand = installcmd.replaceAll('NAMESPACE', namespace);

    this.upgradeCommand = 'helm repo update\n'+
      this.installCommand.replace('install', 'upgrade')
      .replace('--create-namespace ', '');
  }

  get platform(): string {
    return this.form?.controls['platform'].value;
  }

  get isPlatformSelected(): boolean {
    return !!this.platform;
  }

  get isProjectSet(): boolean {
    return !!(this.form?.value.platform_project_id);
  }

  get storageClassRequired(): boolean {
    return this.platform === 'generic';
  }

  private calcGcpLoggingCommands() {
    const value = this.form.value;
    const project = value.platform_project_id;
    const namespace = value.namespace || 'cykubed';
    let serviceAccount;
    if (project) {
        serviceAccount = `${namespace}@${project}.iam.gserviceaccount.com`;
        if (this.form.value.service_account !== serviceAccount) {
          this.form.patchValue({'service_account': serviceAccount});
        }
        this.gcpLoggingCommands = `gcloud iam service-accounts create ${namespace} --project="${project}" \n` +
             `gcloud projects add-iam-policy-binding "${project}" \\\n      --member "serviceAccount:${serviceAccount}" \\\n      --role "roles/logging.logWriter" \n` +
             `gcloud iam service-accounts add-iam-policy-binding ${serviceAccount} \\\n     --role "roles/iam.workloadIdentityUser" \\\n     --member "serviceAccount:${project}.svc.id.goog[${namespace}/cykubed]" \n`
    }
  }

  showDeleteConfirm() {
    const namespace = this.agent?.namespace;
    this.deleteCommand = `helm uninstall ${namespace} -n ${namespace}`;

    if (this.agent?.first_connected) {
      this.matDialog.open(this.confirmDeleteRef);
    } else {
      this.delete();
    }
  }

  delete() {
    if (!this.agent) {
      return;
    }
    this.agentService.deleteAgent(this.agent.id).subscribe({
      next: created => {
        this.snackbar.open('Agent deleted', "OK", {duration: 2000});
        this.router.navigate(['../..'], {relativeTo: this.route})
      },
      error: err => {
        this.errorDialogService.show("Failed to delete agent", err.message);
      }
    }
    );
  }
}
