import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GenericTriggerableTableComponent} from './generic-triggerable-table/generic-triggerable-table.component';
import {SharedModule} from '../../shared/shared.module';
import {TriggerTypeComponent} from './trigger-type/trigger-type.component';
import {WidgetsModule} from '../../widgets/widgets.module';


@NgModule({
  declarations: [
    GenericTriggerableTableComponent,
    TriggerTypeComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    WidgetsModule
  ],
  exports: [
    GenericTriggerableTableComponent,
    TriggerTypeComponent
  ]
})
export class CommonSettingsModule { }
