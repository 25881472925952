import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SignupComponent} from './features/signup/signup.component';
import {OauthCallbackComponent} from './features/signup/oauth-callback/oauth-callback.component';
import {SignupGuard} from '@guards/signup.guard';
import {GithubAppInstalledComponent} from './github-app-installed/github-app-installed.component';
import {AcceptInviteComponent} from './features/signup/accept-invite/accept-invite.component';
import {BadGatewayComponent} from './auth/bad-gateway/bad-gateway.component';
import {HoldingPageComponent} from './holding-page/holding-page.component';
import {OrganisationDeletedMessage} from './delete-org-message/organisation-deleted-message.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {TermsComponent} from './terms/terms.component';
import {PricingPlansComponent} from './pricing/pricing-plans/pricing-plans.component';

const routes: Routes = [
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [SignupGuard]
  },
  {
    path: 'leaving-message',
    component: OrganisationDeletedMessage
  },
  {
    path: 'accept-invite/:token',
    component: AcceptInviteComponent
  },
  {
    path: 'oauth/callback/:platform',
    component: OauthCallbackComponent
  },
  {
    path: 'holding-page',
    component: HoldingPageComponent
  },
  {
    path: 'main',
    data: { breadcrumb: { skip: true } },
    loadChildren: () => import('./features/main/main.module').then(m => m.MainModule)
  },
  {
    path: 'github-app-installed',
    component: GithubAppInstalledComponent
  },
  {
    path: 'bad-gateway',
    component: BadGatewayComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'pricing',
    component: PricingPlansComponent
  },
  {
    path: '',
    redirectTo: 'main/dashboard/summary',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
