<h1>Notifications</h1>

<ng-container *ngIf="connectedPlatforms !== null && !connectedPlatforms?.length">
  <p>Connect to a messaging platforms to receive test run notifications:</p>

  <app-integrations-list filter="messaging" [redirectHere]="true"
                         [showAvailable]="true"></app-integrations-list>

</ng-container>


<div class="notifications-list" *ngIf="fetched" >

  <div class="toolbar row-space-between">
    <button mat-raised-button color="primary" [routerLink]="['./new']">Add new notification</button>
  </div>

  <app-generic-triggerable-table type="notification"
                                 *ngIf="triggerables?.length"
                                 [items]="triggerables" (selected)="selected($event)">

  </app-generic-triggerable-table>

</div>
