import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WebhookFormComponent} from './webhook-form/webhook-form.component';
import {WebhooksFormPageComponent} from './webhooks-form-page/webhooks-form-page.component';
import {WebhooksPageComponent} from './webhooks-page/webhooks-page.component';
import {WebhooksTableComponent} from './webhooks-table/webhooks-table.component';
import {SharedModule} from '../../shared/shared.module';
import {WidgetsModule} from '../../widgets/widgets.module';
import {CommonSettingsModule} from '../common/common-settings.module';
import {WebhookHistoryComponent} from './webhook-history/webhook-history.component';
import {HighlightModule} from 'ngx-highlightjs';


@NgModule({
  declarations: [
    WebhookFormComponent,
    WebhooksFormPageComponent,
    WebhooksPageComponent,
    WebhooksTableComponent,
    WebhookHistoryComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    WidgetsModule,
    CommonSettingsModule,
    HighlightModule
  ]
})
export class WebhooksModule { }
