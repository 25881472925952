import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApiTokensComponent} from './api-tokens.component';
import {SharedModule} from '../../shared/shared.module';
import {WidgetsModule} from '../../widgets/widgets.module';


@NgModule({
  declarations: [
    ApiTokensComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    WidgetsModule
  ]
})
export class ApiTokensModule { }
