import {Component, OnInit} from '@angular/core';
import {UserProfile} from '@models/generated.model';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {IconsService} from '@services/ui-services/icon.service';
import {FormControl} from '@angular/forms';
import {AccountService} from '@services/api-services/account.service';
import {WebsocketService} from '@services/api-services/websocket.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  profile: UserProfile;
  orgControl = new FormControl();
  showCancelled = false;
  expiryDate: Date | undefined;
  showPaymentFailed = false;
  showFreeTierExceeded = false;

  constructor(
    public authService: AuthenticationService,
    private accountService: AccountService,
    private websocketService: WebsocketService,
    public icons: IconsService) {

    this.orgControl.setValue(this.authService.currentOrganisation);
  }

  ngOnInit(): void {
    this.websocketService.exceededExceededIncludeBuildCredits$.subscribe(() => {
      this.checkFreeTierExceeded();
    });

    this.checkFreeTierExceeded();
  }

  private checkFreeTierExceeded() {
     this.accountService.getAccountDetails().subscribe(account => {
       this.showPaymentFailed = account.payment_failed || false;
       this.showFreeTierExceeded = account.exceeded_free_plan || false;
       if (account.subscription.expires) {
         this.expiryDate = new Date(account.subscription.expires);
       }
    });
  }

  gotoBillingPortal() {
    this.accountService.gotoCustomerPortal();
  }

  protected readonly frameElement = frameElement;
  protected readonly parseInt = parseInt;
}
