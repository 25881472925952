import {Component} from '@angular/core';

@Component({
  selector: 'app-sub-plan-settings',
  templateUrl: './sub-plan-settings.component.html',
  styleUrls: ['./sub-plan-settings.component.scss']
})
export class SubPlanSettingsComponent {

}
