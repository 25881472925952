import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../guards/auth.guard';
import {MainComponent} from './main.component';
import {TestRunTable} from '../dashboard/testrun-table/test-run-table.component';
import {TestRunComponent} from '../dashboard/test-run/test-run.component';
import {TestRunResolver} from '@services/resolvers/test-run.resolver';
import {DashboardComponent} from '../dashboard/dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    data: { breadcrumb: { skip: true } },
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Dashboard'
        },
        children: [
          {
            path: 'summary',
            component: TestRunTable
          },
          {
            path: 'testrun/:project/:localid',
            component: TestRunComponent,
            resolve: {
              testrun: TestRunResolver
            },
            data: {
              breadcrumb: {
                alias: 'testrun'
              }
            },
          },
          {
            path: '',
            redirectTo: 'summary',
            pathMatch: 'full'
          }
        ]
      },
      {
        path: 'settings',
        data: { breadcrumb: { disable: true, label: 'Settings' } },
        loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule)
      }
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
