import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {map, Observable} from 'rxjs';
import {AuthenticationService} from '../services/api-services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingGuard {

  constructor(private authService: AuthenticationService,
              private router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    if (!this.authService.checkAuthenticated(route)) {
      return this.router.createUrlTree(['/signup']);
    }
    return this.authService.ensureProfile().pipe(map( () => true));
  }

}
