import {Component} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {IconsService} from '@services/ui-services/icon.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, distinctUntilChanged, filter, switchMap, tap} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {IntegrationSummary, PlatformEnum, Repository, UserProfile} from '@models/generated.model';
import {ProjectService} from '@services/api-services/project.service';
import {GenericOwner, RepositoriesService} from '@services/api-services/repositories.service';
import {gitPlatforms} from '@models/consts';
import {UiSettingsService} from '@services/api-services/ui-settings.service';
import {IntegrationsService} from '@services/api-services/integrations.service';
import {IconDefinition} from '@fortawesome/free-brands-svg-icons';


@UntilDestroy()
@Component({
  selector: 'app-new-project-page',
  templateUrl: './new-project-page.component.html',
  styleUrls: ['./new-project-page.component.scss']
})
export class NewProjectPageComponent {
  user: UserProfile;
  platformControl = new FormControl();
  ownerControl = new FormControl();
  searchControl = new FormControl();
  repositories: Array<Repository>;
  selected?: Repository;
  form: FormGroup;
  fetching = false;
  platforms: PlatformEnum[];
  owners: GenericOwner[];
  gitIntegrations: IntegrationSummary[];
  selectedIntegration: IntegrationSummary;
  selectedPlatform: PlatformEnum;
  selectedOwner: GenericOwner | null;
  reposIcon: IconDefinition;

  constructor(private authService: AuthenticationService,
              private integrationsService: IntegrationsService,
              private uiSettingsService: UiSettingsService,
              private route: ActivatedRoute,
              private projectService: ProjectService,
              private repositoriesService: RepositoriesService,
              public icons: IconsService) {

    this.form = this.projectService.projectForm();
    this.user = this.authService.profile;
  }

  get showOwner(): boolean {
    return (this.selectedPlatform === 'bitbucket' || this.selectedPlatform === 'github');
  }

  ngOnInit(): void {

    this.platformControl.valueChanges.pipe(
      untilDestroyed(this),
      filter(() => this.platforms?.length>0),
      distinctUntilChanged(),
      switchMap(platform => {
        this.repositories = [];
        this.ownerControl.disable();
        this.selectedPlatform = platform;
        this.selectedIntegration = this.gitIntegrations.find(g => g.name === platform)!;
        return this.repositoriesService.getOwners(platform)
      })).subscribe(owners => {
        this.owners = owners || [];
        this.selectedOwner = null;
        // prepend bogus user if allowing user repos
        if ((this.selectedPlatform === 'github' && this.selectedIntegration.allow_user_repositories)
          || (this.selectedPlatform === 'gitlab')) {
          this.owners.push({id: this.selectedIntegration.login!, name: 'User', is_user: true});
        }

        let selected: GenericOwner | undefined;
        if (this.user.uisettings?.last_git_platform === this.selectedPlatform) {
           selected = owners.find(g => g.id === this.user.uisettings?.last_git_org_id);
        }
        if (!selected) {
          selected = owners[owners.length-1];
        }
        this.ownerControl.setValue(selected);
        this.ownerControl.enable();
        this.searchControl.setValue('');
    });

    this.searchControl.valueChanges.pipe(
      untilDestroyed(this),
      filter(() => this.platforms?.length>0),
      tap(() => this.fetching = true),
      debounceTime(100),
      filter(() => !!this.selectedOwner),
      switchMap((s) => {
        return this.repositoriesService.getRepositories(this.selectedPlatform, this.selectedOwner!.id, s,
          this.selectedOwner?.is_user)
      })).subscribe(reposList => {
      this.fetching = false;
      this.repositories = reposList;
    });

    this.ownerControl.valueChanges.pipe(
      untilDestroyed(this),
      filter((v) => !!v && this.platforms?.length>0)
      ).subscribe((owner: GenericOwner) => {
        this.repositories = [];
        this.selectedOwner = owner;
        this.searchControl.setValue('');
        this.uiSettingsService.setGitUISettings(this.platformControl.value, owner.id);
    });

    this.integrationsService.getConnectedIntegrations$('git').pipe(untilDestroyed(this))
      .subscribe(integrations => {
      this.gitIntegrations = integrations;
      this.platforms = this.gitIntegrations.map(i => i.name);
      this.platformControl.setValue(this.user.uisettings?.last_git_platform || this.platforms[0]);
      if (this.platforms?.length === 1) {
        this.platformControl.disable();
      }
    });
  }

  selectRepos(repos: Repository) {
    this.selected = repos;
    this.reposIcon = this.integrationsService.getIcon(repos.platform);
  }

  protected readonly gitPlatforms = gitPlatforms;
}
