<div class="price-headline">
  <span class="price">{{ currencySymbol }}{{ (prices?.flat_fee || 0) | number:'1.0-0' }}</span>
  <label *ngIf="prices">per month</label>
</div>

<div class="free-spacer" *ngIf="!prices"></div>

<ul class="limits">
  <li>
    <label>Users</label>
    <span>
      <ng-container *ngIf="plan.users_limit; else unlimited">{{ plan.users_limit }}</ng-container>
    </span>
  </li>

  <li><label matTooltipPosition="left"
             matTooltip="A test result is a single completed 'it()' function in your test specs"
             matTooltipShowDelay="0">Included test results</label>
    <span *ngIf="plan.included_test_results else unlimited">{{ plan.included_test_results | number:'1.0-0' }}</span>
  </li>

  <li *ngIf="prices"><label matTooltipShowDelay="0" matTooltipPosition="left"
                            matTooltip="We charge this for each 1000 test results over the included quota">Additional
    test results</label> <span>{{ currencySymbol }}{{ prices.per_10k_tests }} / 10,000</span></li>

</ul>
<fieldset class="hosted-box" *ngIf="showHosted">
  <legend>Fully hosted only</legend>
  <ul class="limits">
    <li *ngIf="showHosted"><label matTooltipShowDelay="0" matTooltipPosition="left"
               matTooltip="We assign 10 credits = 1 CPU/minute, 1 = 1 GB/minute">Included build credits</label>
      <span>{{ plan.included_build_credits  | number:'1.0-0'}}</span>
    </li>
    <li *ngIf="showHosted"><label matTooltipShowDelay="0" matTooltipPosition="left"
                                             matTooltip="The maximum number of test files that can be run in parallel">Maximum
      concurrency</label> <span>{{ plan.max_parallelism }}</span></li>
    <li *ngIf="prices && showHosted"><label matTooltipShowDelay="0" matTooltipPosition="left"
                                                       matTooltip="We charge this for each 10,000 build credits over the included quota">Additional
      build credits</label> <span>{{ currencySymbol }}{{ prices.per_10k_build_credits }} / 10,000</span></li>
  </ul>
</fieldset>


<ng-template #unlimited>Unlimited</ng-template>
