import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {PageSizeOptions} from '@models/consts';
import {AdminService, UserFilter} from '@services/api-services/admin.service';
import {FormControl} from '@angular/forms';
import {AdminUser, AdminUserList} from '@models/generated.model';
import {IconsService} from '@services/ui-services/icon.service';
import {UiSessionSettingsService} from '@services/ui-session-settings.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, distinctUntilChanged} from 'rxjs';
import {PageEvent} from '@angular/material/paginator';
import {MatSelectionListChange} from '@angular/material/list';

@UntilDestroy()
@Component({
  selector: 'app-admin-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss']
})
export class AdminUserListComponent implements OnInit, OnChanges {


  filter: UserFilter = {page: 0, pagesize: 50}
  searchControl = new FormControl();
  userList: AdminUserList = {...this.filter, total: 0, items: []};
  protected readonly pageSizeOptions = PageSizeOptions;
  selectedUser: AdminUser;
  selectedUsers: AdminUser[];

  @Input() orgId: number;
  @Input() allowSelection: boolean = true;
  @Input() allowSearch: boolean = true;
  @Output() selected = new EventEmitter<AdminUser>();

  constructor(private adminService: AdminService,
              public icons: IconsService,
              private uiSettings: UiSessionSettingsService) {
    this.filter.pagesize = uiSettings.pageSize;
  }

  ngOnInit(): void {
    if (this.allowSearch) {
      this.searchControl.valueChanges.pipe(
        untilDestroyed(this),
        debounceTime(100),
        distinctUntilChanged()
      ).subscribe(v => {
        this.filter.search = v;
        this.fetch();
      });
    }

    this.fetch();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['orgId']) {
      this.fetch();
    }
  }

  private fetch() {
    if (this.orgId) {
      this.filter.organisation_id = this.orgId;
    } else {
      delete this.filter.organisation_id;
    }
    this.adminService.listUsers(this.filter).subscribe(result => {
      this.userList = result;
    });
  }

  async pageChanged(e: PageEvent) {
    if (e.pageSize !== this.filter.pagesize) {
      this.uiSettings.pageSize = this.filter.pagesize = e.pageSize;
    }
    this.filter.page = e.pageIndex;
    this.fetch();
  }

  select(user: AdminUser) {
    this.selectedUser = user;
    this.selectedUsers = [user];
    this.selected.next(user);
  }


  userSelected(event: MatSelectionListChange) {
    this.select(event.options[0].value);
  }

}
