import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TriggerableModel, TriggerableType} from '@models/triggerable.model';
import {IconsService} from '@services/ui-services/icon.service';
import {WebhooksService} from '@services/api-services/webhooks.service';
import {faRectangleVerticalHistory} from '@fortawesome/pro-solid-svg-icons';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-generic-triggerable-table',
  templateUrl: './generic-triggerable-table.component.html',
  styleUrls: ['./generic-triggerable-table.component.scss']
})
export class GenericTriggerableTableComponent {

  historyIcon = faRectangleVerticalHistory;

  @Input() type: TriggerableType;
  @Input() items: TriggerableModel[];
  @Output() selected = new EventEmitter<TriggerableModel>();


  constructor(public icons: IconsService,
              private router: Router,
              private route: ActivatedRoute,
              private webhookService: WebhooksService) {
  }

  get isNotificationTable(): boolean {
    return this.type === 'notification';
  }

  select(item: TriggerableModel) {
    this.selected.next(item);
  }

  getTriggerSummary(item: TriggerableModel): string {
    return this.webhookService.getTriggerSummary(item);
  }

  async historyClicked(item: TriggerableModel) {
    await this.router.navigate(['history', item.id], {relativeTo: this.route})
  }
}
