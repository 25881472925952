import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IconsService} from '@services/ui-services/icon.service';
import {UsersService} from '@services/api-services/users.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserInvite} from '@models/generated.model';
import {forbiddenValueValidator} from '@lib/form-utils';

@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss']
})
export class InviteUsersComponent implements OnInit {
  inviteForm: FormGroup;
  orgInviteLink: string;
  existingUserEmails: Set<string> = new Set();

  constructor( private fb: FormBuilder,
               private userService: UsersService,
               private snackbar: MatSnackBar,
               private router: Router,
               private route: ActivatedRoute,
               private errorDialogService: ErrorDialogService,
               public icons: IconsService) {

    this.inviteForm = fb.group({
      invites: fb.array([])
    });

    this.addInvite();

  }


  get invites() {
    return this.inviteForm.controls['invites'] as FormArray;
  }

  addInvite() {
    this.invites.push(this.fb.group({
      email: ['', {validators: [Validators.email, Validators.required,
          forbiddenValueValidator(this.existingUserEmails)], updateOn: 'blur'}],
      permissions: ['member', Validators.required]
    }))
  }

  removeInvite(i: number) {
    this.invites.removeAt(i);
  }

  ngOnInit(): void {
    this.userService.getUsers().subscribe(users => {
      users.forEach(u => {
        this.existingUserEmails.add(u.email);
      })
    });
    this.userService.getInviteUrl().subscribe(v => {
      this.orgInviteLink = v;
    });
  }

  async copied() {
    this.userService.showOrgInviteLinkSnackbar();
  }

  save() {
    const invites: UserInvite[] = this.inviteForm.value['invites'].map( (invite: any) => {
      return {
        email: invite.email,
        is_admin: invite.permissions === 'admin'
      }
    });
    this.userService.inviteUsers(invites).subscribe({
      next: async () => {
        this.snackbar.open("Users invited", "OK", {duration: 2000});
        await this.router.navigate(['..'], {relativeTo: this.route});
      },
      error: (err) => {
        this.errorDialogService.show('Failed to invite users', err);
      }
    })

  }
}
