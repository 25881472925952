import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {map, Observable} from 'rxjs';
import {AuthenticationService} from '../services/api-services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(protected authService: AuthenticationService,
              protected router: Router) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    if (!this.authService.checkAuthenticated(route)) {
      const token = route.queryParams['token'];
      if (token) {
        this.authService.setToken(token);
        return this.router.navigate(['/main/dashboard']);
      }
      return this.router.createUrlTree(['/signup']);
    }

    return this.authService.ensureProfile().pipe(
        map(profile => {
          const org = this.authService.currentOrganisation!;
          if (!org) {
            this.authService.logOut(false);
            return this.router.createUrlTree(['/signup']);
          }
          if (!this.authService.isOrgInitialised && org.is_admin) {
            return this.router.createUrlTree(['/main/settings/onboarding',
              this.authService.currentOrganisation?.onboarding_state])
          }
          return true;
        })
    )
  }
}
