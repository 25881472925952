<div *ngIf="selection" class="container">

  <div class="pager" *ngIf="summaries && summaries!.length > 1">
    <button mat-icon-button [disabled]="!hasPrev" (click)="selectPrev()"><fa-icon [icon]="leftIcon" size="lg"></fa-icon></button>
    <span class="position">{{ (idx+1) }} of {{ summaries.length }}</span>
    <button mat-icon-button [disabled]="!hasNext" (click)="selectNext()">
      <fa-icon [icon]="rightIcon" size="lg"></fa-icon></button>
  </div>

  <div class="main-contents">

    <section class="description">
      <h2 class="file icon-line"><fa-icon [icon]="icons.file" size="lg"></fa-icon>
        <span>{{ selection.file }}<span *ngIf="testLine !== undefined"><span class="test-line">: {{ testLine }}</span></span>
      </span></h2>
      <h3 class="browser  icon-line" *ngIf="selection?.browser">
        <img alt="{{selection.browser}} logo" *ngIf="browserLogo" [src]="browserLogo">
        <span>{{ selection.browser | titlecase }} browser</span>
        <span class="retry" *ngIf="selection.retry && selection.retry > 0"> (retry {{ selection.retry }})</span>
      </h3>

      <h3 class="context  icon-line">
        <fa-icon [icon]="icons.test" size="lg"></fa-icon>
        <span>{{ selection.context }} : {{ selection.title }}</span>
      </h3>

      <pre class="error-message" *ngFor="let err of selection.errors">{{ err.message }}</pre>

      <div *ngIf="codeFrame?.frame" class="code-frame">
        <pre><h4>{{ codeFrame!.file}} line {{codeFrame!.line}}</h4><code>{{ codeFrame!.frame }}</code></pre>
      </div>
    </section>

    <div class="screenshots">
      <app-screenshots *ngIf="selection.failure_screenshots"
                    [images]="selection.failure_screenshots">
      </app-screenshots>
    </div>
  </div>

</div>

