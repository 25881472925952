import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, Observable, of, tap} from 'rxjs';
import {environment} from '@environment';
import {Address, Country} from '@models/generated.model';
import {FormBuilder, FormGroup} from '@angular/forms';

export interface ExtremeIpResult {
  city: string;
  country: string;
  countryCode: string;
  timezone: string;
  region: string;
}

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  _countries: Country[];

  constructor(private http: HttpClient,
              private fb: FormBuilder) { }

  getCountries(): Observable<Country[]> {
    if (this._countries) {
      return of(this._countries);
    }
    return this.http.get<Country[]>('/countries').pipe(
      tap(countries => this._countries = countries)
    );
  }

  getFormGroup(address?: Address): FormGroup {
    return this.fb.group({
      country: [address?.country],
      line1: [address?.line1],
      line2: [address?.line2],
      postal_code: [address?.postal_code],
      city: [address?.city],
      state: [address?.state]
    });
  }

  getCountryCodeFromIP(): Observable<string> {
    const cachedCountryCode = sessionStorage.getItem('countryCodeFromIP');
    if (cachedCountryCode) {
      return of(cachedCountryCode);
    }
    const params = new HttpParams({fromObject: {key: environment.ipLookupApiKey}});
    return this.http.get<ExtremeIpResult>('https://extreme-ip-lookup.com/json', {params}).pipe(
      map(result => result.countryCode),
      tap( result => {
        sessionStorage.setItem('countryCodeFromIP', result);
      }));
  }

}
