import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {AdminService} from '@services/api-services/admin.service';
import {IconsService} from '@services/ui-services/icon.service';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {AdminOrganisation} from '@models/generated.model';
import {Plans} from '@models/consts';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-admin-org-details',
  templateUrl: './admin-org-details.component.html',
  styleUrls: ['./admin-org-details.component.scss']
})
export class AdminOrgDetailsComponent implements OnInit {

  @Input() org: AdminOrganisation;
  @Input() showName = true;
  @Output() orgChanged = new EventEmitter<AdminOrganisation>();

  @ViewChild('changePlan', { static: true }) changePlan: TemplateRef<any>;
  @ViewChild('addBuildCredits', { static: true }) addBuildCredits: TemplateRef<any>;

  changePlanForm: FormGroup;
  buildCreditsForm: FormGroup;
  myOrgId: number;
  plans = Plans;

  constructor(private adminService: AdminService,
              public icons: IconsService,
              private route: ActivatedRoute,
              authService: AuthenticationService,
              fb: FormBuilder,
              private errorDialogService: ErrorDialogService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog) {

    this.myOrgId = authService.currentOrganisationId;
    this.changePlanForm = fb.group({
      plan: ['', Validators.required],
      expires: ['']
    });

    this.buildCreditsForm = fb.group({
      credits: ['', [Validators.required, Validators.min(1), Validators.max(100000)]]
    });
  }

  ngOnInit(): void {
    if (this.route.snapshot.data['org']) {
      this.org = this.route.snapshot.data['org'];
    }
  }

  showPlanChangeDialog() {
    this.changePlanForm.reset();
    this.dialog.open(this.changePlan);
  }

  showAddBuildCredits() {
    this.buildCreditsForm.reset();
    this.dialog.open(this.addBuildCredits);
  }

  confirmChangePlan() {
    const formValue = this.changePlanForm.value;
    this.adminService.changeSubscriptionPlan(this.org.id, formValue.plan,
      formValue.expires).subscribe({next: org => {
        this.snackbar.open("Subscription plan changed", "OK", {duration: 2000});
        this.org = org;
        this.orgChanged.next(org);
      },
      error: err => {
       this.errorDialogService.show('Failed to update plan', err.message);
      }

    })
  }

  confirmAddCredits() {
    const credits = this.buildCreditsForm.value.credits;
    this.adminService.addBuildCredits(this.org.id, credits).subscribe({
      next: org => {
        this.snackbar.open("Credits added", "OK", {duration: 2000});
        this.org = org;
        this.orgChanged.next(org);
      },
      error: err => {
       this.errorDialogService.show('Failed to add build credits', err.message);
      }
    });
  }
}

