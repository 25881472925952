<div class="light-theme main-container">

  <app-nav-bar></app-nav-bar>

  <div class="free-tier-exceeded warning-box" *ngIf="showFreeTierExceeded">
    <span><fa-icon size="2x" class="warning" [icon]="icons.warning"></fa-icon> You have used your free allowance for this month</span>
    <button mat-raised-button color="accent" [routerLink]="['/main/settings/account/plan/upgrade']">Upgrade Now</button>
  </div>
  <div class="cancelled warning-box" *ngIf="expiryDate">
    <span><fa-icon size="2x"  class="warning" [icon]="icons.warning"></fa-icon> Your service will terminate on {{ expiryDate | date }}</span>
    <button mat-raised-button color="accent" (click)="gotoBillingPortal()">Upgrade Now</button>
  </div>

  <div class="payment-failed warning-box" *ngIf="showPaymentFailed && !expiryDate">
    <span><fa-icon size="2x"  class="warning" [icon]="icons.warning"></fa-icon> Your last payment failed. Please upgrade your payment details to avoid loss of service</span>
    <button mat-raised-button color="accent" (click)="gotoBillingPortal()">Fix Payment Details</button>
  </div>
  <div class="contents">

    <router-outlet></router-outlet>
  </div>
</div>
