import {Component, OnInit} from '@angular/core';
import {SubscriptionPlan, UserOrganisationSummary} from '@models/generated.model';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AccountService} from '@services/api-services/account.service';

@Component({
  selector: 'app-upgrade-plan',
  templateUrl: './upgrade-plan.component.html',
  styleUrls: ['./upgrade-plan.component.scss']
})
export class UpgradePlanComponent implements OnInit {

  org: UserOrganisationSummary;
  currentPlan: SubscriptionPlan;

  constructor(private authService: AuthenticationService,
              private accountService: AccountService,
              private router: Router,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.org = this.authService.currentOrganisation!;

    this.accountService.getAccountDetails().subscribe(d => {
      this.currentPlan = d.subscription.plan;
    });
  }

  async cancel() {
    await this.router.navigate(['..'], {relativeTo: this.route});
  }
}
