<div class="page">
  <h2>Your Organisation</h2>

  <div class="contents">
    <form [formGroup]="orgForm" *ngIf="orgForm">
      <mat-form-field appearance="outline" class="org-name">
        <mat-label>Organisation name</mat-label>
        <input matInput formControlName="name" placeholder="Organisation name">
        <mat-error>This field is required</mat-error>
      </mat-form-field>


    </form>

  </div>


  <div class="toolbar">
      <button mat-raised-button color="primary"
              [disabled]="orgForm.pristine || orgForm.invalid"
              (click)="saveOrgDetails()">Save changes</button>

      <button mat-button color="warn" (click)="showDeleteConfirm()">Delete Organisation</button>

  </div>

</div>



<ng-template #confirmDelete>
  <h1 mat-dialog-title>Confirm delete organisation?</h1>
  <div mat-dialog-content>
    <p>This will delete all your projects and cancel any active subscription plans.</p>
    <p>Are you sure?</p>
  </div>
  <div mat-dialog-actions>
    <div  class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>No</button>
      <button mat-raised-button mat-dialog-close color="warn" (click)="deleteOrganisation()">Confirm delete</button>
    </div>
  </div>
</ng-template>
