import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {IconsService} from '@services/ui-services/icon.service';
import {Project, TestRunSummary, UserProfile} from '@models/generated.model';
import {PageEvent} from '@angular/material/paginator';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {StartRunDialogComponent} from '../start-run-dialog/start-run-dialog.component';
import {TestRunFilter} from '@services/api-services/test-run.service';
import {intervalToDuration} from 'date-fns'
import {ACTIVE_STATES} from '@models/states';
import {TestrunSummaryService} from '../testrun-summary.service';
import {UiSessionSettingsService} from '@services/ui-session-settings.service';
import {distinctUntilChanged} from 'rxjs/internal/operators/distinctUntilChanged';
import {ProjectService} from '@services/api-services/project.service';


@UntilDestroy()
@Component({
  selector: 'app-testrun-table',
  templateUrl: './test-run-table.component.html',
  styleUrls: ['./test-run-table.component.scss']
})
export class TestRunTable implements OnInit, AfterViewInit {

  @ViewChild('tablewrap') tableWrapEl: ElementRef;

  profile?: UserProfile;
  pageSizeOptions = [10, 50, 100];

  authorCtrl = new FormControl();
  statusCtrl = new FormControl();
  projectCtrl = new FormControl();
  branchCtrl = new FormControl();

  trfilter: TestRunFilter = {page: 0, pagesize: 10};

  allowAdd = true;
  testdur: Duration;
  projects: Project[];

  constructor(private route: ActivatedRoute,
              private projectService: ProjectService,
              public service: TestrunSummaryService,
              private router: Router,
              public icons: IconsService,
              private dialog: MatDialog,
              private uiSettings: UiSessionSettingsService,
              public authService: AuthenticationService) {
  }

  initialise() {
    this.service.filter$.next(this.trfilter);
  }

  reset() {
      this.trfilter = {page: 0, pagesize: 10}
      this.authorCtrl.reset();
      this.projectCtrl.reset();
      this.branchCtrl.reset();
      this.initialise();
  }

  get startDisabled(): boolean {
    return this.authService.newRunsDiabled;
  }

  ngOnInit(): void {
    this.trfilter.pagesize = this.uiSettings.pageSize;
    this.projectService.projects$.pipe(untilDestroyed(this)).subscribe(projects => {
      this.projects = projects;
    });

    this.authService.currentOrganisation$.pipe(untilDestroyed(this)).subscribe(() => {
      this.reset();
    });

    this.initialise();

    this.router.events.pipe(untilDestroyed(this)).subscribe( event => {
        if (event instanceof NavigationStart) {
          if (!event.url.startsWith('/main/dashboard/summary')) {
            this.service.scrollPosition = this.tableWrapEl?.nativeElement.scrollTop;
          }
        }
    });

    this.route.params.pipe(untilDestroyed(this)).subscribe(params => {

      this.trfilter = {...params, page: parseInt(params['page'] || 0),
                       pagesize: parseInt(params['pageSize'] || this.uiSettings.pageSize)};

      if (this.trfilter.author_email && this.authorCtrl.value !== this.trfilter.author_email) {
        this.authorCtrl.setValue(this.trfilter.author_email, {emitEvent: false});
      } else {
        if (!this.trfilter.author_email) {
          this.authorCtrl.reset(null, {emitEvent: false});
        }
      }
      if (this.trfilter.branch && this.branchCtrl.value !== this.trfilter.branch) {
        this.branchCtrl.setValue(this.trfilter.branch, {emitEvent: false});
      }
      this.projectCtrl.setValue(this.trfilter.project_id, {emitEvent: false});
      if (this.trfilter.status) {
        this.statusCtrl.setValue(this.trfilter.status, {emitEvent: false});
      }

      this.service.filter$.next(this.trfilter);
    });

    this.testdur = intervalToDuration({start: 0, end: 10000})

    this.authorCtrl.valueChanges.pipe(
      untilDestroyed(this),
      distinctUntilChanged(),
    ).subscribe(async email => {
      if (email) {
        this.trfilter.author_email = email;
      } else {
        delete this.trfilter.author_email;
      }
      this.filterChanged();
    });

    this.branchCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe(async branch => {
      if (!branch) {
        delete this.trfilter.branch;
      } else {
        this.trfilter.branch = branch;
      }
      this.filterChanged();
    });

    this.projectCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe(async id => {
      if (!id) {
         this.branchCtrl.disable();
        delete this.trfilter.project_id;
      } else {
        this.branchCtrl.enable();
        this.trfilter.project_id = id;
      }
      this.filterChanged();
    });

    this.statusCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe(async status => {
      if (!status) {
        delete this.trfilter.status;
      } else {
        this.trfilter.status = status;
      }
      this.filterChanged();
    });
  }

  getStatusTooltip(tr: TestRunSummary): string {
    switch (tr.status) {
      case 'passed':
        if (tr.flakey_tests) {
          return 'Passed but some tests were flakey (i.e failed initially but passed on retry)';
        } else {
          return 'All tests passed';
        }
      case 'building':
        return 'Building web app';
      case 'pending':
        return 'Waiting for an agent';
      case 'running':
        return 'Running test specs';
      case 'cancelled':
        return 'Run was cancelled';
      case 'failed':
        return (tr.failed_tests === 1)?'Run has 1 failed test': `Run has ${tr.failed_tests} failed tests`;
      case 'started':
        return 'Run accepted by agent';
      case 'timeout':
        return 'Run failed to complete due to timeout';
    }
  }

  get isFiltered(): boolean {
    return !!this.trfilter.author_email || !!this.trfilter.branch;
  }

  gotoCommit(event: Event, tr: TestRunSummary) {
    event.stopPropagation();
    if (tr.commit?.commit_url) {
      location.href = tr.commit.commit_url;
    }
  }

  // restore the scroll position
  ngAfterViewInit(): void {
    this.tableWrapEl.nativeElement.scrollTop = this.service.scrollPosition;
  }

  isRunning(run: TestRunSummary): boolean {
    return ACTIVE_STATES.includes(run.status);
  }

  pageChanged(e: PageEvent) {
    if (e.pageSize !== this.trfilter.pagesize) {
      this.uiSettings.pageSize = this.trfilter.pagesize = e.pageSize;

    }
    this.trfilter.page = e.pageIndex;
    this.filterChanged();
  }

  async runClicked(run: TestRunSummary) {
    await this.router.navigate(['/main/dashboard/testrun', run.project_name, run.local_id]);
  }

  private filterChanged() {
    const path = {...this.trfilter};
    this.router.navigate(['.', path],
      {replaceUrl: true, relativeTo: this.route});
  }
  //
  // async branchSelected(event: MatAutocompleteSelectedEvent) {
  //   this.trfilter.branch = event.option.value;
  //   this.filterChanged();
  // }
  //
  // async clearBranch(event: MouseEvent) {
  //   trapEvent(event);
  //   delete this.trfilter.branch;
  //   this.branchCtrl.reset();
  //   this.filterChanged();
  // }

  startRun() {
    this.dialog.open(StartRunDialogComponent, {
      width: '500px'
    });
  }

  toDate(dt: string): Date {
    return new Date(dt);
  }

  projectSelected(project: Project) {
    this.trfilter.project_name = project.name;
  }

  getFlakeyTooltip(run: TestRunSummary) {
    if (run.flakey_tests === 1) {
      return 'Run has 1 flakey test';
    } else {
      return `Run has ${run.flakey_tests} flakey tests`;
    }
  }

  async gotoFailedRun(run: TestRunSummary, event: MouseEvent) {
    event.stopPropagation();
    await this.service.gotoFailTestRunDetail(run);
  }

  async gotoRunWithFlakes(run: TestRunSummary, event: MouseEvent) {
    event.stopPropagation();
    await this.service.gotoFlakeTestRunDetail(run);
  }
}
