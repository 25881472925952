import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NotificationsService} from '@services/api-services/notifications.service';
import {NotificationChannel, PlatformEnum} from '@models/generated.model';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, filter, startWith, switchMap} from 'rxjs';
import {NotificationPlatforms} from '@models/consts';

@UntilDestroy()
@Component({
  selector: 'app-notification-channel-select',
  templateUrl: './channel-select.component.html',
  styleUrls: ['./channel-select.component.scss'],
  providers: [
  {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ChannelSelectComponent),
    multi: true
  }]
})
export class ChannelSelectComponent implements OnInit, OnChanges, ControlValueAccessor {

  // call if value was changed inside our component
  private _onChange = (_: any) => {};
  // call if input was "touched" .. !
  private _onTouched = () => {};

  @Input() platform: PlatformEnum;
  @Input() includePrivate = false;

  channels: NotificationChannel[];
  filteredChannels: NotificationChannel[];
  searchControl = new FormControl();
  private selectedChannel: NotificationChannel | null;


  constructor(private service: NotificationsService) {

  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(channel: NotificationChannel): void {
    if (!channel) {
      this.selectedChannel = null;
      this.searchControl.reset(null, {emitEvent: false});
    } else {
      this.selectedChannel = channel;
      this.searchControl.setValue(channel.name, {emitEvent: false, emitModelToViewChange: false});
    }

    this.searchControl.setValue(channel);
    this.selectedChannel = channel;
  }

  channelName(channel: NotificationChannel): string {
    return (channel && channel.name) ? channel.name: '';
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      untilDestroyed(this),
      filter(() => !!this.platform && NotificationPlatforms.includes(this.platform)),
      startWith(""),
      debounceTime(100),
      switchMap(value => {
        const label = typeof value === 'string' ? value : value?.name;
        return this.service.searchChannels(this.platform, label, this.includePrivate);
      })
    ).subscribe(channels => {
      this.channels = channels;
      this.filterChannels();
    });
  }

  private filterChannels() {
    if (this.channels) {
      this.filteredChannels = this.channels.filter(ch => this.includePrivate || (!this.includePrivate && ch.public));
    } else {
      this.filteredChannels = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.filterChannels();
  }

  channelSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedChannel = event.option.value;
    this._onChange(this.selectedChannel);
  }
}
