import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {AdminOrganisation, AdminUser, UserProfile} from '@models/generated.model';
import {IconsService} from '@services/ui-services/icon.service';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {AdminService} from '@services/api-services/admin.service';

@Component({
  selector: 'app-admin-user-details',
  templateUrl: './admin-user-details.component.html',
  styleUrls: ['./admin-user-details.component.scss']
})
export class AdminUserDetailsComponent implements OnChanges {

  @Input() user: AdminUser;

  profile: UserProfile;
  orgs: AdminOrganisation[];

  constructor(public icons: IconsService,
              private adminService: AdminService,
              private authService: AuthenticationService) {
    this.profile = authService.profile;
  }

  async impersonate() {
    await this.authService.setImpersonating(this.user);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // fetch all org details
    this.adminService.listOrganisations({page:0, pagesize: 0,
      user_id: this.user.id}).subscribe(orgs => {
      this.orgs = orgs.items;
    });
  }
}
