import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {SubscriptionPlan, UserOrganisationSummary} from '@models/generated.model';
import {AccountService} from '@services/api-services/account.service';
import {Router} from '@angular/router';
import {AgentService} from '@services/api-services/agent.service';

@Component({
  selector: 'app-onboarding-choose-plan',
  templateUrl: './onboarding-choose-plan.component.html',
  styleUrls: ['./onboarding-choose-plan.component.scss']
})
export class OnboardingChoosePlanComponent implements OnInit {
  org: UserOrganisationSummary;

  constructor(private authService: AuthenticationService,
              private accountService: AccountService,
              private agentService: AgentService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.org = this.authService.currentOrganisation!;
  }

  async planSelected(plan: SubscriptionPlan) {
    // we can mark the org as initialised now
    this.accountService.updateOrganisation({onboarding_state: 'completed'}).subscribe(async () => {
      const org = this.authService.currentOrganisation!;
      if (plan.name === 'Free') {
        if (org.prefer_self_host) {
          // create an agent if we don't already have one (we shouldn't, but it doesn't hurt to be paranoid)
          this.agentService.listAgents().subscribe(async agents => {
            if (!agents?.length) {
              this.agentService.createAgent().subscribe(async agent => {
                await this.router.navigate(['/main/settings/agents/manage', agent.id]);
              });
            } else {
              // go to the new project page
              await this.router.navigate(['/main/settings/projects/new'])
            }
          })
        } else {
          // go to the new project page
          await this.router.navigate(['/main/settings/projects/new'])
        }
      } else {
        // go to the purchase page
      }
    })

  }
}
