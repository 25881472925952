import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {LargeCykubedLogoComponent} from './large-cykubed-logo/large-cykubed-logo.component';
import {BetaMessageComponent} from './beta-message/beta-message.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    LargeCykubedLogoComponent,
    BetaMessageComponent
  ],
  imports: [
    CommonModule,
    NgOptimizedImage,
    FontAwesomeModule
  ],
  exports: [
    LargeCykubedLogoComponent,
    BetaMessageComponent
  ]
})
export class LogosModule { }
