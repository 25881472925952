import {Component, Input} from '@angular/core';
import {faCopy} from '@fortawesome/free-regular-svg-icons';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-copy-text-button',
  templateUrl: './copy-text-button.component.html',
  styleUrls: ['./copy-text-button.component.scss']
})
export class CopyTextButtonComponent {

  copyIcon = faCopy;

  @Input() value: string | null;

  constructor(private snackbar: MatSnackBar) {

  }

  copied() {
      this.snackbar.open('Copied to clipboard', 'OK', {duration: 1000});
  }
}
