<div *ngIf="stats" class="usage-stats">

  <h2 *ngIf="stats.total_cost_usd">Estimated cost: <span class="cost">${{ stats.total_cost_usd | number:'1.1-3' }} (USD)</span></h2>

  <h2>Usage</h2>
  <mat-card>
    <mat-card-header>
      <h3 matCardTitle>Overall Totals</h3>
    </mat-card-header>
    <mat-card-content>
      <div class="info-item" *ngIf="stats.total_cpu_seconds">
        <label>Total CPU (minutes)</label>
        <span>{{ (stats!.total_cpu_seconds! / 60) | number:'1.1-2' }}</span>
      </div>
      <div class="info-item" *ngIf="stats.total_memory_gb_seconds">
        <label>Memory (GB mins)</label>
        <span>{{ stats!.total_memory_gb_seconds! / 60 | number:'1.1-2' }}</span>
      </div>
      <div class="info-item" *ngIf="stats.total_ephemeral_gb_seconds">
        <label>Ephemeral (GB mins)</label>
        <span>{{ stats!.total_ephemeral_gb_seconds! / 60 | number:'1.1-2' }}</span>
      </div>
    </mat-card-content>
  </mat-card>

  <div *ngIf="stats.cpu_seconds_spot" class="spot-breakdown">
    <mat-card>
      <mat-card-header>
        <h3 matCardTitle>Spot-VM Totals</h3>
      </mat-card-header>
      <mat-card-content>
        <div class="info-item" *ngIf="stats.cpu_seconds_spot">
          <label>Total CPU (minutes)</label>
          <span>{{ (stats!.cpu_seconds_spot! / 60) | number:'1.1-2' }}</span>
        </div>
        <div class="info-item" *ngIf="stats.memory_gb_seconds_spot">
          <label>Memory (GB mins)</label>
          <span>{{ stats!.memory_gb_seconds_spot! / 60 | number:'1.1-2' }}</span>
        </div>
        <div class="info-item" *ngIf="stats.ephemeral_gb_seconds_spot">
          <label>Ephemeral (GB mins)</label>
          <span>{{ stats!.ephemeral_gb_seconds_spot! / 60 | number:'1.1-2' }}</span>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="stats.cpu_seconds_normal">
      <mat-card-header>
        <h3 matCardTitle>Non-spot-VM Totals</h3>
      </mat-card-header>
      <mat-card-content>
        <div class="info-item" *ngIf="stats.cpu_seconds_normal">
          <label>Total CPU (minutes)</label>
          <span>{{ (stats!.cpu_seconds_normal! / 60) | number:'1.1-2' }}</span>
        </div>
        <div class="info-item" *ngIf="stats.memory_gb_seconds_normal">
          <label>Memory (GB mins)</label>
          <span>{{ stats!.memory_gb_seconds_normal! / 60 | number:'1.1-2' }}</span>
        </div>
        <div class="info-item" *ngIf="stats.ephemeral_gb_seconds_normal">
          <label>Ephemeral (GB mins)</label>
          <span>{{ stats!.ephemeral_gb_seconds_normal! / 60 | number:'1.1-2' }}</span>
        </div>
      </mat-card-content>
    </mat-card>


  </div>
</div>
