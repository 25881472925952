<h1>Webhooks</h1>

<div *ngIf="fetched && !triggerables?.length" class="info-box">
  <p>Webhooks provide a simple mechanism to integrate with other services when a test run completes.
    All webhooks are POSTed with the full description of a test run (you can find the schema in the response to the API endpoint <a href="{{apiUrl}}/redoc#tag/Testrun/operation/get_testrun_testrun__testrun_id__get" target="_blank"> described here</a>).

  </p>
</div>

<div class="webhooks-list" *ngIf="fetched" >


  <div class="toolbar row-space-between">
    <button mat-raised-button color="primary" [routerLink]="['./new']">Add new webhook</button>
  </div>

  <app-generic-triggerable-table type="webhook"
                                 *ngIf="triggerables?.length"
                                 [items]="triggerables" (selected)="selected($event)">

  </app-generic-triggerable-table>


</div>

