import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProjectResolver} from './project.resolver';
import {TestRunResolver} from './test-run.resolver';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    ProjectResolver,
    TestRunResolver
  ]
})
export class ResolversModule { }
