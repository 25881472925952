<div class="container">
  <app-circle-progress [value]="progress" class="circle-progress"></app-circle-progress>

  <div class="text">
    <label>{{ label }}</label>

    <div class="value"><span class="value">{{ value }}</span> / <span class="total">{{ total }}</span></div>
  </div>


</div>
