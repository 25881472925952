import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {map, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor(private router: Router,
              private authService: AuthenticationService) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!this.authService.checkAuthenticated(route)) {
        return this.router.createUrlTree(['/signup']);
      }

      return this.authService.ensureProfile().pipe(
        map(profile => {
          return !!this.authService.currentOrganisation!.is_admin;
        }));
    }
}
