import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Project} from '@models/generated.model';
import {ProjectService} from '@services/api-services/project.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectResolver  {

  constructor(private service: ProjectService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project> {
    return this.service.getProject$(Number(<string>route.paramMap.get('id')));
  }
}
