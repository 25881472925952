<div class="dialog">

  <h2 mat-dialog-title>Enter Rocket.chat credentials</h2>

  <div mat-dialog-content>

    <p>Please supply the credentials for your Rocket.chat installation</p>
    <p>You can generate a personal access token by following the instructions <a
      target="_blank"
      href="https://developer.rocket.chat/reference/api/rest-api/endpoints/authentication-endpoints/login"> in the Rocket.chat documentation</a>. You should disable 2FA</p>

    <form [formGroup]="form">
      <mat-form-field>
        <mat-label>Site URL</mat-label>
        <input matInput name="url" formControlName="url" type="url" autocomplete="off">
        <mat-error *ngIf="form.controls['url'].hasError('required')">This field is required</mat-error>
        <mat-error *ngIf="form.controls['url'].hasError('invalidUrl')">Invalid URL format</mat-error>
      </mat-form-field>
      <mat-form-field class="user">
        <mat-label>User ID</mat-label>
        <input matInput type="text" formControlName="user_id" name="user_id" autocomplete="off">
      </mat-form-field>
      <mat-form-field class="user">
        <mat-label>Access Token</mat-label>
        <input matInput type="text" formControlName="access_token" name="access_token" autocomplete="off">
      </mat-form-field>
    </form>
  </div>

  <div mat-dialog-actions>
    <div class="row-centered">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-raised-button mat-dialog-close color="primary" [disabled]="form.invalid"
              (click)="save()">OK
      </button>
    </div>
  </div>


</div>
