import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-pricing-plans',
  templateUrl: './pricing-plans.component.html',
  styleUrls: ['./pricing-plans.component.scss']
})
export class PricingPlansComponent implements OnInit {

  backgroundColour = '#fff';
  hideFree = false;
  textColour = '#000';
  mobile = false;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    const qp = this.route.snapshot.queryParams;
    const background = qp['background'];
    if (background) {
      this.backgroundColour = `#${background}`;
    }
    const colour = qp['text'];
    if (colour) {
      this.textColour = `#${colour}`;
    }
    if (qp['mobile'] === 'true') {
      this.mobile = true;
    }

    const hideFree = qp['hideFree'];
    if (hideFree === 'true') {
      this.hideFree = true;
    }
  }

}
