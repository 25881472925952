import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AccountService} from '@services/api-services/account.service';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {AccountDetails, SubscriptionPlan, UserOrganisationSummary} from '@models/generated.model';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ActivatedRoute, Router} from '@angular/router';
import {WebsocketService} from '@services/api-services/websocket.service';
import {AgentService} from '@services/api-services/agent.service';
import {MatDialog} from '@angular/material/dialog';
import {map, Observable, switchMap} from 'rxjs';
import {IconsService} from '@services/ui-services/icon.service';


@UntilDestroy()
@Component({
  selector: 'app-plan-usage',
  templateUrl: './plan-usage.component.html',
  styleUrls: ['./plan-usage.component.scss']
})
export class PlanUsageComponent implements OnInit {
  account: AccountDetails;
  userProgress: number;
  plan: SubscriptionPlan;
  org: UserOrganisationSummary;
  upgradeButtonText: string;
  upgrading = false;
  upgradeSuccessful = false;
  showUpgradeButton = true;
  showManageButton = false;

  @ViewChild('confirmHostedSwitch', {static: true}) confirmHostedSwitch: TemplateRef<any>;

  constructor(private router: Router,
              private agentService: AgentService,
              private route: ActivatedRoute,
              private dialog: MatDialog,
              public icons: IconsService,
              private websocketService: WebsocketService,
              private accountService: AccountService,
              private authService: AuthenticationService) {

  }

  ngOnInit(): void {

    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      const state = params['state'];

      const sessionId = params['session_id'];
      if (sessionId) {
        // this came from Stripe
        this.upgrading = true;
        this.websocketService.getPlanUpgradeSuccessful$().pipe(untilDestroyed(this)).subscribe(async msg => {
          this.upgrading = false;
          this.upgradeSuccessful = true;
          await this.router.navigate(['.'], {relativeTo: this.route});
        });
      }
    });

    this.websocketService.jobStatsUpdated$.pipe(
      untilDestroyed(this),
      switchMap(() => this.accountService.getAccountDetails()))
      .subscribe( d => {
        this.updateAccountDetails(d);
    });

    this.router.events.pipe(untilDestroyed(this)).subscribe(() => {
      this.showUpgradeButton = (this.route.children.length === 0);
    });

    this.authService.currentOrganisation$.pipe(
      untilDestroyed(this),
      switchMap(org => {
        this.org = org;
        return this.accountService.getAccountDetails()
      })).subscribe(d => {
        this.updateAccountDetails(d);
      });

      this.showUpgradeButton = (this.route.children.length === 0);
  }

  private updateAccountDetails(d: AccountDetails) {
    this.account = d;
    this.plan = d.subscription.plan;
    if (this.plan.users_limit) {
      this.userProgress = 100 * d.users / this.plan.users_limit;
    }

    switch (this.plan.name) {
      case 'Free':
        this.upgradeButtonText = "Upgrade Now";
        break
      case 'Staff':
        this.showManageButton = false
        this.showUpgradeButton = false;
        break;
      default:
        this.showManageButton = true;
        this.upgradeButtonText = "Upgrade Plan";
    }
  }


  readonly childInactive$: Observable<boolean> = this.router.events.pipe(
    map(() => this.route.children.length === 0)
  );

  async upgradeClicked() {
    await this.router.navigate(['./upgrade'], {relativeTo: this.route});
  }

  switchToHostedClicked() {
    if (this.agentService.agents?.length) {
      this.dialog.open(this.confirmHostedSwitch);
    } else {
      this.switchToHostedConfirmed();
    }
  }

  switchToSelfHosted() {
    this.accountService.updateOrganisation({prefer_self_host: true}).subscribe(async () => {
      if (!this.agentService.agents?.length) {
        // will add an egent and edit it
        this.agentService.addNewAgent();
      } else {
        await this.agentService.editAgent()
      }

    })

  }

  switchToHostedConfirmed() {
    this.accountService.updateOrganisation({prefer_self_host: false}).subscribe(() => {
      this.org.prefer_self_host = false;
    })
  }

  gotoBillingPortal() {
    this.accountService.gotoCustomerPortal();
  }
}
