import {Component} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {TestRunService} from '@services/api-services/test-run.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Router} from '@angular/router';
import {ProjectService} from '@services/api-services/project.service';

@UntilDestroy()
@Component({
  selector: 'app-start-run-dialog',
  templateUrl: './start-run-dialog.component.html',
  styleUrls: ['./start-run-dialog.component.scss']
})
export class StartRunDialogComponent {

  form: FormGroup;
  starting = false;

  constructor(private router: Router,
              private service: TestRunService,
              private snackBar: MatSnackBar,
              fb: FormBuilder,
              projectService: ProjectService,
              private errorDialogService: ErrorDialogService,
              public dialog: MatDialog) {

    this.form = fb.group({
      projectId: ['', Validators.required],
      branch: [{value:'', disabled: true}, Validators.required]
    });

    if (projectService.projects?.length === 1) {
      this.form.patchValue({'projectId': projectService.projects[0].id});
      this.form.controls['branch'].enable();
    }

    this.form.controls['projectId'].valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
      if (!!v) {
        this.form.controls['branch'].enable();
      } else {
        this.form.controls['branch'].disable();
      }
    });
  }

  start() {
    const value = this.form.value;
    // FIXME add error reporting
    this.starting = true;
    this.service.createTestRun(value.projectId, value.branch).subscribe({
      next: async testrun => {
        this.snackBar.open(`Test run ${testrun.local_id} created`, "OK", {duration: 2000});
        this.dialog.closeAll();
        this.starting = false;
        await this.router.navigate(['/main/dashboard/testrun', testrun.project_name,
          testrun.local_id]);
      },
      error: () => {
        this.dialog.closeAll();
        this.errorDialogService.show("Failed to create test run");
      }
    });
  }
}
