import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {WebhooksService} from '@services/api-services/webhooks.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {WebHook, WebhookHistory} from '@models/generated.model';
import {WebsocketService} from '@services/api-services/websocket.service';
import {takeWhile} from 'rxjs';
import {faCheck, faX} from '@fortawesome/pro-solid-svg-icons';
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
import {MatSelectionListChange} from '@angular/material/list';

@UntilDestroy()
@Component({
  selector: 'app-webhook-history',
  templateUrl: './webhook-history.component.html',
  styleUrls: ['./webhook-history.component.scss']
})
export class WebhookHistoryComponent implements OnInit {
  webhookId: number;
  items: WebhookHistory[] = [];
  successIcon = faCheck;
  failIcon = faX;
  webhook: WebHook | undefined;
  selectedItem: WebhookHistory;
  selectedItems: WebhookHistory[];
  errorIcon = faTriangleExclamation;
  request: string;

  constructor(private router: Router,
              private webhookService: WebhooksService,
              private websocketService: WebsocketService,
              private route: ActivatedRoute) {
  }

  ngOnInit(): void {

    this.route.paramMap.pipe(untilDestroyed(this)).subscribe(
      params => {
        const id = params.get('id');
        if (id) {
          this.webhookId = parseInt(id);
          this.fetch();
        }
      }
    )
  }


  private fetch() {
    const hookId = this.webhookId;
    this.items = [];

    this.webhookService.get$(hookId).subscribe(hook => {
      this.webhook = hook;
    })

    this.websocketService.getWebhookEvents$(hookId).pipe(
      takeWhile( () => this.webhookId === hookId)).subscribe(item => {
       this.items.unshift(item);
       this.select(item);
    });

    this.webhookService.getHistory(this.webhookId).subscribe(items => {
      this.items = items;
      if (items.length) {
        this.select(items[0]);
      }
    });

  }

  private select(item: WebhookHistory) {
    this.selectedItem = item;
    this.request = JSON.stringify(JSON.parse(this.selectedItem.request), null, 2);
    this.selectedItems = [item];
  }

  selected(event: MatSelectionListChange) {
    this.select(event.options[0].value);
  }
}
