<div class="page">
  <h1>Agents</h1>

  <p *ngIf="!agents?.length && agentsFetched">You will need to install a Cykubed agent into each Kubernetes cluster
    where you want to run tests</p>

  <div *ngFor="let agent of agents" class="agent p2 mat-elevation-z2 list-item" matRipple
       (click)="editAgent(agent)">

    <div class="row-align-top space-between">
      <div class="name">
        <h2 matListItemTitle>{{ agent.name }}
          <span *ngIf="agent.is_public && profile.is_staff" class="public-icon">
            <fa-icon  [icon]="icons.publicIcon" size="1x"></fa-icon>
          </span>
        </h2>
        <span matListItemLine>{{ (agent.platform | uppercase) || 'Generic platform' }}</span>
      </div>

      <div>
        <app-agent-indicator [agent]="agent" [showText]="true"></app-agent-indicator>
        <div class="update-available" *ngIf="agent.updateAvailable">
          <fa-icon [icon]="updatesAvailableIcon"></fa-icon>
          Update available ({{ latestVersion }})
        </div>

      </div>
    </div>


  </div>

  <button mat-raised-button color="primary" class="add-button" (click)="addNewAgent()"
          aria-label="Add another agent">
    <mat-icon>add</mat-icon>
    <span>Add agent</span>
  </button>
  <p class="mat-body-2">Note: only one agent is required per Kubernetes cluster</p>


</div>
