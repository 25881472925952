import {Component, TemplateRef, ViewChild} from '@angular/core';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {UsersService} from '@services/api-services/users.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AccountDetails, UserInvite, UserModel, UserProfile} from '@models/generated.model';
import {IconsService} from '@services/ui-services/icon.service';
import {MatDialog} from '@angular/material/dialog';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {MatTable} from '@angular/material/table';
import {FormControl} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {AccountService} from '@services/api-services/account.service';


interface ExtUserModel extends UserModel{
  is_invite?: boolean

}

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent {
  orgInviteLink: string;
  displayedColumns: string[] = ['name', 'email', 'role', 'actions'];
  users: ExtUserModel[] = [];
  pendingInvites: UserInvite[];
  selected?: ExtUserModel;
  profile: UserProfile;
  account: AccountDetails;
  userLimitReached = false;
  fetched = false;

  @ViewChild('confirmRemoveRef', { static: true }) confirmRemoveTpl: TemplateRef<any>;
  @ViewChild('editUserRef', { static: true }) editUserTpl: TemplateRef<any>;
  @ViewChild('inviteUsersRef', { static: true }) inviteUsesTpl: TemplateRef<any>;

  @ViewChild(MatTable) table: MatTable<ExtUserModel>;
  permissions = new FormControl();

  constructor(private authService: AuthenticationService,
              private accountService: AccountService,
              private snackbar: MatSnackBar,
              public icons: IconsService,
              private dialog: MatDialog,
              private errorDialogService: ErrorDialogService,
              private userService: UsersService) {

  }

  ngOnInit(): void {
    this.profile = this.authService.profile;
    this.userService.getInviteUrl().subscribe(v => {
      this.orgInviteLink = v;
    });
    this.fetch();
  }

  private checkUserLimit() {
    this.userLimitReached = (!!this.account.subscription.plan.users_limit &&
                                  this.users.length === this.account.subscription.plan.users_limit);
  }

  fetch() {
    forkJoin({
      users: this.userService.getUsers(),
      invites: this.userService.getPendingInvites(),
      account: this.accountService.getAccountDetails()
    }).subscribe(({users, invites, account}) => {
      this.users = users;
      this.pendingInvites = invites;
      this.account = account;
      this.checkUserLimit();
      if (invites?.length) {
        const extinvites = invites.map(invite => (
          {
            id: 0,
            email: invite.email,
            is_admin: invite.is_admin || false,
            name: 'pending',
            is_active: false,
            is_invite: true
          }));
        this.users.push(...extinvites);
        this.table.renderRows();
        this.fetched = true;
      }
    });
  }

  removeUserClicked(user: ExtUserModel) {
    this.selected = user;
    if (user.is_invite) {
      // just remove invite - don't ask
      this.userService.deletePendingInvite(user.email).subscribe(() => {
        this.removeSelectedFromList();
      });
    } else {
      // otherwise ask for confirmation
      this.dialog.open(this.confirmRemoveTpl);
    }
  }

  showCopiedResponse() {
    this.userService.showOrgInviteLinkSnackbar();
  }

  removeUser() {
    this.userService.removeUserFromOrganisation(this.selected!.id).subscribe({next: () => {
      this.removeSelectedFromList();
    }, error: () => {
      this.errorDialogService.show("Failed to remove user from organisation");
    }});
  }

  private removeSelectedFromList() {
    if (this.selected) {
      const idx = this.users.indexOf(this.selected);
      this.users.splice(idx, 1);
      delete this.selected;
      this.table.renderRows();
      this.snackbar.open("User removed", "OK", {duration: 2000});
    }
    this.checkUserLimit();
  }

  updateUser() {
    const payload = {is_admin: (this.permissions.value == 'admin')};
    this.userService.updateUserPermissions(this.selected!.id, payload).subscribe({
      next: () => {
        this.selected!.is_admin = payload.is_admin;
        this.table.renderRows();
        this.snackbar.open("User updated", "OK", {duration: 2000});
      },
      error: () => {
        this.errorDialogService.show("Failed to update user permissions");
      }
    })

  }

  editClicked(user: ExtUserModel) {
    this.selected = user;
    this.permissions.reset();
    this.permissions.setValue((user.is_admin)?'admin': 'member');
    this.dialog.open(this.editUserTpl);
  }

  resendInvite(email: string) {
      this.userService.resendInvite(email).subscribe({next: () => {
        this.snackbar.open("Invite resent", "OK", {duration: 2000});
    }, error: (err) => {
      this.errorDialogService.show("Failed to resend invite");
    }});
  }
}
