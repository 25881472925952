import {Component, Input} from '@angular/core';
import {TestRunJobStats} from '@models/generated.model';

@Component({
  selector: 'app-job-stats',
  templateUrl: './job-stats.component.html',
  styleUrls: ['./job-stats.component.scss']
})
export class JobStatsComponent {
  @Input() stats: TestRunJobStats;
}
