import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormUtilsService {

  constructor() {
  }

  urlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      let valid = true;

      const value = control.value;
      if (!value) {
        return null;
      }
      try {
        new URL(value as string);
      } catch {
        valid = false;
      }
      return valid ? null : {invalidUrl: true}
    }
  }
}
