<app-breadcrumbs></app-breadcrumbs>
<div class="container">
  <div class="lhs">

    <mat-card class="p1">

      <ng-container *ngIf="testrun">
        <div [class]="testrun.status" class="status background" data-testid="status">
          <fa-icon [icon]="icons.status(testrun.status)" size="lg"
                   [spin]="isRunning"></fa-icon>
          <h2>{{ testrun.status | titlecase }}</h2>
          <span class="flex-spacer"></span>
          <button mat-raised-button color="accent" *ngIf="isRunning"
                  (click)="cancel()">Cancel
          </button>
          <button mat-raised-button color="primary" *ngIf="!isRunning" [disabled]="rerunDisabled"
                  (click)="rerun()">Rerun
          </button>
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Build options">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="clearCache()">Clear cache</button>
          </mat-menu>
        </div>

        <p data-testid="branch"><fa-icon [icon]="icons.branch"></fa-icon> {{ testrun.branch }}</p>

        <div class="info-item" *ngIf="testrun.started">
          <label>Started</label>
          <span>{{ testrun.started | date:'medium' }}</span>
        </div>
        <ng-container *ngIf="!isRunning">
          <div class="info-item" *ngIf="testrun.finished">
            <label>Finished</label>
            <span>{{ testrun.finished | date:'medium' }}</span>
          </div>
          <div class="info-item" *ngIf="testrun.duration" data-testid="duration">
            <label>Duration</label>
            <span>{{ testrun.duration | shortDuration }}</span>
          </div>
        </ng-container>

      </ng-container>

    </mat-card>

    <mat-card *ngIf="testrun?.error" class="error-card">
      <mat-card-content>
        <fa-icon size="1x" class="failed" [icon]="icons.failed"></fa-icon>
        {{ testrun?.error }}
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="testrun && testrun.commit">
      <mat-card-content>
        <p><a [href]="testrun.commit.commit_url"><fa-icon [icon]="icons.commit"></fa-icon> {{ testrun.sha | slice:0:8 }}</a></p>

        <div class="commit-message">{{ testrun.commit.message }}
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="isRunning && totalSpecs">
      <mat-card-content>
        <p>Completed {{ completedSpecs }} spec files out of {{ totalSpecs }}:</p>

        <mat-progress-bar mode="buffer" class="hide-dots"
                          [value]="progress" [bufferValue]="progressPlusStarted" *ngIf="isRunning"></mat-progress-bar>

        <div class="dates">
          <p><label>Started</label> {{ testrun?.started_date | dfnsFormatDistanceToNowStrict }} ago</p>
        </div>

      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="flakeyTests?.length || failedTests?.length" class="result-summary"
              data-testid="result-summary">
      <mat-selection-list [multiple]="false" [(ngModel)]="selectedSpecSummaries"
                          (selectionChange)="specSummarySelected($event)">
        <mat-list-option *ngFor="let spec of failFlakeSummaries" [value]="spec">
          <div class="spec-summary" matListItemTitle>
            <span class="file-name" [matTooltip]="spec.file">{{ spec.shortFileName }}</span>
            <div class="badges">
              <app-spec-status-badge *ngIf="spec.failedTests.length"
                                     [value]="spec.failedTests.length"
                                     status="fail"></app-spec-status-badge>
              <app-spec-status-badge *ngIf="spec.flakeyTests.length"
                                     [value]="spec.flakeyTests.length"
                                     status="flake"></app-spec-status-badge>
            </div>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </mat-card>

  </div>

  <div class="rhs">

    <mat-tab-group animationDuration="0ms" mat-stretch-tabs="false" class="full-flex"
                   [selectedIndex]="mainTabIndex" (selectedIndexChange)="tabSelected($event)">
      <mat-tab label="Logs">
        <app-build-log [testrunId]="testrun.id" class="tab-contents" *ngIf="testrun">

        </app-build-log>
      </mat-tab>

      <mat-tab [label]="(isRunning)?'Progress': 'Results'">

        <section class="specs tab-contents">

          <div class="spec-list">
            <table mat-table [dataSource]="specDataSource" matSort matSortActive="status" matSortDirection="desc">
              <ng-container matColumnDef="shortFileName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">File</th>
                <td mat-cell *matCellDef="let spec">
                  <h4 class="name">{{ spec.shortFileName }}</h4></td>
              </ng-container>
              <ng-container matColumnDef="pod">
                <th mat-header-cell *matHeaderCellDef>Pod</th>
                <td mat-cell *matCellDef="let spec">{{ spec.pod_name }}</td>
              </ng-container>
              <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by duration">Duration
                  (s)
                </th>
                <td mat-cell *matCellDef="let spec">{{ spec.duration }}</td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by status">Status</th>
                <td mat-cell *matCellDef="let spec">
                  <span  class="status-badges">
                    <fa-icon [icon]="icons.spinner" *ngIf="isRunning && spec.status === 'started'" [spin]="true"
                             class="primary"
                             size="xl"></fa-icon>
                    <fa-icon [icon]="icons.passed" *ngIf="spec.status === 'passed'" class="passed"
                             size="xl"></fa-icon>
                    <fa-icon [icon]="icons.cancelled" *ngIf="spec.status === 'cancelled'" class="cancelled"
                             size="xl"></fa-icon>
                    <fa-icon [icon]="icons.timeout" matTooltip="Spec exceeded the timeout" *ngIf="spec.status === 'timeout'" class="failed"
                             size="xl"></fa-icon>
                    <app-spec-status-badge *ngIf="spec.flakeyTests?.length" [value]="spec.flakeyTests!.length"
                          status="flake"></app-spec-status-badge>
                    <app-spec-status-badge *ngIf="spec.failedTests?.length" [value]="spec.failedTests!.length"
                          status="fail"></app-spec-status-badge>

                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let spec; columns: displayedColumns;" [class]="spec.status"
                  (click)="specSelected(spec)"
              ></tr>
              <tr *matNoDataRow>
                <td colspan="4">
                  <p class="p1">No specs yet</p>
                </td>
              </tr>
            </table>

          </div>
        </section>
      </mat-tab>

      <mat-tab label="Failed tests" [disabled]="!failedTests.length">
        <app-test-summaries [summaries]="failedTests" class="tab-contents"
                            (selectionChange)="selectFailedTest($event)"
                  [selection]="selectedFailedTestSummary">

        </app-test-summaries>
      </mat-tab>

      <mat-tab label="Flakey tests" [disabled]="!flakeyTests.length">
        <app-test-summaries [summaries]="flakeyTests" class="tab-contents"
                            (selectionChange)="selectFlakeyTest($event)"
            [selection]="selectedFlakeyTestSummary">

        </app-test-summaries>
      </mat-tab>

      <mat-tab label="Stats" *ngIf="testrun && testrun.jobstats && !isRunning">
        <app-job-stats [stats]="testrun.jobstats"></app-job-stats>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
