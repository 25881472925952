import {Component, Input} from '@angular/core';
import {TestSummaryType} from '@models/testrun-ext.model';

type TestSummaryTypeMap = {
  [key in TestSummaryType]: string;
}
const TestsummaryTooltipStrings: TestSummaryTypeMap = {
  fail: 'failed',
  pass: 'passed',
  flake: 'flakey'
}

@Component({
  selector: 'app-spec-status-badge',
  templateUrl: './spec-status-badge.component.html',
  styleUrls: ['./spec-status-badge.component.scss']
})
export class SpecStatusBadgeComponent {
  @Input() value: number;
  @Input() status: TestSummaryType;

  get tooltip(): string {
    const v = TestsummaryTooltipStrings[this.status];
    if (this.value === 1) {
      return `1 ${v} test`;
    } else {
      return `${this.value} ${v} tests`;
    }
  }
}
