<div class="toolbar">
  <ng-container *ngIf="stripeTestMode">
    <span class="fake-time" *ngIf="fakeTime"><fa-icon [icon]="icons.clock"></fa-icon> {{ fakeTime | date }}</span>
    <button mat-raised-button (click)="showAdvanceTime()">Advance time</button>
    <button mat-raised-button (click)="clearFakeTime()" *ngIf="fakeTime">Clear fake time</button>
  </ng-container>
</div>

<nav mat-tab-nav-bar [tabPanel]="tabPanel" mat-stretch-tabs="false" mat-align-tabs="start">
  <a mat-tab-link *ngFor="let item of navItems" [routerLink]="item.path" #planActive="routerLinkActive" routerLinkActive
     [active]="planActive.isActive"
  > {{ item.label }} </a>
</nav>
<mat-tab-nav-panel #tabPanel>
  <router-outlet></router-outlet>
</mat-tab-nav-panel>


<ng-template #showAdvance>
  <div mat-dialog-title>
    <h2>Advance test time</h2>
  </div>
  <div mat-dialog-content>
    <div class="calendar-wrapper">
      <mat-calendar [(selected)]="nextTime" [minDate]="nextTime"></mat-calendar>
    </div>
  </div>
  <div mat-dialog-actions>
    <div  class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-raised-button mat-dialog-close color="accent" (click)="advanceTime()">OK</button>
    </div>
  </div>
</ng-template>

