<mat-sidenav-container>
  <mat-sidenav #snav mode="side" opened>
    <mat-nav-list>
      <a mat-list-item *ngFor="let item of navItems" [routerLink]="['/main/settings', item.path]"
         [class]="item.path"
         routerLinkActive="nav-active">{{ item.label }}</a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-breadcrumbs></app-breadcrumbs>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
