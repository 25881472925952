import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ErrorDialogService} from './error-dialog.service';
import {TimezoneService} from './timezone.service';
import {IconsService} from './icon.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    ErrorDialogService,
    TimezoneService,
    IconsService
  ]
})
export class UiServicesModule { }
