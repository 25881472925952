import {Component} from '@angular/core';
import {AuthenticationService} from '@services/api-services/authentication.service';

@Component({
  selector: 'app-beta-message',
  templateUrl: './beta-message.component.html',
  styleUrls: ['./beta-message.component.scss']
})
export class BetaMessageComponent {
  constructor(public auth: AuthenticationService) {}
}
