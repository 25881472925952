<h2><ng-container *ngIf="notification else newNotification">Edit notification</ng-container></h2>
<ng-template #newNotification>New notification</ng-template>

<form class="vertical-form card-contents" [formGroup]="form" *ngIf="form">

  <div class="platform-and-channel" *ngIf="connectedPlatforms">
    <mat-form-field class="select-platform">
      <mat-label>Messaging platform</mat-label>
      <mat-select formControlName="platform">
         <mat-select-trigger>
           <app-platform-name [platform]="form.controls['platform'].value"></app-platform-name>
         </mat-select-trigger>
          <mat-option *ngFor="let platform of connectedPlatforms" [value]="platform">
            <app-platform-name [platform]="platform"></app-platform-name>
          </mat-option>
      </mat-select>
    </mat-form-field>

    <app-notification-channel-select formControlName="channel"
                                     [includePrivate]="this.form.controls['include_private'].value"
                                      [platform]="this.form.controls['platform'].value"
    ></app-notification-channel-select>


    <mat-slide-toggle formControlName="include_private">Include private?</mat-slide-toggle>

    <button mat-raised-button (click)="sendTestMessage()" type="button"
          [disabled]="form.invalid">Send a test message</button>

  </div>

  <div class="two-columns no-subscript">

    <div class="project-select">
      <app-project-select data-testid="project-select"
                          formControlName="project_id" placeholder="All projects"></app-project-select>
      <div class="hint">Optionally restrict this notification to a single project</div>
    </div>
    <div class="branch">
      <mat-form-field appearance="fill" class="branch-field no-subscript">
        <mat-label>All branches</mat-label>
        <input matInput formControlName="branch_regex">
        <mat-error *ngIf="form.controls['branch_regex'].hasError('required')">This field is required</mat-error>
      </mat-form-field>
      <div class="hint">Enter a regular expression to ensure that only matching branches will trigger</div>
    </div>
  </div>

  <app-trigger-type [form]="form"></app-trigger-type>

  <div class="end-button-row">

    <button mat-raised-button (click)="cancel()" type="button">{{ cancelLabel }}</button>

    <app-save-button [label]="(notification===undefined)?'Create notification':'Save changes'"
                     [form]="form" [saving]="saving" (savedClicked)="save()"></app-save-button>

    <span class="flex-spacer"></span>

    <button mat-raised-button color="warn" (click)="confirmDelete()" *ngIf="notification?.id"
            type="button">Delete</button>

  </div>

</form>




<ng-template #confirmDeleteRef>
  <h1 mat-dialog-title>Delete notification?</h1>
  <div mat-dialog-actions>
    <div  class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>No</button>
      <button mat-raised-button mat-dialog-close color="warn" (click)="delete()">Delete</button>
    </div>
  </div>
</ng-template>
