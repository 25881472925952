<section class="details">

  <h2 *ngIf="showName"><fa-icon [icon]="icons.organisation"></fa-icon> {{ org.name }}</h2>

  <div class="standard-toolbar">
    <button mat-raised-button (click)="showPlanChangeDialog()" *ngIf="org.id !== myOrgId">Change Plan</button>
    <button mat-raised-button (click)="showAddBuildCredits()" *ngIf="org.id !== myOrgId && !org.prefer_self_host">Add Build Credits</button>

  </div>

  <dl class="info-list">

    <div>
      <dt>ID</dt>
      <dd>{{ org.id }}</dd>
    </div>
    <div>
      <dt>Self-host?</dt>
      <dd>{{ org.prefer_self_host }}</dd>
    </div>
    <div>
      <dt>Subscription plan</dt>
      <dd>
        {{ org.account.subscription.plan.name }}
      </dd>
    </div>
    <div *ngIf="!!org.account.subscription.expires else openEnded">
      <dt>Expires?</dt>
      <dd>{{ org.account.subscription.expires | date }}</dd>
    </div>
    <ng-template #openEnded>
      <div>
        <dt>Open-ended contract</dt>
        <dd></dd>
      </div>
    </ng-template>
    <div>
      <dt>Test results used this month</dt>
      <dd>{{ org.account.test_results_used | number }}</dd>
    </div>
    <div>
      <dt>Build credits used this month</dt>
      <dd>{{ org.account.build_credits_used_this_month | number }}</dd>
    </div>
    <div>
      <dt>Build credits before top-up</dt>
      <dd>{{ org.account.build_credits_remaining_before_topup | number }}</dd>
    </div>

  </dl>
</section>

<ng-template #changePlan>
  <div mat-dialog-title>
    <h2>Change Subscription Plan</h2>
    <h3>{{ org.name }}</h3>
  </div>
  <div mat-dialog-content>

    <form [formGroup]="changePlanForm" class="vertical-form">
      <mat-form-field appearance="fill">
        <mat-label>Plan</mat-label>
        <mat-select formControlName="plan">
          <mat-option *ngFor="let plan of plans" [value]="plan">{{ plan }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Expires?</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="expires">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

    </form>

  </div>
  <div mat-dialog-actions>
    <div  class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-raised-button mat-dialog-close color="accent" (click)="confirmChangePlan()" [disabled]="changePlanForm.invalid">OK</button>
    </div>
  </div>
</ng-template>

<ng-template #addBuildCredits>
  <div mat-dialog-title>
    <h2>Add Build Credits</h2>
    <h3>{{ org.name }}</h3>
  </div>
  <div mat-dialog-content>

    <form [formGroup]="buildCreditsForm" class="vertical-form">
      <mat-form-field appearance="fill">
        <mat-label>Credits</mat-label>
        <input matInput formControlName="credits" type="number">
      </mat-form-field>
    </form>

  </div>
  <div mat-dialog-actions>
    <div  class="row-space-between">
      <button mat-raised-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-raised-button mat-dialog-close color="accent" (click)="confirmAddCredits()"
              [disabled]="buildCreditsForm.invalid">OK</button>
    </div>
  </div>
</ng-template>

