import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Address, UserOrganisationSummary, UserProfile} from '@models/generated.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '@services/api-services/authentication.service';
import {AccountService} from '@services/api-services/account.service';
import {ErrorDialogService} from '@services/ui-services/error-dialog.service';
import {MatDialog} from '@angular/material/dialog';
import {AddressService} from '@services/api-services/address.service';

@Component({
  selector: 'app-organisation-details',
  templateUrl: './organisation-details.component.html',
  styleUrls: ['./organisation-details.component.scss']
})
export class OrganisationDetailsComponent implements OnInit {
  profile: UserProfile;
  orgForm: FormGroup;
  allowDelete = false;
  org: UserOrganisationSummary;
  address: Address;

  @ViewChild('confirmDelete', { static: true }) deleteConfirm: TemplateRef<any>;

  constructor(private authService: AuthenticationService,
              private accountService: AccountService,
              private addressService: AddressService,
              private dialog: MatDialog,
              private errorService: ErrorDialogService,
              private fb: FormBuilder) {
    this.profile = authService.profile;
    this.allowDelete = authService.isAdmin;
  }

  get addressForm() : FormGroup {
    return this.orgForm.get('address') as FormGroup;
  }

  ngOnInit(): void {
    this.org = this.authService.currentOrganisation!;
    this.orgForm = this.fb.group({
      name: [this.org.name, Validators.required],
      address: this.addressService.getFormGroup(this.org.address)
    })
  }

  saveOrgDetails() {
    const value = this.orgForm.value;
    this.org.name = value.name;
    this.org.address = value.address;
    this.accountService.updateOrganisation(this.orgForm.value).subscribe(
      this.errorService.genericUpdateObserver(
        'Updated organisation details',
            'Failed to update organisation details'))
    this.orgForm.markAsPristine();
  }

  showDeleteConfirm() {
    this.dialog.open(this.deleteConfirm);
  }

  deleteOrganisation() {
    this.accountService.deleteOrganisation();
  }

  addressUpdated(addr: Address) {
    this.orgForm.patchValue({'address': addr});
    this.orgForm.markAsDirty();
  }
}
