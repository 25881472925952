<div class="trigger-type-options" [formGroup]="form">

  <div class="checkbox-option">
    <mat-checkbox formControlName="on_pass">Passed <div class="mat-caption">Send a message every time a run passes</div></mat-checkbox>
  </div>

  <div class="checkbox-option">
    <mat-checkbox formControlName="on_fixed">Fixed
      <div class="mat-caption">Send a message every time a run is fixed. <app-info-icon tooltip="You will only receive a message if the previous test run on the same branch failed"></app-info-icon></div></mat-checkbox>

  </div>

  <div class="checkbox-option">
    <mat-checkbox formControlName="on_fail">Failed
      <div class="mat-caption">Send a message every time a run fails</div></mat-checkbox>
  </div>

  <div class="checkbox-option">
    <mat-checkbox formControlName="on_flake">Flakey
      <div class="mat-caption">Send a message when the run contains flakey tests <app-info-icon
        tooltip="(even if the overall run passes)"></app-info-icon></div></mat-checkbox>
  </div>

  <mat-error *ngIf="form.errors?.['noTriggers']">Please specify at least one trigger</mat-error>

</div>
